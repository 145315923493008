import gql from "graphql-tag";

export default gql`
  query Supplier($supplierId: ID!) {
    supplier(id: $supplierId) {
      id
      name
      note
      countryCode
      hasActions
      link
      isArchived
      learnerblySupplierId
      webhook
      partnerAccount {
        id
        name
        updatedAt
        createdAt
      }
      preferredPaymentMethod
      isPayoutAllowed
      isCardAllowed
      isPayoutClientAllowed
      vatRatePercent
      purchaseProcesses {
        id
        name
        isPartnerPurchaseProcess
        partnerAccount {
          id
        }
        steps {
          id
        }
        updatedAt
      }
      vatRates {
        type
        amount
      }
    }
  }
`;
