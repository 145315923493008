import React, { ReactElement, useContext } from "react";
import classnames from "classnames";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import Auth from "@aws-amplify/auth";
import { envVars } from "../../config/env-vars";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import AppContext from "../App/AppContext";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      backgroundColor: "var(--primaryBase)",
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    heading: {
      color: "var(--neutralLightest)",
      padding: "1rem",
      flexGrow: 1
    },
    hide: {
      display: "none"
    }
  })
);

const onLogoutClick = () =>
  Auth.signOut().then(() => window.location.assign(envVars.webUrl));

const Header = (): ReactElement => {
  const classes = useStyles();
  const {
    handleDrawerOpen,
    isMenuOpen,
    user: { firstName }
  } = useContext(AppContext);

  return (
    <AppBar
      className={classnames(classes.appBar, {
        [classes.appBarShift]: isMenuOpen
      })}
      position="fixed"
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="Open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          className={classnames({
            [classes.hide]: isMenuOpen
          })}
        >
          <MenuIcon />
        </IconButton>
        <Typography className={classes.heading} variant="h5">
          Fulfilment
        </Typography>
        Welcome, {firstName}
        <Button onClick={onLogoutClick} color="inherit">
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
