import React, { FunctionComponent } from "react";
import { Switch, Route } from "react-router";
import Home from "../Home";
import Accounts from "../Accounts";
import Orders from "../Orders";
import Order from "../Order";
import CreateAccount from "../CreateAccount";
import Account from "../Account";
import CreateSupplier from "../CreateSupplier";
import Suppliers from "../Suppliers";
import Supplier from "../Supplier";
import PartnerAccount from "../PartnerAccount";
import PurchaseProcess from "../PurchaseProcess";
import ReconcileOverview from "../Reconcile";
import ReconcileTopUpOverview from "../ReconcileTopUp";
import OpenAmazonOrders from "../OpenAmazonOrders";
import StripeOrders from "../StripeOrders";
import StripeOrder from "../StripeOrder";

const Routes: FunctionComponent = () => (
  <Switch>
    <Route path="/" component={Home} exact />
    <Route path="/accounts/create" component={CreateAccount} />
    <Route path="/accounts/:accountId" component={Account} />
    <Route path="/accounts" component={Accounts} />
    <Route path="/orders/:orderId" component={Order} />
    <Route path="/orders-stripe/:orderId" component={StripeOrder} />
    <Route path="/orders-stripe" component={StripeOrders} />
    <Route path="/orders" component={Orders} />
    <Route path="/open-amazon-orders/:accountId" component={OpenAmazonOrders} />
    <Route path="/suppliers/create" component={CreateSupplier} />
    <Route path="/suppliers/:supplierId" component={Supplier} exact />
    <Route
      path="/suppliers/:supplierId/partner-account/:partnerAccountId"
      component={PartnerAccount}
    />
    <Route
      path="/suppliers/:supplierId/process/:purchaseProcessId"
      component={PurchaseProcess}
    />
    <Route path="/suppliers" component={Suppliers} />
    <Route path="/reconcile" component={ReconcileOverview} />
    <Route path="/reconcile-topup" component={ReconcileTopUpOverview} />
  </Switch>
);

export default Routes;
