import { mixed, string, number, object, InferType, boolean } from "yup";
import { CountryCode } from "../../config/globalTypes";
import { PreferredPaymentMethod } from "../../config/globalTypes";

const countryCode = mixed<CountryCode>()
  .oneOf(Object.values(CountryCode))
  .label("Country Code");

const preferredPaymentMethod = mixed<PreferredPaymentMethod>()
  .oneOf(Object.values(PreferredPaymentMethod))
  .label("Preferred Payment Method");

export const SupplierSchema = object()
  .shape({
    name: string().required().label("Name"),
    vatRatePercent: number()
      .min(0)
      .max(100)
      .nullable()
      .transform((_, value) => {
        if (value === "" || value === null) return null;
        return Number(value);
      })
      .label("VAT Rate Percent"),
    link: string().label("Link"),
    learnerblySupplierId: string().nullable().label("Learnerbly Supplier ID"),
    countryCode: countryCode,
    webhook: string().nullable().label("Webhook"),
    isPayoutAllowed: boolean().label("Payout"),
    isCardAllowed: boolean().label("Card"),
    isPayoutClientAllowed: boolean().label("Payout Client"),
    preferredPaymentMethod: preferredPaymentMethod
  })
  .unknown(true);

export type SupplierSchemaType = InferType<typeof SupplierSchema>;
