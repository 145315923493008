import { ChangeEvent, useEffect, useMemo, useState } from "react";

import { createStyles, makeStyles, Theme } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Zoom from "@material-ui/core/Zoom";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";

import ReconcileTableRow from "./ReconcileTableRow";
import { FormattedRow } from "./types";
import { cloneDeep } from "lodash";
import Loading from "../Loading";

interface Props {
  rows: FormattedRow[];

  hasMoreRows?: boolean;

  fetchRows: (index: string, limit: number) => Promise<void>;
  updateRows: (rows: FormattedRow[]) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end"
    },
    header: {
      "& th": {
        fontSize: "12px",
        color: "#7d7d7d"
      }
    },
    table: {
      "& td": {
        fontSize: "12px"
      }
    },
    mainAction: {
      position: "fixed",
      right: theme.spacing(4),
      bottom: theme.spacing(4)
    }
  })
);

const SplitTable = ({
  rows,
  updateRows,
  fetchRows,
  hasMoreRows = false
}: Props) => {
  const classes = useStyles();

  const [page, setPage] = useState<number>(0);
  const [numberOfRows, setNumberOfRows] = useState<number>(10);
  const [paginationLoading, setPaginationLoading] = useState<boolean>(false);
  const [currentRows, setCurrentRows] = useState<FormattedRow[]>([]);
  const [selection, setSelection] = useState<string[]>([]);

  const numberOfPages = useMemo(
    () => Math.ceil(rows.length / numberOfRows),
    [rows, numberOfRows]
  );

  useEffect(() => {
    const currentIndex = page * numberOfRows;
    setCurrentRows(rows.slice(currentIndex, currentIndex + numberOfRows));
  }, [rows, page, numberOfRows]);

  const selectRows = (selectedRows?: FormattedRow[]) => {
    if (!selectedRows) {
      setSelection(rows.map(row => row.id));
    } else {
      setSelection([...selection, ...selectedRows.map(row => row.id)]);
    }
  };

  const deselectRows = (selectedRows?: FormattedRow[]) => {
    if (!selectedRows) {
      setSelection([]);
    } else {
      setSelection(
        selection.filter(row => {
          return selectedRows.some(selectedRow => row !== selectedRow.id);
        })
      );
    }
  };

  const updateRow = (updatedRow: FormattedRow) => {
    const index = rows.findIndex(row => row.id === updatedRow.id);
    if (index >= 0) {
      const clonedRows = cloneDeep(rows);
      clonedRows[index] = updatedRow;
      updateRows(clonedRows);
    }
  };

  const fetchPage = async (cursor: string, numberOfRows: number) => {
    setPaginationLoading(true);

    try {
      await fetchRows(cursor, numberOfRows);
    } finally {
      setPaginationLoading(false);
    }
  };

  const onChangePage = async (pageIndex: number) => {
    if (paginationLoading) return;
    if (pageIndex + 1 > numberOfPages)
      await fetchPage(rows[rows.length - 1].cursor, numberOfRows);
    setPage(pageIndex);
  };

  const onNumberOfRowsChanged = async (newNumberOfRows: number) => {
    const shouldFetch = newNumberOfRows > rows.length;
    setNumberOfRows(newNumberOfRows);
    setPage(0); // Set page back to 0

    // Is requesting more rows than already has
    if (shouldFetch) await fetchPage(currentRows[0].cursor, newNumberOfRows);
  };

  const onFabClicked = () => {
    // TODO: Reconcile the selection
    deselectRows();
  };

  return (
    <>
      <Zoom in={selection.length > 0} timeout={200} unmountOnExit>
        <Fab
          className={classes.mainAction}
          color="primary"
          variant="extended"
          onClick={onFabClicked}
        >
          <Typography>Reconcile!</Typography>
        </Fab>
      </Zoom>
      <Table className={classes.table}>
        <TableHead className={classes.header}>
          <TableRow>
            <TableCell>Transaction Date</TableCell>
            <TableCell>Transaction Amount</TableCell>
            <TableCell>Transaction Statement</TableCell>

            <TableCell>{/* Intentially empty for spacing */}</TableCell>

            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={
                  selection.length !== 0 && rows.length !== selection.length
                }
                checked={selection.length > 0}
                onChange={(_, checked) => {
                  if (checked) selectRows();
                  else deselectRows();
                }}
                inputProps={{ "aria-label": "controlled" }}
                color="primary"
              />
            </TableCell>

            <TableCell>Actions</TableCell>
            <TableCell>Order Date</TableCell>
            <TableCell>Order Amount</TableCell>
            <TableCell>Supplier</TableCell>
            <TableCell>Net Price</TableCell>
            <TableCell>VAT</TableCell>
            <TableCell>Order Title</TableCell>
            <TableCell>Order Info</TableCell>
          </TableRow>
        </TableHead>

        {paginationLoading ? (
          <TableBody>
            <TableRow>
              <TableCell colSpan={14}>
                <Loading />
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <TableBody>
            {currentRows.map(row => {
              const isChecked = selection.includes(row.id);
              return (
                <ReconcileTableRow
                  key={row.id}
                  row={row}
                  updateRow={updateRow}
                  isSelected={isChecked}
                  onSelectChanged={isSelected => {
                    if (isSelected) selectRows([row]);
                    else deselectRows([row]);
                  }}
                />
              );
            })}
          </TableBody>
        )}
        <TableFooter>
          <TableRow>
            <TablePagination
              count={hasMoreRows ? -1 : rows.length}
              page={page}
              rowsPerPage={numberOfRows}
              onPageChange={async (_, pageIndex) => onChangePage(pageIndex)}
              onRowsPerPageChange={(event: ChangeEvent<HTMLInputElement>) => {
                onNumberOfRowsChanged(Number(event.target.value));
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </>
  );
};

export default SplitTable;
