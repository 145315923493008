import React from "react";
import PageMeta from "../PageMeta";
import { Typography } from "@material-ui/core";

const Home = () => {
  return (
    <>
      <PageMeta title="Home" />
      <Typography variant="h5" gutterBottom>
        Fulfilment
      </Typography>
      <iframe
        title="Fulfilment QuickSight Dashboard"
        width="960"
        height="720"
        src="https://eu-west-1.quicksight.aws.amazon.com/sn/embed/share/accounts/731680477297/dashboards/c2c2ea97-119e-45a4-a9b5-c48802dbbab3?directory_alias=fulfilment"
      />
    </>
  );
};

export default Home;
