import gql from "graphql-tag";

export default gql`
  mutation UpdateShippingAddress($orderId: ID!, $shippingDetails: String!) {
    updateShippingAddress(
      orderId: $orderId
      shippingDetails: $shippingDetails
    ) {
      id
      shippingDetails {
        city
        state
        country
        postcode
        street
        telephone
        diallingCode
      }
    }
  }
`;
