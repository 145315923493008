import gql from "graphql-tag";

export default gql`
  mutation SetRefunded($orderId: ID!, $refundReason: String) {
    setRefunded(orderId: $orderId, refundReason: $refundReason) {
      id
      status
      refundedAt
      refundedBy {
        ... on User {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
