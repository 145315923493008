import gql from "graphql-tag";

export default gql`
  mutation SetStripeOnHold($orderId: Int!) {
    setStripeOnHold(orderId: $orderId) {
      id
      status
    }
  }
`;
