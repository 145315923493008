import React from "react";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    error: {
      minHeight: "15rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: theme.palette.error.dark,
      textAlign: "center"
    }
  })
);

const Error = ({ message }: { message: string }) => {
  const classes = useStyles();

  return (
    <div className={classes.error}>
      <div>
        <Typography variant="h4" gutterBottom display="block">
          <span role="img" aria-label="Ow no">
            🤦‍♀
          </span>
        </Typography>
        <Typography variant="subtitle1">
          Snap! Something went wrong. <b>{message}</b>
        </Typography>
      </div>
    </div>
  );
};

export default Error;
