import gql from "graphql-tag";

export default gql`
  mutation ActivatePartnerAccount($id: ID!) {
    activateSupplierPartnerAccount(id: $id) {
      id
      name
      partnerAccount {
        id
        name
        updatedAt
        createdAt
      }
    }
  }
`;
