import gql from "graphql-tag";

export default gql`
  query AllAccounts {
    accounts {
      id
      name
      balance
      currencyCode
      archived
      stripeTransactions(
        filters: {
          unreconciledOnly: true
          types: [PURCHASE, REFUND, MANUAL_REFUND]
        }
      ) {
        totalCount
      }
    }
  }
`;
