import gql from "graphql-tag";

export default gql`
  query TransactionsTopUp(
    $accountId: String
    $unreconciledOnly: Boolean
    $types: [StripeTransactionType]
    $after: String
    $first: Int
  ) {
    stripeTransactions(
      after: $after
      first: $first
      filters: {
        unreconciledOnly: $unreconciledOnly
        accountId: $accountId
        types: $types
      }
    ) {
      totalCount
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          type
          amount
          statementDescriptor
          createdAt
          currency
          account {
            id
            name
          }
        }
      }
    }
  }
`;
