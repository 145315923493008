import { useMutation } from "@apollo/react-hooks";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";

import { useContext } from "react";
import { responseToUnion } from "../../util/apollo";
import AppContext from "../App/AppContext";
import { NotificationTypes } from "../Notification/Notification";
import PayoutForm from "./PayoutForm";
import { PayoutSchema } from "./schema";
import {
  IssueStripePayout,
  IssueStripePayoutVariables
} from "./__generated__/IssueStripePayout";

import issuePayoutMutation from "./issue-payout.graphql";

interface Props {
  initialValues?: {
    supplierId?: string;
    amount?: number;
    currencyCode?: string;
  };
  orderId: number;
  isOpen: boolean;
  onClose: () => void;
}

const PayoutModal = ({ orderId, isOpen, onClose, initialValues }: Props) => {
  const { handleShowNotification } = useContext(AppContext);
  const [mutation, { loading }] = useMutation<
    IssueStripePayout,
    IssueStripePayoutVariables
  >(issuePayoutMutation);

  const handleError = (error: Error) =>
    handleShowNotification({
      type: NotificationTypes.error,
      message: error.message
    });

  const onConfirmClick = async (values: PayoutSchema) => {
    try {
      const { data } = await mutation({
        variables: {
          input: {
            amount: values.amount,
            orderId: orderId,
            supplierId: values.supplier
          }
        }
      });
      if (data) {
        const result = responseToUnion(data.issueStripePayout);

        if (result.__typename === "StripeOrder") {
          handleShowNotification({
            type: NotificationTypes.success,
            message: "Paid out successfully!"
          });
          onClose();
        } else {
          throw new Error(result.message);
        }
      }
    } catch (error) {
      handleError(error as Error);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth={true}>
      <DialogTitle>Issue Payout</DialogTitle>
      <PayoutForm
        confirmPending={loading}
        initialValues={initialValues}
        onConfirm={onConfirmClick}
        onCancel={() => onClose()}
      />
    </Dialog>
  );
};

export default PayoutModal;
