import { createContext } from "react";

export interface AppStateValues {
  account?: string;
}

const ReconcileContext = createContext<AppStateValues>({
  account: ""
});

export default ReconcileContext;
