import { useQuery } from "@apollo/react-hooks";
import { Grid } from "@material-ui/core";

import React, { useReducer } from "react";
import { ResourceType } from "../../config/globalTypes";
import { getResourceTypeLabel } from "../../util/text-display";
import SelectField from "../SelectField";
import { StripeSetPurchasedSuppliers } from "./__generated__/StripeSetPurchasedSuppliers";

import suppliersQuery from "./../StripeOrders/Filter/suppliers.graphql";

interface FormValues {
  resourceType: ResourceType;
  supplierId: string;
}

const formReducer = (
  state: Required<FormValues>,
  event: { name: string; value: unknown }
) => ({ ...state, [event.name]: event.value });

const StripeSetPurchasedForm = ({ formValues }: { formValues: FormValues }) => {
  const { data, loading } = useQuery<StripeSetPurchasedSuppliers>(
    suppliersQuery,
    {
      variables: { filters: { isArchived: false } }
    }
  );
  const [formData, setFormData] = useReducer(formReducer, {
    resourceType: formValues.resourceType,
    supplierId: formValues.supplierId
  });

  const { suppliers = [] } = data || {};

  if (loading) return null;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SelectField
          name="resourceType"
          label="Resource Type"
          value={formData.resourceType}
          onChange={(value: string) =>
            setFormData({ name: "resourceType", value })
          }
          values={Object.values(ResourceType).map(type => ({
            key: type,
            label: getResourceTypeLabel(type)
          }))}
        />
      </Grid>

      <Grid item xs={12}>
        <SelectField
          name="supplierId"
          label="Supplier"
          value={formData.supplierId}
          onChange={(value: string) =>
            setFormData({ name: "supplierId", value })
          }
          values={suppliers.map(({ id, name }) => ({
            key: id,
            label: name
          }))}
          required
        />
      </Grid>
    </Grid>
  );
};

export default StripeSetPurchasedForm;
