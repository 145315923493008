import React, { FunctionComponent, useState } from "react";

import Error from "../Error";
import Loading from "../Loading";
import PageMeta from "../PageMeta";

import { Alert } from "@material-ui/lab";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { useParams } from "react-router";
import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
  Link as MaterialLink,
  Grid
} from "@material-ui/core";
import ShippingIcon from "@material-ui/icons/LocalShipping";

import moment from "moment";
import DataCell from "../DataCell";
import DoneIcon from "@material-ui/icons/Done";
import {
  OpenAmazonOrders as TOpenAmazonOrders,
  OpenAmazonOrdersVariables as TOpenAmazonOrdersVariables
} from "./__generated__/OpenAmazonOrders";
import {
  TriggerAmazonFulfilmentAutomatic,
  TriggerAmazonFulfilmentAutomaticVariables
} from "./__generated__/TriggerAmazonFulfilmentAutomatic";
import { addCurrency } from "../../util/text-display";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";

import openAmazonOrdersQuery from "./open-amazon-orders.graphql";
import triggerAmazonFulfilmentAutomaticMutation from "./trigger-amazon-fulfilment-automatic.graphql";

const TriggerButton: FunctionComponent<{
  orderId: string;
  amazonASIN: string;
  setCalledMutations: (value: React.SetStateAction<string[]>) => void;
}> = ({ orderId, amazonASIN, setCalledMutations }) => {
  const [triggerAmazonFulfilment, { loading, error }] = useMutation<
    TriggerAmazonFulfilmentAutomatic,
    TriggerAmazonFulfilmentAutomaticVariables
  >(triggerAmazonFulfilmentAutomaticMutation, {
    variables: {
      input: {
        orderId,
        amazonASIN
      }
    }
  });

  if (loading) {
    return <CircularProgress size={20} />;
  }

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  return (
    <Button
      color="primary"
      size="small"
      onClick={() =>
        triggerAmazonFulfilment().then(() => {
          setCalledMutations(calledMutations => [...calledMutations, orderId]);
        })
      }
    >
      <ShippingIcon /> Trigger Automation
    </Button>
  );
};

const OpenAmazonOrders: FunctionComponent = () => {
  const { accountId } = useParams<{
    accountId: string;
  }>();
  const { loading, error, data } = useQuery<
    TOpenAmazonOrders,
    TOpenAmazonOrdersVariables
  >(openAmazonOrdersQuery, {
    variables: { accountId }
  });

  const [calledMutations, setCalledMutations] = useState<Array<string>>([]);

  const [triggerAmazonFulfilment] = useMutation<
    TriggerAmazonFulfilmentAutomatic,
    TriggerAmazonFulfilmentAutomaticVariables
  >(triggerAmazonFulfilmentAutomaticMutation);

  if (loading) {
    return (
      <>
        <PageMeta title="Amazon Orders" />
        <Loading />
      </>
    );
  }

  if (error) {
    return (
      <>
        <PageMeta title="Amazon Orders - Error" />
        <Error message={error.message} />
      </>
    );
  }

  if (data) {
    const { orders } = data;

    const callAll = async () => {
      for (const order of orders.edges) {
        const { node } = order;
        if (!calledMutations.includes(node.id) && node.amazonData?.amazonASIN) {
          await triggerAmazonFulfilment({
            variables: {
              input: {
                orderId: node.id,
                amazonASIN: node.amazonData?.amazonASIN as string
              }
            }
          });
          setCalledMutations(calledMutations => [...calledMutations, node.id]);
        }
      }
    };

    return (
      <Box>
        <PageMeta title="Amazon Orders" />
        <Grid container>
          <Box flexGrow={1}>
            <Typography variant="h5">
              <b>Open Amazon Orders</b>
            </Typography>
          </Box>
          <Box>
            <Typography variant="h4" gutterBottom>
              <Box>
                <Button
                  onClick={() => callAll()}
                  color="primary"
                  variant="contained"
                >
                  Trigger Automation for all orders
                </Button>
              </Box>
            </Typography>
          </Box>
        </Grid>
        <Table>
          <TableHead>
            <TableRow>
              <DataCell>Order Date</DataCell>
              <DataCell>Goods Price</DataCell>
              <DataCell>Supplier</DataCell>
              <DataCell>Product</DataCell>
              <DataCell>Status</DataCell>
              <DataCell align="right">Action</DataCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {orders.edges.map(({ node }) => {
              const {
                id,
                amazonData,
                productDescription,
                goodsPrice,
                account: { currencyCode },
                supplier,
                title,
                status
              } = node;
              return (
                <TableRow key={id}>
                  <DataCell>
                    {node.createdAt
                      ? moment(node.createdAt).format("DD, MMM YYYY, hh:mm")
                      : undefined}
                  </DataCell>
                  <DataCell>{addCurrency(goodsPrice, currencyCode)}</DataCell>
                  <DataCell>{supplier?.name}</DataCell>
                  <DataCell>
                    {title}, {productDescription}
                  </DataCell>
                  <DataCell>{status}</DataCell>
                  <DataCell align="right">
                    <MaterialLink
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`/orders/${id}/`}
                    >
                      <Button color="primary">
                        <AttachMoneyIcon />
                        View order
                      </Button>
                    </MaterialLink>
                    {amazonData?.amazonASIN && (
                      <>
                        {calledMutations.includes(id) ? (
                          <DoneIcon />
                        ) : (
                          <TriggerButton
                            orderId={id}
                            amazonASIN={amazonData?.amazonASIN as string}
                            setCalledMutations={setCalledMutations}
                          />
                        )}
                      </>
                    )}
                  </DataCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    );
  }

  return <></>;
};

export default OpenAmazonOrders;
