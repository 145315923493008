import gql from "graphql-tag";

export default gql`
  mutation SetPaid(
    $orderId: ID!
    $goodsPrice: Fixed!
    $shippingFees: Fixed!
    $vatAmount: Fixed!
    $vatRatePercent: Fixed
    $commissionAmount: Fixed!
    $commissionVatAmount: Fixed
  ) {
    setPaid(
      orderId: $orderId
      goodsPrice: $goodsPrice
      shippingFees: $shippingFees
      vatAmount: $vatAmount
      vatRatePercent: $vatRatePercent
      commissionAmount: $commissionAmount
      commissionVatAmount: $commissionVatAmount
    ) {
      id
      paidAt
      paidBy {
        ... on User {
          id
          firstName
          lastName
        }
      }
      status
      goodsPrice
      shippingFees
      commissionAmount
      vatAmount
      account {
        id
        balance
      }
    }
  }
`;
