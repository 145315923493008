import gql from "graphql-tag";

export default gql`
  mutation RectifyResourceType($orderId: Int!, $resourceType: ResourceType!) {
    rectifyResourceType(orderId: $orderId, resourceType: $resourceType) {
      id
      resourceType
    }
  }
`;
