import gql from "graphql-tag";

export default gql`
  mutation UpdatePurchaseProcessStep(
    $id: ID!
    $input: UpdatePurchaseProcessStepInput!
  ) {
    updatePurchaseProcessStep(id: $id, input: $input) {
      id
      title
      body
      updatedAt
      createdAt
    }
  }
`;
