import gql from "graphql-tag";

export default gql`
  mutation SetStripeRefunded($orderId: Int!, $reason: String) {
    setStripeRefunded(orderId: $orderId, reason: $reason) {
      ... on SetStripeRefundedOutput {
        order {
          id
          status
          refundedAt
          refundedBy {
            ... on User {
              id
              firstName
              lastName
            }
          }
          markedPurchased {
            checkoutGrossPrice
          }
          vatAmount
        }
      }
      ... on SemanticError {
        message
        code
      }
    }
  }
`;
