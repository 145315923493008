import React, { Fragment, ReactElement, useState, useContext } from "react";
import { useParams } from "react-router";
import {
  makeStyles,
  Theme,
  createStyles,
  Box,
  Grid,
  Typography,
  Fab,
  Button,
  Chip,
  Link
} from "@material-ui/core";
import moment from "moment";
import capitalize from "lodash/capitalize";
import countries from "@learnerbly/i18n-iso-countries";
import { flag } from "country-emoji";

import { useQuery, useMutation } from "@apollo/react-hooks";
import Delete from "@material-ui/icons/Delete";
import { ApolloError } from "apollo-client";
import { useHistory } from "react-router";

import Error from "../Error";
import Loading from "../Loading";
import PageMeta from "../PageMeta";
import UpdateSupplier from "../UpdateSupplier";
import AppContext from "../App/AppContext";
import { NotificationTypes } from "../Notification/Notification";

import CreatePurchaseProcess from "../CreatePurchaseProcess";

import {
  Supplier as TSupplier,
  SupplierVariables as TSupplierVariables
} from "./__generated__/Supplier";
import {
  ActivatePartnerAccount as TActivatePartnerAccount,
  ActivatePartnerAccountVariables as TActivatePartnerAccountVariables
} from "./__generated__/ActivatePartnerAccount";

import supplierByIdQuery from "./supplier.graphql";
import activatePartnerAccountMutation from "./activate-partner-account.graphql";
import archiveSupplierMutation from "./archive-supplier.graphql";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      textDecoration: "none",
      color: "inherit"
    },
    mainAction: {
      position: "fixed",
      right: theme.spacing(4),
      bottom: theme.spacing(4)
    },
    title: {
      fontSize: 16,
      fontWeight: 600
    },
    section: {
      marginBottom: 24
    },
    sectionHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    sectionTitle: {
      fontSize: 14,
      fontWeight: 500,
      textTransform: "uppercase",
      marginBottom: 16
    },
    sectionRows: {
      marginTop: 8
    },
    sectionRow: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: 12
    },
    sectionCard: {
      padding: "16px",
      border: "1px solid #eee"
    },
    sectionCardTitle: {
      fontSize: 14,
      fontWeight: 500
    },
    panel: {
      background: "#fff",
      padding: "24px",
      border: "1px solid #eee"
    },
    emptyState: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      padding: 64
    },
    processContainer: {
      padding: 8,
      width: "100%",
      "&:hover": {
        textDecoration: "none"
      }
    },
    process: {
      paddingBottom: 16,
      marginTop: 16,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      borderBottom: "1px solid #eee"
    },
    processDetails: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    }
  })
);

const Supplier = (): ReactElement => {
  const classes = useStyles();
  const history = useHistory();

  const { supplierId } = useParams<{
    supplierId: string;
  }>();

  const { loading, error, data } = useQuery<TSupplier, TSupplierVariables>(
    supplierByIdQuery,
    {
      variables: { supplierId }
    }
  );

  const [mutation, { loading: mutationLoading }] = useMutation<
    TActivatePartnerAccount,
    TActivatePartnerAccountVariables
  >(activatePartnerAccountMutation);

  const [archiveSupplier, { loading: archiveLoading }] = useMutation(
    archiveSupplierMutation
  );
  const { handleShowNotification } = useContext(AppContext);

  const [open, setOpen] = useState<{
    updateSupplier: boolean;
    createPurchaseProcess: boolean;
  }>({
    updateSupplier: false,
    createPurchaseProcess: false
  });

  const handleModalOpen = (modalType: string) =>
    setOpen(modalState => ({ ...modalState, [modalType]: true }));

  const handleModalClose = (modalType: string) =>
    setOpen(modalState => ({ ...modalState, [modalType]: false }));

  const onActivatePartnerAccount = async () =>
    mutation({
      variables: { id: supplierId },
      update: (cache, { data }) => {
        if (!data) return;

        cache.writeQuery({
          query: supplierByIdQuery,
          variables: { supplierId },
          data: {
            ...data,
            partnerAccount: data.activateSupplierPartnerAccount?.partnerAccount
          }
        });
      }
    });

  const handleArchiveSupplier = () => history.replace("/suppliers/");

  const onArchiveClick = async () => {
    if (window.confirm("Are you sure you wish to archive this supplier?")) {
      try {
        await archiveSupplier({ variables: { id: supplierId } });
        handleArchiveSupplier();
      } catch (err) {
        handleShowNotification({
          type: NotificationTypes.error,
          message:
            err instanceof ApolloError ? err.message : "archive supplier failed"
        });
      }
    }
  };

  if (loading) {
    return (
      <>
        <PageMeta title="Supplier" />
        <Loading />
      </>
    );
  }

  if (error) {
    return (
      <>
        <PageMeta title="Supplier - Error" />
        <Error message={error.message} />
      </>
    );
  }

  if (data) {
    const {
      supplier: {
        id: supplierId,
        name,
        countryCode,
        link,
        isArchived,
        learnerblySupplierId,
        partnerAccount,
        purchaseProcesses,
        vatRates,
        preferredPaymentMethod,
        isPayoutAllowed,
        isCardAllowed,
        isPayoutClientAllowed,
        vatRatePercent
      }
    } = data;

    const availablePaymentMethods = [
      ...(isPayoutAllowed ? ["Payout"] : []),
      ...(isCardAllowed ? ["Card"] : []),
      ...(isPayoutClientAllowed ? ["Payout Client"] : [])
    ];

    const formattedPaymentMethod =
      preferredPaymentMethod === "CARD"
        ? "Card"
        : preferredPaymentMethod === "PAYOUT"
        ? "Payout"
        : "Payout Client";

    return (
      <Fragment>
        <PageMeta title={`Supplier - ${name}`} />
        <Box mb={4}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Grid
                container
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Typography variant="h4">
                    {name} - {countryCode}
                  </Typography>
                </Grid>
                {isArchived && (
                  <Grid item>
                    <Typography variant="overline">Archived</Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                justifyContent="flex-end"
                alignItems="center"
                spacing={1}
              >
                {!isArchived && (
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<Delete />}
                      onClick={onArchiveClick}
                      disabled={archiveLoading}
                    >
                      {archiveLoading ? "Archiving..." : "archive supplier"}
                    </Button>
                  </Grid>
                )}
                <Grid item>
                  <Button variant="outlined" href={link} target="_blank">
                    External Website
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box mb={4}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Box className={classes.panel}>
                <Box className={classes.section}>
                  <Typography className={classes.sectionTitle} variant="h5">
                    Learnerbly Admin ID
                  </Typography>
                  <span>
                    {learnerblySupplierId
                      ? learnerblySupplierId
                      : "ID has not been assigned"}
                  </span>
                </Box>

                <Box className={classes.section}>
                  <Box className={classes.sectionHeader}>
                    <Typography className={classes.sectionTitle} variant="h5">
                      VAT Rates
                    </Typography>
                    <Typography
                      className={classes.sectionCardTitle}
                      variant="h5"
                    >
                      {`${flag(countryCode)}${countries.getName(
                        countryCode,
                        "en"
                      )}`}
                    </Typography>
                  </Box>
                  <Box className={classes.sectionRows}>
                    {vatRatePercent !== null ? (
                      <Box className={classes.sectionRow}>
                        <span>Defined Rate</span>
                        <span>{vatRatePercent}%</span>
                      </Box>
                    ) : (
                      vatRates &&
                      vatRates.map(
                        ({
                          type,
                          amount
                        }: {
                          type: string;
                          amount: number;
                        }) => (
                          <Box key={type} className={classes.sectionRow}>
                            <span>{capitalize(type.toLowerCase())} Rate</span>
                            <span>{amount}%</span>
                          </Box>
                        )
                      )
                    )}
                  </Box>
                </Box>

                <Box className={classes.section}>
                  <Typography className={classes.sectionTitle} variant="h5">
                    Partner Account
                  </Typography>
                  {partnerAccount ? (
                    <Link
                      href={`/suppliers/${supplierId}/partner-account/${partnerAccount.id}`}
                    >
                      <Box className={classes.sectionCard}>
                        <Grid
                          container
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid item>
                            <Typography
                              className={classes.sectionCardTitle}
                              color="primary"
                            >
                              ACTIVE
                            </Typography>
                          </Grid>
                          <Grid item>
                            <span>
                              Since{" "}
                              {moment(partnerAccount.createdAt).format(
                                "D MMM YY"
                              )}
                            </span>
                          </Grid>
                        </Grid>
                      </Box>
                    </Link>
                  ) : (
                    <Box className={classes.sectionCard}>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item>
                          <Typography
                            className={classes.sectionCardTitle}
                            color="error"
                          >
                            DISABLED
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Button
                            size="small"
                            onClick={onActivatePartnerAccount}
                            disabled={mutationLoading}
                          >
                            {mutationLoading
                              ? "Activating..."
                              : "Activate account"}
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </Box>
                <Box className={classes.section}>
                  <Box className={classes.sectionHeader}>
                    <Typography className={classes.sectionTitle} variant="h5">
                      Payment Methods
                    </Typography>
                  </Box>
                  <Box className={classes.sectionRows}>
                    {
                      <Box className={classes.sectionRow}>
                        <span>Allowed Methods</span>
                        <span>{availablePaymentMethods.join(", ")}</span>
                      </Box>
                    }
                    {
                      <Box className={classes.sectionRow}>
                        <span>Default Method</span>
                        <span>{formattedPaymentMethod}</span>
                      </Box>
                    }
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Box className={classes.panel}>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={2}
                  alignItems="center"
                >
                  <Grid item>
                    <Typography variant="h4" className={classes.title}>
                      Purchase Processes
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={() => handleModalOpen("createPurchaseProcess")}
                    >
                      New Process
                    </Button>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={2}
                  alignItems="center"
                >
                  {purchaseProcesses && purchaseProcesses.length ? (
                    purchaseProcesses.map(
                      ({
                        id,
                        name,
                        isPartnerPurchaseProcess,
                        steps,
                        updatedAt
                      }) => (
                        <Link
                          className={classes.processContainer}
                          href={`/suppliers/${supplierId}/process/${id}`}
                          key={id}
                        >
                          <Box className={classes.process}>
                            <Box>
                              <Typography variant="body2">{name}</Typography>
                            </Box>
                            <Box className={classes.processDetails}>
                              {isPartnerPurchaseProcess && (
                                <Chip
                                  size="small"
                                  label="Partner Process"
                                  color="primary"
                                  style={{ marginRight: 24 }}
                                />
                              )}
                              <Typography
                                variant="body2"
                                style={{ marginRight: 8 }}
                              >
                                {steps?.length === 1
                                  ? `${steps.length} Step`
                                  : `${steps?.length} Steps`}
                              </Typography>
                              <Typography variant="body2">
                                Updated {moment(updatedAt).fromNow()}
                              </Typography>
                            </Box>
                          </Box>
                        </Link>
                      )
                    )
                  ) : (
                    <Box className={classes.emptyState}>
                      <span>No processes created</span>
                    </Box>
                  )}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {!isArchived && (
          <Box className={classes.mainAction}>
            <Fab
              color="primary"
              variant="extended"
              onClick={() => handleModalOpen("updateSupplier")}
            >
              Update Supplier
            </Fab>
          </Box>
        )}

        <UpdateSupplier
          supplier={data.supplier}
          isOpen={open.updateSupplier}
          handleModalClose={() => handleModalClose("updateSupplier")}
        />

        <CreatePurchaseProcess
          supplier={data.supplier}
          isOpen={open.createPurchaseProcess}
          handleModalClose={() => handleModalClose("createPurchaseProcess")}
        />
      </Fragment>
    );
  }

  return <Box />;
};

export default Supplier;
