import React, { useContext, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import AppContext from "../App/AppContext";

import { NotificationTypes } from "../Notification/Notification";
import { Button, Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { handleSubmit } from "../../util/form-validation";
import TextField from "../TextField";
import { ApolloError } from "apollo-client";
import {
  SetStripeInternalNote,
  SetStripeInternalNoteVariables
} from "./__generated__/SetStripeInternalNote";

import setStripeInternalNoteMutation from "./stripe-set-internal-note.graphql";

const StripeInternalNote = ({
  orderId,
  internalNote
}: {
  orderId: number;
  internalNote: string | null;
}) => {
  const { handleShowNotification } = useContext(AppContext);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [formState, setFormState] = useState<string>(internalNote || "");
  const [mutation, { loading }] = useMutation<
    SetStripeInternalNote,
    SetStripeInternalNoteVariables
  >(setStripeInternalNoteMutation, {
    onCompleted: () => setShowForm(false),
    onError: (error: ApolloError) =>
      handleShowNotification({
        type: NotificationTypes.error,
        message: error.message
      })
  });

  function handleFieldChange(value: any) {
    setFormState(value);
  }

  return showForm ? (
    <form
      onSubmit={handleSubmit(() =>
        mutation({
          variables: {
            orderId,
            internalNote: formState
          }
        })
      )}
    >
      <TextField
        name="internalNote"
        value={formState}
        onChange={handleFieldChange}
        label="Notes"
        multiline
      />
      <Button size="small" color="primary" type="submit" disabled={loading}>
        Save
      </Button>
    </form>
  ) : (
    <Box mb={2}>
      <Typography variant="overline" display="block" gutterBottom>
        Internal Notes
      </Typography>
      {internalNote && (
        <Typography variant="body2" gutterBottom>
          {internalNote}
        </Typography>
      )}
      <Button size="small" type="button" onClick={() => setShowForm(true)}>
        {internalNote ? "Edit" : "Add"}
      </Button>
    </Box>
  );
};

export default StripeInternalNote;
