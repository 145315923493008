import React, { useContext } from "react";
import { useMutation } from "@apollo/react-hooks";
import AppContext from "../App/AppContext";
import {
  TriggerActions as TTriggerActions,
  TriggerActionsVariables as TTriggerActionsVariables
} from "./__generated__/TriggerActions";

import { NotificationTypes } from "../Notification/Notification";
import { Button } from "@material-ui/core";
import ExtensionIcon from "@material-ui/icons/Extension";

import triggerActionsMutation from "./trigger-actions.graphql";

const TriggerActionsButton = ({
  orderId,
  hasActions
}: {
  orderId: string;
  hasActions: boolean;
}) => {
  const [mutation, { loading }] = useMutation<
    TTriggerActions,
    TTriggerActionsVariables
  >(triggerActionsMutation);
  const { handleShowNotification } = useContext(AppContext);

  return (
    <Button
      color="primary"
      disabled={!hasActions || loading}
      onClick={() =>
        mutation({
          variables: {
            orderId
          }
        }).catch(({ message }: { message: string }) =>
          handleShowNotification({
            type: NotificationTypes.error,
            message
          })
        )
      }
      size="large"
    >
      <ExtensionIcon /> Trigger actions
    </Button>
  );
};

export default TriggerActionsButton;
