import moment from "moment";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles } from "@material-ui/core";

import DataCell from "../DataCell";
import { Transaction } from "../Reconcile/types";

const useStyles = makeStyles(() =>
  createStyles({
    tableBody: {
      "& td": {
        fontSize: "16px",
        fontWeight: "bold"
      }
    },
    tableHead: {
      backgroundColor: "#f9fafa",
      "& th": {
        fontSize: "16px",
        fontWeight: "bold",
        color: "#7d7d7d"
      }
    }
  })
);

interface Props {
  transaction: Transaction;
}

const TransactionSummary = ({ transaction }: Props) => {
  const classes = useStyles();

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="h5">
          <b>Unmatched Transaction</b>
        </Typography>
      </Box>

      <Table>
        <TableHead className={classes.tableHead}>
          <TableRow>
            <DataCell>Transaction Date</DataCell>
            <DataCell>Transaction Amount</DataCell>
            <DataCell>Transaction Statement</DataCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          <TableRow>
            <DataCell>
              {moment(transaction.createdAt).format("DD, MMM YYYY, hh:mm")}
            </DataCell>
            <DataCell>
              {transaction.amount}
              {transaction.currency ? ` ${transaction.currency}` : ""}
              {transaction.merchantAmount && transaction.merchantCurrency && (
                <Typography variant="subtitle2" color="textSecondary">
                  ({transaction.merchantAmount.toFixed(2)}{" "}
                  {transaction.merchantCurrency})
                </Typography>
              )}
            </DataCell>
            <DataCell>{transaction.statementDescriptor}</DataCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};

export default TransactionSummary;
