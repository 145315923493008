import React, { useEffect, useRef, useState } from "react";

import { Box, Button, ButtonGroup, Typography } from "@material-ui/core";
import NumberField from "../NumberField";

interface Props {
  onChange?: (vatRate: number) => void;
  value?: number | null;
}

const VatField = ({ onChange, value = null }: Props) => {
  const [selectedVatRate, setSelectedVatRate] = useState<number | null>(value);

  const lastValue = useRef(selectedVatRate);

  useEffect(() => {
    if (onChange && lastValue.current !== selectedVatRate) {
      onChange(selectedVatRate || 0);
      lastValue.current = selectedVatRate;
    }
  }, [selectedVatRate, onChange]);

  const onUseVatClicked = (useVat: boolean) => {
    if (!useVat) {
      setSelectedVatRate(0);
    } else {
      setSelectedVatRate(20);
    }
  };

  const isVatPicked = selectedVatRate !== null;

  if (!isVatPicked) {
    return (
      <Box>
        <Typography variant="h6" gutterBottom>
          Deduct VAT?
        </Typography>
        <ButtonGroup>
          <Button onClick={() => onUseVatClicked(true)}>Yes</Button>
          <Button onClick={() => onUseVatClicked(false)}>No</Button>
        </ButtonGroup>
      </Box>
    );
  }

  return (
    <NumberField
      label="VAT Rate"
      adornment="%"
      value={selectedVatRate}
      onChange={(_, floatValue) => setSelectedVatRate(floatValue)}
    />
  );
};

export default VatField;
