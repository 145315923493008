import React from "react";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  footer: {
    backgroundColor: "var(--neutralLighter)",
    marginTop: "auto",
    minHeight: "3.5rem"
  }
});

const Footer = () => {
  const classes = useStyles({});

  return <footer className={classes.footer}></footer>;
};

export default Footer;
