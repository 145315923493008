import React, { useContext } from "react";
import { useMutation } from "@apollo/react-hooks";
import AppContext from "../App/AppContext";
import {
  SetInProgressVariables as TSetInProgressVariables,
  SetInProgress as TSetInProgress
} from "./__generated__/SetInProgress";

import { NotificationTypes } from "../Notification/Notification";
import { Button } from "@material-ui/core";
import InProgressIcon from "@material-ui/icons/Pool";

import setInProgressMutation from "./set-in-progress.graphql";

const SetInProgressButton = ({ orderId }: { orderId: string }) => {
  const [mutation, { loading }] = useMutation<
    TSetInProgress,
    TSetInProgressVariables
  >(setInProgressMutation);
  const { handleShowNotification } = useContext(AppContext);

  return (
    <Button
      color="primary"
      disabled={loading}
      onClick={() =>
        mutation({
          variables: {
            orderId
          }
        }).catch(({ message }: { message: string }) =>
          handleShowNotification({
            type: NotificationTypes.error,
            message
          })
        )
      }
      size="large"
    >
      <InProgressIcon /> Set in progress
    </Button>
  );
};

export default SetInProgressButton;
