import { FormEvent, SetStateAction } from "react";

export const required = (input: any) =>
  input || input === 0 ? undefined : "Required";

export const validCurrency = (input: any) => {
  if (!input) {
    return;
  }

  const validCurrencyNumber = new RegExp(/^\d+(?:\.\d{2})?$/);
  if (!validCurrencyNumber.test(input)) {
    return "Invalid currency value";
  }

  return;
};

export const handleSubmit =
  <T>(callback: (data: T) => Promise<any>) =>
  (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const target = event.nativeEvent.target as HTMLFormElement;
    const formData = [...new FormData(target)].reduce(
      (obj: object, [key, value]) => ({
        [key]: value,
        ...obj
      }),
      {}
    ) as T;

    callback(formData);
  };

export const withFormState =
  <T>(formState: T, setFormState: (value: SetStateAction<T>) => void) =>
  <T>(field: keyof typeof formState) =>
  (value: any) =>
    setFormState({
      ...formState,
      [field]: value as T
    });
