import gql from "graphql-tag";

export default gql`
  mutation UpdatePurchaseProcess(
    $id: ID!
    $input: UpdatePurchaseProcessInput!
  ) {
    updatePurchaseProcess(id: $id, input: $input) {
      id
      name
      isPartnerPurchaseProcess
      description
      supplier {
        id
      }
    }
  }
`;
