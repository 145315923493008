import gql from "graphql-tag";

export default gql`
  mutation SetResourceType($orderId: ID!, $resourceType: ResourceType!) {
    setResourceType(orderId: $orderId, resourceType: $resourceType) {
      id
      resourceType
    }
  }
`;
