import React, { ReactElement, ChangeEvent } from "react";
import {
  default as MaterialTextField,
  StandardTextFieldProps
} from "@material-ui/core/TextField";
import { default as InputAdornment } from "@material-ui/core/InputAdornment";

interface CustomProps {
  adornment?: string;
  onChange: (value: string | undefined) => void;
}

const TextField = ({
  fullWidth = true,
  adornment,
  onChange,
  error = false,
  ...props
}: Omit<StandardTextFieldProps, "onChange"> & CustomProps): ReactElement => (
  <MaterialTextField
    error={error}
    fullWidth={fullWidth}
    {...props}
    onChange={(event: ChangeEvent<HTMLInputElement>) =>
      onChange(event.target.value)
    }
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          {adornment ? adornment : ""}
        </InputAdornment>
      )
    }}
  />
);

export default TextField;
