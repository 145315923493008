import React, { useContext, Fragment, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Grid,
  Box
} from "@material-ui/core";

import AppContext from "../App/AppContext";
import { Redirect } from "react-router-dom";
import { NotificationTypes } from "../Notification/Notification";
import { useMutation } from "@apollo/react-hooks";
import { handleSubmit, withFormState } from "../../util/form-validation";
import TextField from "../TextField";
import SwitchField from "../SwitchField";

import {
  CreatePurchaseProcessVariables as TCreatePurchaseProcessVariables,
  CreatePurchaseProcess as TCreatePurchaseProcess
} from "./__generated__/CreatePurchaseProcess";

import createPurchaseProcessMutation from "./create-purchase-process.graphql";

import supplierByIdQuery from "../Supplier/supplier.graphql";

interface Props {
  supplier: any;
  isOpen: boolean;
  handleModalClose: () => void;
}

interface TFormState {
  name: string;
  description?: string;
  isPartnerPurchaseProcess: boolean;
}

const CreatePurchaseProcess = ({
  supplier,
  isOpen,
  handleModalClose
}: Props) => {
  const [mutation, { loading, data: createPurchaseProcessMutationData }] =
    useMutation<TCreatePurchaseProcess, TCreatePurchaseProcessVariables>(
      createPurchaseProcessMutation
    );

  const { handleShowNotification } = useContext(AppContext);

  const [formState, setFormState] = useState<TFormState>({
    name: "",
    isPartnerPurchaseProcess: false
  });

  const handleFieldChange = withFormState<TFormState>(formState, setFormState);

  const { partnerAccount } = supplier;

  const onSubmit = async () => {
    const { name, description, isPartnerPurchaseProcess } = formState;

    const partnerAccountId = partnerAccount?.id;

    try {
      if (isPartnerPurchaseProcess && !partnerAccountId) {
        throw new Error(
          "Please activate the partner account before creating this process as a partner process"
        );
      }

      await mutation({
        variables: {
          input: {
            supplierId: supplier.id,
            name,
            description,
            partnerAccountId,
            isPartnerPurchaseProcess
          }
        },
        refetchQueries: [
          { query: supplierByIdQuery, variables: { supplierId: supplier.id } }
        ],
        awaitRefetchQueries: true
      });

      handleModalClose();
      handleShowNotification({
        type: NotificationTypes.success,
        message: "Process created"
      });
    } catch (error) {
      handleShowNotification({
        type: NotificationTypes.error,
        message: `Oh no, there was a problem creating the process. Reason: ${error.message}`
      });
    }
  };

  return (
    <Fragment>
      <Dialog open={isOpen} onClose={handleModalClose}>
        <DialogTitle>Create Purchase Process</DialogTitle>
        <DialogContent>
          <Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={3}>
                <Grid item>
                  <Box mb={2}>
                    <TextField
                      name="name"
                      label="Name"
                      value={formState.name}
                      onChange={handleFieldChange<string>("name")}
                      required
                    />
                    <TextField
                      name="description"
                      label="Description"
                      value={formState.description}
                      onChange={handleFieldChange<string>("description")}
                    />
                    <SwitchField
                      disabled={supplier.partnerAccountId}
                      checked={formState.isPartnerPurchaseProcess}
                      onChange={handleFieldChange<boolean>(
                        "isPartnerPurchaseProcess"
                      )}
                      name="isPartnerPurchaseProcess"
                      label={
                        supplier.partnerAccountId
                          ? "This process is for partner orders"
                          : "Please activate a partner account to create partner purchase processes"
                      }
                      inputProps={{
                        "aria-label": "This process is for partner orders"
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Fragment>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose} color="primary">
            Cancel
          </Button>
          <Button onClick={onSubmit} color="primary" disabled={loading}>
            {loading ? <CircularProgress size={30} /> : "Create"}
          </Button>
        </DialogActions>
        {!loading && createPurchaseProcessMutationData && (
          <Redirect to={`/suppliers/${supplier.id}`} />
        )}
      </Dialog>
    </Fragment>
  );
};

export default CreatePurchaseProcess;
