import gql from "graphql-tag";

export default gql`
  query TransactionsByAccount($accountId: ID!, $after: String, $first: Int) {
    account(id: $accountId) {
      id
      transactions(after: $after, first: $first) {
        edges {
          id
          cursor
          node {
            id
            amount
            type
            createdAt
            internalNote
            order {
              id
            }
            status
            lastCardDigits
            statementDescriptor
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
        totalCount
      }
    }
  }
`;
