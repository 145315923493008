export type ErrorWithMessage = {
  message: string;
};

export function isErrorwithMessage(error: unknown): error is ErrorWithMessage {
  return (
    typeof error === "object" &&
    error !== null &&
    "message" in error &&
    typeof (error as Record<string, unknown>).message === "string"
  );
}

export function getErrorMessage(error: unknown) {
  if (isErrorwithMessage(error)) return error.message;
}
