import React, { ReactNode, ReactElement } from "react";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  main: {
    flexGrow: 1,
    margin: "5rem auto",
    padding: "1.5rem",
    overflowY: "scroll"
  }
});

interface Props {
  children?: ReactNode;
}

const Main = ({ children }: Props): ReactElement => {
  const classes = useStyles();

  return <main className={classes.main}>{children}</main>;
};

export default Main;
