import gql from "graphql-tag";

export default gql`
  query Accounts($filters: AccountFilters) {
    accounts(filters: $filters) {
      id
      name
      currencyCode
    }
  }
`;
