import React from "react";
import * as Sentry from "@sentry/react";

import { logError } from "../../util/error-logging";
import Error from "../Error";

const ErrorBoundary = ({ children }: { children: React.ReactNode }) => {
  const onFallback: Sentry.FallbackRender = ({ error, componentStack }) => {
    Sentry.withScope(scope => {
      scope.setExtra("componentStack", componentStack);
      logError(error);
    });

    return <Error message={error.toString()} />;
  };

  return (
    <Sentry.ErrorBoundary fallback={onFallback}>
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
