import gql from "graphql-tag";

export default gql`
  mutation UpdateStripeShippingDetails(
    $orderId: Int!
    $shippingDetails: StripeShippingDetailsInput!
  ) {
    updateStripeShippingDetails(
      orderId: $orderId
      shippingDetails: $shippingDetails
    ) {
      id
      shippingDetails {
        city
        state
        country
        postcode
        street
        recipientEmail
        recipientName
        telephone
        diallingCode
      }
    }
  }
`;
