import gql from "graphql-tag";

export default gql`
  query UnreconciledStripeOrders(
    $after: String
    $sortBy: SortBy
    $filters: StripeOrderFilters
  ) {
    # TODO: Add in unreconciled filter
    stripeOrders(first: 10, after: $after, sortBy: $sortBy, filters: $filters) {
      pageInfo {
        hasNextPage
      }
      totalCount
      edges {
        cursor
        node {
          id
          createdAt
          markedPurchased {
            at
            checkoutGrossPrice
          }
          supplier {
            id
            name
          }
          vatAmount
          requestCurrencyCode
          requestGrossPrice
          title
        }
      }
    }
  }
`;
