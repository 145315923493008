import { ReactElement, useContext, Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import { Button, CircularProgress, Box } from "@material-ui/core";
import PageMeta from "../PageMeta";
import {
  CreateSupplierVariables as TCreateSupplierVariables,
  CreateSupplier as TCreateSupplier
} from "./__generated__/CreateSupplier";

import AppContext from "../App/AppContext";
import { useHistory } from "react-router-dom";
import { NotificationTypes } from "../Notification/Notification";
import { CountryCode } from "../../config/globalTypes";
import { useMutation } from "@apollo/react-hooks";

import createSupplierMutation from "./create-supplier.graphql";
import SupplierForm, { SupplierSchemaType } from "../SupplierForm";

const CreateSupplier = (): ReactElement => {
  const [mutation, { loading }] = useMutation<
    TCreateSupplier,
    TCreateSupplierVariables
  >(createSupplierMutation);
  const { handleShowNotification } = useContext(AppContext);
  const history = useHistory();

  const onSubmit = (values: SupplierSchemaType) =>
    mutation({
      variables: {
        input: {
          ...values,
          link: values.link || "",
          countryCode: values.countryCode || CountryCode.GB
        }
      }
    }).then(
      ({ data }) => {
        if (data) {
          const {
            createSupplier: { id }
          } = data;
          handleShowNotification({
            type: NotificationTypes.success,
            message: "Supplier Created"
          });
          history.push(`/suppliers/${id}/`);
        }
      },
      () =>
        handleShowNotification({
          type: NotificationTypes.error,
          message: "Oh no, things blown up"
        })
    );

  return (
    <Fragment>
      <PageMeta title="Create Supplier" />
      <Typography variant="h4" gutterBottom>
        Create Supplier
      </Typography>
      <Box maxWidth="50%">
        <SupplierForm isCreation={true} onSubmit={onSubmit} />
        <Button
          form="supplier-form"
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {loading ? <CircularProgress size={30} /> : "Create Supplier"}
        </Button>
      </Box>
    </Fragment>
  );
};

export default CreateSupplier;
