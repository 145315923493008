import { Grid, Box, makeStyles, createStyles } from "@material-ui/core";
import { round } from "lodash";
import React, { useMemo, useReducer } from "react";
import { safeDivide, safeSubtract } from "../../util/math";
import { getCurrencySymbol } from "../../util/text-display";
import NumberField from "../NumberField";

interface FormValues {
  checkoutGrossPrice: GQL.Fixed;
  vatAmount?: GQL.Fixed;
  vatRatePercent?: GQL.Fixed;
}

const useStyles = makeStyles(() =>
  createStyles({
    readOnly: {
      pointerEvents: "none",
      opacity: 0.6
    }
  })
);

const formReducer = (
  state: Required<FormValues>,
  event: { name: string; value: unknown }
) => ({ ...state, [event.name]: event.value });

const RectifyPriceForm = ({
  formValues,
  currencyCode
}: {
  formValues: FormValues;
  currencyCode: string;
}) => {
  const classes = useStyles();
  const [formData, setFormData] = useReducer(formReducer, {
    checkoutGrossPrice: formValues.checkoutGrossPrice,
    vatAmount: formValues.vatAmount ?? 0,
    vatRatePercent: formValues.vatRatePercent ?? 0
  });
  const currencySymbol = useMemo(
    () => getCurrencySymbol(currencyCode),
    [currencyCode]
  );

  const vatPercent = 1 + formData.vatRatePercent / 100;
  const netPrice = round(
    safeDivide(formData.checkoutGrossPrice, vatPercent),
    2
  );
  const vatAmount = safeSubtract(formData.checkoutGrossPrice, netPrice);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box mt={2}>
          <NumberField
            id="checkoutGrossPrice"
            name="checkoutGrossPrice"
            label="Gross Price (incl. VAT)"
            adornment={currencySymbol}
            value={formData.checkoutGrossPrice}
            onChange={value =>
              setFormData({ name: "checkoutGrossPrice", value })
            }
            required
          />
        </Box>
      </Grid>

      <Grid item xs={12}>
        <NumberField
          id="vatRatePercent"
          name="vatRatePercent"
          label="VAT Rate"
          adornment="%"
          value={formData.vatRatePercent}
          onChange={value => setFormData({ name: "vatRatePercent", value })}
        />
      </Grid>

      <Grid item xs={6}>
        <NumberField
          className={classes.readOnly}
          id="netPrice"
          name="netPrice"
          label="Net Price"
          adornment={currencySymbol}
          value={netPrice}
          required
          readOnly
        />
      </Grid>

      <Grid item xs={6}>
        <NumberField
          className={classes.readOnly}
          id="vatAmount"
          name="vatAmount"
          label="VAT Amount"
          adornment={currencySymbol}
          value={vatAmount}
          onChange={value => setFormData({ name: "vatAmount", value })}
          readOnly
        />
      </Grid>
    </Grid>
  );
};

export default RectifyPriceForm;
