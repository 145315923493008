import gql from "graphql-tag";

export default gql`
  query StripeOrders(
    $after: String
    $first: Int
    $filters: StripeOrderFilters
    $sortOrder: SortOrder
  ) {
    stripeOrders(
      after: $after
      first: $first
      filters: $filters
      sortOrder: $sortOrder
    ) {
      edges {
        id
        cursor
        node {
          id
          title
          status
          grossPriceAgreedByLearner
          requestGrossPrice
          requestCurrencyCode
          refundedAt
          refundedBy {
            id
            firstName
            lastName
          }
          requestedAt
          additionalNote
          approvedAt
          supplier {
            link
            isPayoutAllowed
            id
            name
            hasActions
            preferredPaymentMethod
            partnerAccount {
              name
            }
          }
          markedPurchased {
            by {
              id
              email
              firstName
              lastName
            }
            at
            checkoutGrossPrice
          }
          markedWontPurchase {
            by {
              id
              email
              firstName
              lastName
            }
            at
            reason
          }
          requestId
          vatAmount
          vatRatePercent
          resourceType
          requestType
          requestedBy {
            id
            email
            name
          }
          createdBy {
            id
            email
            firstName
            lastName
          }
          createdAt
          productDescription
          productEventDate
          assignedTo {
            id
            email
            firstName
            lastName
          }
          transactions {
            id
            status
            type
          }
          internalNote
          purchaseLink
          account {
            id
            name
            currencyCode
            balance
            isStripe
            isAmazonFulfilmentAutomatic
          }
          shippingDetails {
            city
            state
            country
            postcode
            street
            recipientEmail
            recipientName
            telephone
            diallingCode
          }
          amazonData {
            asin
            status
            confirmationId
          }
          isAmazonFulfilmentAutomatic
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
