import React, { useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";

import Header from "../Header";
import Footer from "../Footer";
import Routes from "../Routes";

import Menu from "../Menu";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import { WhoAmI_me } from "./../Entry/__generated__/whoami"; // figure out how to share types
import AppContext, { AppStateValues } from "./AppContext";
import Notification, {
  ShowNotificationState,
  NotificationTypes
} from "../Notification/Notification";
import Main from "../Main";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: "var(--neutralLightest)",
      display: "flex",
      minHeight: "100vh",
      width: "100vw"
    }
  })
);

const App = ({ user }: { user: WhoAmI_me }) => {
  const classes = useStyles();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [showNotification, setShowNotification] =
    useState<ShowNotificationState>({
      isVisible: false,
      message: "",
      type: NotificationTypes.success
    });

  const handleNotificationClose = (): void =>
    setShowNotification({ ...showNotification, isVisible: false });

  const appStateValues: AppStateValues = {
    handleDrawerOpen: () => setMenuOpen(true),
    handleDrawerClose: () => setMenuOpen(false),
    handleShowNotification: ({ message, type }) =>
      setShowNotification({
        isVisible: true,
        message,
        type
      }),
    isMenuOpen,
    user
  };

  return (
    <AppContext.Provider value={appStateValues}>
      <CssBaseline />
      <div className={classes.container}>
        <Notification {...{ ...showNotification, handleNotificationClose }} />
        <Header />
        <Menu />
        <Main>
          <ErrorBoundary>
            <Routes />
          </ErrorBoundary>
        </Main>
        <Footer />
      </div>
    </AppContext.Provider>
  );
};

export default App;
