import Link from "@material-ui/core/Link";
import React from "react";

export const AMAZON_ORDER_PATH =
  "gp/your-account/order-details/ref=ppx_yo_dt_b_order_details_o00?ie=UTF8&orderID=";

const AmazonOrderLink = ({
  children,
  domain,
  confirmationId
}: {
  children?: React.ReactNode;
  domain: string;
  confirmationId: string;
}) => {
  const amazonResourceLink = `${domain}${AMAZON_ORDER_PATH}${confirmationId}`;

  return (
    <Link
      target="_blank"
      rel="noopener noreferrer"
      style={{ display: "flex", alignItems: "center" }}
      href={amazonResourceLink}
    >
      {children || confirmationId}
    </Link>
  );
};

export default AmazonOrderLink;
