import { useEffect, useState } from "react";

import { useQuery } from "@apollo/react-hooks";

import { StringParam, useQueryParam } from "use-query-params";
import ReconcileContext from "./ReconcileContext";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Error from "../Error";
import Loading from "../Loading";
import PageMeta from "../PageMeta";

import { FormattedRow, MatchStatusType } from "./types";
import {
  StripeTransactions,
  StripeTransactions_stripeTransactions_edges
} from "./__generated__/StripeTransactions";
import ReconcileTable from "./ReconcileTable";
import { unionBy } from "lodash";

import transactionsQuery from "./transactions.graphql";

const makeSelectedRow = (transactionId: number, orderId?: number) => {
  if (!orderId) return `${transactionId}`;
  return `${orderId}:${transactionId}`;
};

const formatRows = (rows: StripeTransactions_stripeTransactions_edges[]) => {
  return rows.reduce<FormattedRow[]>((prev, edge) => {
    const order = edge.node.order;

    if (order != null) {
      const formattedOrder: FormattedRow = {
        cursor: edge.cursor,
        id: makeSelectedRow(edge.node.id, order.id),
        matchStatus: {
          type: MatchStatusType.reconciled
        },
        order,
        transaction: edge.node
      };

      return [...prev, formattedOrder];
    } else {
      return [
        ...prev,
        {
          cursor: edge.cursor,
          id: `${edge.node.id}`,
          matchStatus: {
            type: MatchStatusType.unreconciled
          },
          transaction: edge.node
        }
      ];
    }
  }, [] as FormattedRow[]);
};

const MissingRows = () => {
  return (
    <Grid container direction="row" justifyContent="center">
      <Typography>No Transactions found!</Typography>
    </Grid>
  );
};

const ReconcileOverviewPage = () => {
  const [accountId] = useQueryParam("accountId", StringParam);

  const { loading, error, data, fetchMore } = useQuery<StripeTransactions>(
    transactionsQuery,
    {
      variables: { accountId, unreconciledOnly: true }
    }
  );

  const [rows, setRows] = useState<FormattedRow[]>([]);

  useEffect(() => {
    if (data) {
      const edges = data?.stripeTransactions.edges;
      const formattedEdges = formatRows(edges);
      setRows(formattedEdges);
    }
  }, [data]);

  const fetchPage = async (index: string, limit: number) => {
    await fetchMore({
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          stripeTransactions: {
            edges: unionBy(
              prev.stripeTransactions.edges,
              fetchMoreResult.stripeTransactions.edges,
              "node.id"
            ),
            pageInfo: fetchMoreResult.stripeTransactions.pageInfo,
            totalCount: prev.stripeTransactions.totalCount,
            __typename: prev.stripeTransactions.__typename
          }
        };
      },
      variables: {
        accountId,
        unreconciledOnly: true,
        after: index,
        first: limit
      }
    });
  };

  const updateRows = (newRows: FormattedRow[]) => {
    setRows(newRows);
  };

  if (loading) {
    return (
      <>
        <PageMeta title="Reconciliation" />
        <Loading />
      </>
    );
  }

  if (error) {
    return (
      <>
        <PageMeta title="Reconciliation - Error" />
        <Error message={error.message} />
      </>
    );
  }

  if (rows.length === 0) return <MissingRows />;

  const appStateValues = {
    account: accountId
  };

  return (
    <ReconcileContext.Provider value={appStateValues}>
      <div>
        <PageMeta title="Reconciliation" />
        <Typography variant="h4" gutterBottom>
          Reconciliation
        </Typography>

        <ReconcileTable
          hasMoreRows={data?.stripeTransactions.pageInfo.hasNextPage ?? true}
          rows={rows}
          fetchRows={fetchPage}
          updateRows={updateRows}
        />
      </div>
    </ReconcileContext.Provider>
  );
};

export default ReconcileOverviewPage;
