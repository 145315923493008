import React, {
  ReactElement,
  useState,
  Fragment,
  useRef,
  useMemo
} from "react";
import moment from "moment";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import BrightnessAutoIcon from "@material-ui/icons/BrightnessAuto";
import CopyIcon from "@material-ui/icons/FileCopy";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import DevicesOtherIcon from "@material-ui/icons/DevicesOther";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import { Status as TStatus } from "../../config/globalTypes";
import copy from "copy-to-clipboard";

import { addCurrency, getResourceTypeLabel } from "../../util/text-display";

import { Orders_orders_edges_node } from "../Orders/__generated__/Orders";
import { Order_order_shippingDetails as ShippingDetails } from "../Order/__generated__/Order";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import WarningIcon from "@material-ui/icons/Warning";
import {
  CardHeader,
  IconButton,
  CardContent,
  CardActions,
  Collapse,
  Grid,
  Link as MaterialLink,
  Divider,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuList,
  MenuItem
} from "@material-ui/core";
import Notes from "../Notes";
import StatusChip from "../StatusChip";
import { Link } from "react-router-dom";
import SetInProgressButton from "../SetInProgressButton";
import SetPurchasedButton from "../SetPurchasedButton";
import SetPaidButton from "../SetPaidButton";
import SetCancelledButton from "../SetCancelledButton";
import SetOnHoldButton from "../SetOnHoldButton";
import SetRefundedButton from "../SetRefundedButton";
import TriggerActionsButton from "../TriggerActionsButton";
import AssignToButton from "../AssignToButton";
import UpdatePriceButton from "../UpdatePriceButton";
import SetAmazonFulfilmentAutomatic from "../SetAmazonFulfilmentAutomaticButton";
import UpdateOrderAddress from "../UpdateOrderAddress";
import {
  DefaultComponentProps,
  OverridableTypeMap
} from "@material-ui/core/OverridableComponent";
import SetResourceTypeButton from "../SetResourceTypeButton";
import { PhoneNumberFormat, PhoneNumberUtil } from "google-libphonenumber";
import CopyKindleCardMenuItem from "../CopyKindleCardMenuItem";
import AmazonOrderLink from "./AmazonOrderLink";
import { createStyles, makeStyles } from "@material-ui/styles";

const phoneUtil = PhoneNumberUtil.getInstance();

const stripeChipStyles = makeStyles(() =>
  createStyles({
    button: {
      background:
        "linear-gradient(125deg, rgba(255,203,87,1) 0%, rgba(242,81,113,1) 65%, rgba(195,96,213,1) 100%)"
    }
  })
);

const CopyButton = (props: DefaultComponentProps<OverridableTypeMap>) => (
  <IconButton size="small" {...props}>
    <CopyIcon fontSize="small" />
  </IconButton>
);

const StripeChip = () => {
  const classes = stripeChipStyles();
  return <Chip color="secondary" label="Stripe" className={classes.button} />;
};

const CommissionableChip = () => (
  <Chip
    color="secondary"
    label="Commissionable"
    icon={<MonetizationOnIcon />}
  />
);

const FulfimentAutomaticChip = () => (
  <Chip
    color="secondary"
    label="Automatic"
    icon={<BrightnessAutoIcon />}
    style={{ background: "#742aea" }}
  />
);

const ViewPurchaseProcessButton = ({
  supplierId
}: {
  supplierId: string | null;
}) => (
  <MaterialLink
    color="primary"
    href={`/suppliers/${supplierId}`}
    target="_blank"
    style={{
      display: "flex",
      textTransform: "uppercase",
      fontWeight: 500,
      fontSize: "0.9375rem"
    }}
  >
    <FormatListNumberedIcon fontSize="small" /> Purchase Process
  </MaterialLink>
);

const Order = ({
  id,
  status,
  requestedAt,
  additionalNote,
  approvedAt,
  approvedBy,
  purchasedAt,
  purchasedBy,
  paidAt,
  paidBy,
  refundedAt,
  refundedBy,
  cancelledAt,
  cancelledBy,
  internalNote,
  isAdhoc,
  goodsPrice,
  updatedGoodsPrice,
  shippingFees,
  purchaseLink,
  requestId,
  commissionAmount,
  commissionVatAmount,
  vatAmount,
  vatRatePercent,
  requesterOutstandingOrderCount,
  shippingDetails,
  resourceType,
  requestedBy,
  productDescription,
  assignedTo,
  account: {
    id: accountId,
    name,
    currencyCode,
    balance,
    isStripe: isStripeAccount
  },
  title,
  supplier,
  eventDate,
  isFulfilmentAutomatic,
  amazonData
}: Orders_orders_edges_node): ReactElement => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [open, setOpen] = useState(false);

  const anchorRef = useRef<HTMLButtonElement>(null);
  const amazonUrlRegex =
    /(?:https?:\/\/)?(?:www.)?amazon.((co.uk)|(com)|(es)|(de))/i;
  const isAmazonResource = amazonUrlRegex.test(purchaseLink);
  const isKindleOrder = amazonData && amazonData.amazonASIN?.startsWith("B");
  const hasAmazonResourceUrl =
    amazonData?.amazonConfirmationId && supplier?.link;

  const StaticAmazonOrderLink = useMemo(
    () =>
      ({ children }: { children: React.ReactNode }) =>
        hasAmazonResourceUrl ? (
          <AmazonOrderLink
            domain={supplier?.link as string}
            amazonConfirmationId={amazonData?.amazonConfirmationId as string}
          >
            {children}
          </AmazonOrderLink>
        ) : null,
    [amazonData?.amazonConfirmationId, supplier?.link, hasAmazonResourceUrl]
  );

  const ViewAmazonButton = () => (
    <Button
      style={{
        display: "flex",
        textTransform: "uppercase",
        fontWeight: 500,
        fontSize: "0.9375rem"
      }}
    >
      <StaticAmazonOrderLink>
        <FormatListNumberedIcon fontSize="small" /> View on Amazon
      </StaticAmazonOrderLink>
    </Button>
  );

  const defaultEmptyShippingDetails = {
    recepientName: "",
    city: "",
    state: "",
    country: "",
    postcode: "",
    recepientEmail: "",
    street: "",
    telephone: "",
    diallingCode: ""
  };

  const {
    recepientName,
    city,
    state,
    country,
    postcode,
    recepientEmail,
    street,
    telephone,
    diallingCode
  } = shippingDetails || defaultEmptyShippingDetails;

  const handleExpandClick = () => setExpanded(!expanded);
  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <Box mb={2}>
      <Card>
        <Box>
          <CardHeader
            avatar={
              <Grid container spacing={1} justifyContent="flex-start">
                {isStripeAccount && (
                  <Grid item>
                    <StripeChip />
                  </Grid>
                )}
                <Grid item>
                  <StatusChip status={status} />
                </Grid>
                {supplier?.partnerAccount && (
                  <Grid item>
                    <CommissionableChip />
                  </Grid>
                )}
                {isFulfilmentAutomatic && (
                  <Grid item>
                    <FulfimentAutomaticChip />
                  </Grid>
                )}
              </Grid>
            }
            action={
              <>
                <IconButton
                  onClick={handleToggle}
                  aria-label="expand"
                  ref={anchorRef}
                >
                  <MoreHorizIcon />
                </IconButton>
                <Popper
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                  style={{ zIndex: 999 }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom"
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList autoFocusItem={open} id="order-menu">
                            <MaterialLink
                              target="_blank"
                              rel="noopener noreferrer"
                              href={`/orders/${id}/`}
                            >
                              <MenuItem>View order</MenuItem>
                            </MaterialLink>
                            <MenuItem
                              onClick={() =>
                                copy(`${window.location.host}/orders/${id}/`)
                              }
                            >
                              Copy order URL
                            </MenuItem>

                            {isKindleOrder && (
                              <CopyKindleCardMenuItem
                                firstName={recepientName || ""}
                                orderTitle={title}
                              />
                            )}

                            <MaterialLink
                              target="_blank"
                              rel="noopener noreferrer"
                              href={`https://backoffice.app.learner.be/requests/request/${requestId}/`}
                            >
                              <MenuItem>View in Admin</MenuItem>
                            </MaterialLink>
                            <MenuItem onClick={handleExpandClick}>
                              {expanded ? "Hide Status Log" : "Show Status Log"}
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </>
            }
            title={
              assignedTo?.__typename === "User"
                ? `Assigned to ${assignedTo.firstName} ${assignedTo.lastName}`
                : "Unassigned"
            }
            subheader={`Approved ${moment(approvedAt).format(
              "MMMM Do YYYY, h:mm:ss a"
            )}`}
          />

          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Box mb={2}>
                  <Card>
                    <CardContent>
                      <Typography variant="caption">
                        {resourceType
                          ? getResourceTypeLabel(resourceType)
                          : "No type set"}{" "}
                        <SetResourceTypeButton
                          orderId={id}
                          resourceType={resourceType}
                        />
                      </Typography>
                      <Typography variant="h5">{title}</Typography>
                      {eventDate && (
                        <Typography variant="subtitle1">
                          {`Requested date: `}
                          {moment(eventDate).format("MMMM Do YYYY")}
                        </Typography>
                      )}
                    </CardContent>
                    <CardHeader
                      avatar={
                        isAdhoc ? (
                          <Chip
                            icon={<FlashOnIcon />}
                            label="Adhoc"
                            size="small"
                          />
                        ) : (
                          <Chip
                            icon={<DevicesOtherIcon />}
                            label="Internal"
                            size="small"
                          />
                        )
                      }
                      title={
                        status !== TStatus.PAID &&
                        `${addCurrency(goodsPrice, currencyCode)} (incl VAT)`
                      }
                      subheader={productDescription}
                    />
                    {supplier && (
                      <CardContent>
                        Supplier:{" "}
                        <MaterialLink
                          color="inherit"
                          component={Link}
                          to={`/suppliers/${supplier.id}/`}
                        >
                          {supplier.name} 🔗
                        </MaterialLink>
                      </CardContent>
                    )}
                    {status === TStatus.PAID && (
                      <CardContent>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          Paid
                        </Typography>
                        <div>
                          <strong>Price (excl VAT)</strong>{" "}
                          {addCurrency(goodsPrice, currencyCode)}
                        </div>
                        <div>
                          <strong>Shipping (excl VAT)</strong>{" "}
                          {shippingFees &&
                            addCurrency(shippingFees, currencyCode)}
                        </div>
                        <div>
                          <strong>Total VAT</strong>{" "}
                          {vatAmount && addCurrency(vatAmount, currencyCode)}
                        </div>
                        <div>
                          <strong>Comission</strong>{" "}
                          {commissionAmount &&
                            addCurrency(commissionAmount, currencyCode)}
                        </div>
                      </CardContent>
                    )}
                    <CardActions>
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href={purchaseLink}
                      >
                        <Button type="button" size="small">
                          Provider
                        </Button>
                      </a>
                      <a href={`mailto:${recepientEmail}`}>
                        <Button type="button" size="small">
                          Email User
                        </Button>
                      </a>
                      <UpdatePriceButton
                        orderId={id}
                        currencyCode={currencyCode}
                        updatedGoodsPrice={updatedGoodsPrice || 0}
                      />
                    </CardActions>
                  </Card>
                </Box>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  <Typography variant="caption" display="block" gutterBottom>
                    <b>Requested on</b>{" "}
                    <i>
                      {moment(requestedAt).format("MMMM Do YYYY, h:mm:ss a")}
                    </i>
                  </Typography>
                  {approvedAt && (
                    <Typography variant="caption" display="block" gutterBottom>
                      <b>Approved on</b>{" "}
                      <i>
                        {moment(approvedAt).format("MMMM Do YYYY, h:mm:ss a")}
                      </i>{" "}
                      by <b>{approvedBy}</b>
                    </Typography>
                  )}
                  {purchasedAt && purchasedBy && (
                    <Typography variant="caption" display="block" gutterBottom>
                      <b>Purchased on</b>{" "}
                      <i>
                        {moment(purchasedAt).format("MMMM Do YYYY, h:mm:ss a")}
                      </i>{" "}
                      by{" "}
                      <b>
                        {purchasedBy.firstName} {purchasedBy.lastName}
                      </b>
                    </Typography>
                  )}
                  {paidAt && paidBy && (
                    <Typography variant="caption" display="block" gutterBottom>
                      <b>Paid on</b>{" "}
                      <i>{moment(paidAt).format("MMMM Do YYYY, h:mm:ss a")}</i>{" "}
                      by{" "}
                      <b>
                        {paidBy.firstName} {paidBy.lastName}
                      </b>
                    </Typography>
                  )}
                  {refundedAt && refundedBy && (
                    <Typography variant="caption" display="block" gutterBottom>
                      <b>Refunded on</b>{" "}
                      <i>
                        {moment(refundedAt).format("MMMM Do YYYY, h:mm:ss a")}
                      </i>{" "}
                      by{" "}
                      <b>
                        {refundedBy.firstName} {refundedBy.lastName}
                      </b>
                    </Typography>
                  )}
                  {cancelledAt && cancelledBy && (
                    <Typography variant="caption" display="block" gutterBottom>
                      <b>Cancelled on</b>{" "}
                      <i>
                        {moment(cancelledAt).format("MMMM Do YYYY, h:mm:ss a")}
                      </i>{" "}
                      by{" "}
                      <b>
                        {cancelledBy.firstName} {cancelledBy.lastName}
                      </b>
                    </Typography>
                  )}
                </Collapse>
              </Grid>
              <Grid item xs={4}>
                {
                  <Fragment>
                    <Typography variant="overline" gutterBottom>
                      Address{" "}
                      <UpdateOrderAddress
                        orderId={id}
                        shippingAddress={
                          (shippingDetails ||
                            defaultEmptyShippingDetails) as ShippingDetails
                        }
                      />
                    </Typography>
                    <Typography
                      variant="body2"
                      gutterBottom
                      component="address"
                    >
                      <div>
                        {recepientName}{" "}
                        <CopyButton onClick={() => copy(recepientName || "")} />
                      </div>
                      {street && (
                        <div>
                          {street} <CopyButton onClick={() => copy(street)} />
                        </div>
                      )}
                      {postcode && city && (
                        <div>
                          {postcode}{" "}
                          <CopyButton onClick={() => copy(postcode)} />
                          {city} <CopyButton onClick={() => copy(city)} />
                        </div>
                      )}
                      {state && (
                        <div>
                          {state} <CopyButton onClick={() => copy(state)} />
                        </div>
                      )}
                      <div>
                        {country}{" "}
                        <CopyButton onClick={() => copy(country || "")} />
                      </div>
                      <div>
                        {recepientEmail}{" "}
                        <CopyButton
                          onClick={() => copy(recepientEmail || "")}
                        />
                      </div>
                      {telephone && diallingCode && (
                        <div>
                          {phoneUtil.format(
                            phoneUtil.parseAndKeepRawInput(
                              telephone,
                              diallingCode
                            ),
                            PhoneNumberFormat.E164
                          )}{" "}
                          <CopyButton
                            onClick={() =>
                              copy(
                                phoneUtil.format(
                                  phoneUtil.parseAndKeepRawInput(
                                    telephone,
                                    diallingCode
                                  ),
                                  PhoneNumberFormat.E164
                                )
                              )
                            }
                          />
                        </div>
                      )}
                    </Typography>
                    {additionalNote && (
                      <Fragment>
                        <Typography variant="overline" gutterBottom>
                          Note to Learnerbly
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {additionalNote}
                        </Typography>
                      </Fragment>
                    )}
                  </Fragment>
                }
              </Grid>
              <Grid item xs={4}>
                <Notes orderId={id} internalNote={internalNote} />
                {updatedGoodsPrice && (
                  <>
                    <Divider />
                    <Box pt={2} pb={2}>
                      <Typography
                        display="block"
                        variant="overline"
                        gutterBottom
                        noWrap
                      >
                        Updated goods price
                      </Typography>
                      <Typography
                        display="block"
                        variant="body1"
                        gutterBottom
                        noWrap
                      >
                        {addCurrency(updatedGoodsPrice, currencyCode)} from (
                        {addCurrency(goodsPrice, currencyCode)})
                      </Typography>
                    </Box>
                  </>
                )}
                <Divider />
                <Box pt={2} pb={2}>
                  <Typography
                    display="block"
                    variant="overline"
                    gutterBottom
                    noWrap
                  >
                    About {name} <CopyButton onClick={() => copy(name)} /> (
                    {currencyCode})
                  </Typography>
                  <Typography
                    display="block"
                    variant="body1"
                    color={balance <= 0 ? "secondary" : "primary"}
                    gutterBottom
                    noWrap
                  >
                    {balance <= 0 && <WarningIcon fontSize="small" />}{" "}
                    {addCurrency(balance, currencyCode)} left in account
                  </Typography>
                  <MaterialLink
                    color="inherit"
                    component={Link}
                    to={`/accounts/${accountId}/`}
                  >
                    View Account
                  </MaterialLink>
                </Box>
                <Divider />
                <Box pt={2}>
                  <Typography
                    display="block"
                    variant="overline"
                    gutterBottom
                    noWrap
                  >
                    About {recepientName}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    <strong>{requesterOutstandingOrderCount}</strong>{" "}
                    outstanding order
                    {requesterOutstandingOrderCount === 1 ? " " : "s "}
                    <MaterialLink
                      component={Link}
                      to={`/orders/?requestedBy=${requestedBy}`}
                    >
                      View
                    </MaterialLink>
                  </Typography>
                  <MaterialLink
                    rel="noopener noreferrer"
                    target="_blank"
                    href={`https://backoffice.app.learner.be/users/${requestedBy}/`}
                  >
                    View User in Admin
                  </MaterialLink>
                </Box>
              </Grid>
            </Grid>
          </CardContent>

          <CardActions>
            {[TStatus.OPEN, TStatus.ON_HOLD].includes(status) && (
              <SetInProgressButton orderId={id} />
            )}
            {[TStatus.OPEN, TStatus.ON_HOLD].includes(status) && (
              <AssignToButton orderId={id} />
            )}
            {hasAmazonResourceUrl ? <ViewAmazonButton /> : null}

            {status === TStatus.IN_PROGRESS && (
              <Fragment>
                {!amazonData?.externalId ? (
                  <ViewPurchaseProcessButton
                    supplierId={supplier && supplier.id}
                  />
                ) : null}
                <SetPurchasedButton
                  orderId={id}
                  currencyCode={currencyCode}
                  goodsPrice={goodsPrice}
                  resourceType={resourceType}
                  supplierId={supplier && supplier.id}
                  isStripeAccount={isStripeAccount}
                />
                <SetOnHoldButton orderId={id} />
                <TriggerActionsButton
                  orderId={id}
                  hasActions={!!supplier && supplier.hasActions}
                />
              </Fragment>
            )}
            {status === TStatus.PURCHASED && (
              <Fragment>
                {!amazonData?.externalId ? (
                  <ViewPurchaseProcessButton
                    supplierId={supplier && supplier.id}
                  />
                ) : null}
                <SetPaidButton
                  orderId={id}
                  vatAmount={vatAmount || 0}
                  vatRatePercent={vatRatePercent ?? undefined}
                  commissionAmount={commissionAmount || 0}
                  commissionVatAmount={commissionVatAmount || 0}
                  currencyCode={currencyCode}
                  goodsPrice={goodsPrice}
                  shippingFees={shippingFees || 0}
                />
              </Fragment>
            )}
            {[
              TStatus.OPEN,
              TStatus.IN_PROGRESS,
              TStatus.ON_HOLD,
              TStatus.PURCHASED
            ].includes(status) && (
              <SetCancelledButton
                orderId={id}
                disabled={
                  amazonData?.externalId != null &&
                  !!amazonData?.amazonConfirmed
                }
              />
            )}
            {status === TStatus.PAID && <SetRefundedButton orderId={id} />}
            {[TStatus.OPEN, TStatus.IN_PROGRESS, TStatus.ON_HOLD].includes(
              status
            ) &&
              isAmazonResource && (
                <SetAmazonFulfilmentAutomatic
                  orderId={id}
                  currencyCode={currencyCode}
                  amazonASIN={amazonData && amazonData.amazonASIN}
                  buttonText={
                    amazonData && amazonData.externalId
                      ? "Retry Amazon Order"
                      : "Send to Amazon"
                  }
                />
              )}
          </CardActions>
        </Box>
      </Card>
    </Box>
  );
};

export default Order;
