/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AssetType {
  link = "link",
  text = "text"
}

export enum CountryCode {
  AD = "AD",
  AE = "AE",
  AF = "AF",
  AG = "AG",
  AI = "AI",
  AL = "AL",
  AM = "AM",
  AO = "AO",
  AQ = "AQ",
  AR = "AR",
  AS = "AS",
  AT = "AT",
  AU = "AU",
  AW = "AW",
  AX = "AX",
  AZ = "AZ",
  BA = "BA",
  BB = "BB",
  BD = "BD",
  BE = "BE",
  BF = "BF",
  BG = "BG",
  BH = "BH",
  BI = "BI",
  BJ = "BJ",
  BL = "BL",
  BM = "BM",
  BN = "BN",
  BO = "BO",
  BQ = "BQ",
  BR = "BR",
  BS = "BS",
  BT = "BT",
  BV = "BV",
  BW = "BW",
  BY = "BY",
  BZ = "BZ",
  CA = "CA",
  CC = "CC",
  CD = "CD",
  CF = "CF",
  CG = "CG",
  CH = "CH",
  CI = "CI",
  CK = "CK",
  CL = "CL",
  CM = "CM",
  CN = "CN",
  CO = "CO",
  CR = "CR",
  CU = "CU",
  CV = "CV",
  CW = "CW",
  CX = "CX",
  CY = "CY",
  CZ = "CZ",
  DE = "DE",
  DJ = "DJ",
  DK = "DK",
  DM = "DM",
  DO = "DO",
  DZ = "DZ",
  EC = "EC",
  EE = "EE",
  EG = "EG",
  EH = "EH",
  ER = "ER",
  ES = "ES",
  ET = "ET",
  FI = "FI",
  FJ = "FJ",
  FK = "FK",
  FM = "FM",
  FO = "FO",
  FR = "FR",
  GA = "GA",
  GB = "GB",
  GD = "GD",
  GE = "GE",
  GF = "GF",
  GG = "GG",
  GH = "GH",
  GI = "GI",
  GL = "GL",
  GM = "GM",
  GN = "GN",
  GP = "GP",
  GQ = "GQ",
  GR = "GR",
  GS = "GS",
  GT = "GT",
  GU = "GU",
  GW = "GW",
  GY = "GY",
  HK = "HK",
  HM = "HM",
  HN = "HN",
  HR = "HR",
  HT = "HT",
  HU = "HU",
  ID = "ID",
  IE = "IE",
  IL = "IL",
  IM = "IM",
  IN = "IN",
  IO = "IO",
  IQ = "IQ",
  IR = "IR",
  IS = "IS",
  IT = "IT",
  JE = "JE",
  JM = "JM",
  JO = "JO",
  JP = "JP",
  KE = "KE",
  KG = "KG",
  KH = "KH",
  KI = "KI",
  KM = "KM",
  KN = "KN",
  KP = "KP",
  KR = "KR",
  KW = "KW",
  KY = "KY",
  KZ = "KZ",
  LA = "LA",
  LB = "LB",
  LC = "LC",
  LI = "LI",
  LK = "LK",
  LR = "LR",
  LS = "LS",
  LT = "LT",
  LU = "LU",
  LV = "LV",
  LY = "LY",
  MA = "MA",
  MC = "MC",
  MD = "MD",
  ME = "ME",
  MF = "MF",
  MG = "MG",
  MH = "MH",
  MK = "MK",
  ML = "ML",
  MM = "MM",
  MN = "MN",
  MO = "MO",
  MP = "MP",
  MQ = "MQ",
  MR = "MR",
  MS = "MS",
  MT = "MT",
  MU = "MU",
  MV = "MV",
  MW = "MW",
  MX = "MX",
  MY = "MY",
  MZ = "MZ",
  NA = "NA",
  NC = "NC",
  NE = "NE",
  NF = "NF",
  NG = "NG",
  NI = "NI",
  NL = "NL",
  NO = "NO",
  NP = "NP",
  NR = "NR",
  NU = "NU",
  NZ = "NZ",
  OM = "OM",
  PA = "PA",
  PE = "PE",
  PF = "PF",
  PG = "PG",
  PH = "PH",
  PK = "PK",
  PL = "PL",
  PM = "PM",
  PN = "PN",
  PR = "PR",
  PS = "PS",
  PT = "PT",
  PW = "PW",
  PY = "PY",
  QA = "QA",
  QQ = "QQ",
  RE = "RE",
  RO = "RO",
  RS = "RS",
  RU = "RU",
  RW = "RW",
  SA = "SA",
  SB = "SB",
  SC = "SC",
  SD = "SD",
  SE = "SE",
  SG = "SG",
  SH = "SH",
  SI = "SI",
  SJ = "SJ",
  SK = "SK",
  SL = "SL",
  SM = "SM",
  SN = "SN",
  SO = "SO",
  SR = "SR",
  SS = "SS",
  ST = "ST",
  SV = "SV",
  SX = "SX",
  SY = "SY",
  SZ = "SZ",
  TC = "TC",
  TD = "TD",
  TF = "TF",
  TG = "TG",
  TH = "TH",
  TJ = "TJ",
  TK = "TK",
  TL = "TL",
  TM = "TM",
  TN = "TN",
  TO = "TO",
  TR = "TR",
  TT = "TT",
  TV = "TV",
  TW = "TW",
  TZ = "TZ",
  UA = "UA",
  UG = "UG",
  UM = "UM",
  US = "US",
  UY = "UY",
  UZ = "UZ",
  VA = "VA",
  VC = "VC",
  VE = "VE",
  VG = "VG",
  VI = "VI",
  VN = "VN",
  VU = "VU",
  WF = "WF",
  WS = "WS",
  YE = "YE",
  YT = "YT",
  ZA = "ZA",
  ZM = "ZM",
  ZW = "ZW"
}

export enum CurrencyCode {
  AED = "AED",
  AFN = "AFN",
  ALL = "ALL",
  AMD = "AMD",
  ANG = "ANG",
  AOA = "AOA",
  ARS = "ARS",
  AUD = "AUD",
  AWG = "AWG",
  AZN = "AZN",
  BAM = "BAM",
  BBD = "BBD",
  BDT = "BDT",
  BGN = "BGN",
  BHD = "BHD",
  BIF = "BIF",
  BMD = "BMD",
  BND = "BND",
  BOB = "BOB",
  BOV = "BOV",
  BRL = "BRL",
  BSD = "BSD",
  BTN = "BTN",
  BWP = "BWP",
  BYN = "BYN",
  BZD = "BZD",
  CAD = "CAD",
  CDF = "CDF",
  CHE = "CHE",
  CHF = "CHF",
  CHW = "CHW",
  CLF = "CLF",
  CLP = "CLP",
  CNY = "CNY",
  COP = "COP",
  COU = "COU",
  CRC = "CRC",
  CUC = "CUC",
  CUP = "CUP",
  CVE = "CVE",
  CZK = "CZK",
  DJF = "DJF",
  DKK = "DKK",
  DOP = "DOP",
  DZD = "DZD",
  EGP = "EGP",
  ERN = "ERN",
  ETB = "ETB",
  EUR = "EUR",
  FJD = "FJD",
  FKP = "FKP",
  GBP = "GBP",
  GEL = "GEL",
  GHS = "GHS",
  GIP = "GIP",
  GMD = "GMD",
  GNF = "GNF",
  GTQ = "GTQ",
  GYD = "GYD",
  HKD = "HKD",
  HNL = "HNL",
  HRK = "HRK",
  HTG = "HTG",
  HUF = "HUF",
  IDR = "IDR",
  ILS = "ILS",
  INR = "INR",
  IQD = "IQD",
  IRR = "IRR",
  ISK = "ISK",
  JMD = "JMD",
  JOD = "JOD",
  JPY = "JPY",
  KES = "KES",
  KGS = "KGS",
  KHR = "KHR",
  KMF = "KMF",
  KPW = "KPW",
  KRW = "KRW",
  KWD = "KWD",
  KYD = "KYD",
  KZT = "KZT",
  LAK = "LAK",
  LBP = "LBP",
  LKR = "LKR",
  LRD = "LRD",
  LSL = "LSL",
  LYD = "LYD",
  MAD = "MAD",
  MDL = "MDL",
  MGA = "MGA",
  MKD = "MKD",
  MMK = "MMK",
  MNT = "MNT",
  MOP = "MOP",
  MRU = "MRU",
  MUR = "MUR",
  MVR = "MVR",
  MWK = "MWK",
  MXN = "MXN",
  MXV = "MXV",
  MYR = "MYR",
  MZN = "MZN",
  NAD = "NAD",
  NGN = "NGN",
  NIO = "NIO",
  NOK = "NOK",
  NPR = "NPR",
  NZD = "NZD",
  OMR = "OMR",
  PAB = "PAB",
  PEN = "PEN",
  PGK = "PGK",
  PHP = "PHP",
  PKR = "PKR",
  PLN = "PLN",
  PYG = "PYG",
  QAR = "QAR",
  RON = "RON",
  RSD = "RSD",
  RUB = "RUB",
  RWF = "RWF",
  SAR = "SAR",
  SBD = "SBD",
  SCR = "SCR",
  SDG = "SDG",
  SEK = "SEK",
  SGD = "SGD",
  SHP = "SHP",
  SLL = "SLL",
  SOS = "SOS",
  SRD = "SRD",
  SSP = "SSP",
  STN = "STN",
  SVC = "SVC",
  SYP = "SYP",
  SZL = "SZL",
  THB = "THB",
  TJS = "TJS",
  TMT = "TMT",
  TND = "TND",
  TOP = "TOP",
  TRY = "TRY",
  TTD = "TTD",
  TWD = "TWD",
  TZS = "TZS",
  UAH = "UAH",
  UGX = "UGX",
  USD = "USD",
  USN = "USN",
  UYI = "UYI",
  UYU = "UYU",
  UYW = "UYW",
  UZS = "UZS",
  VED = "VED",
  VES = "VES",
  VND = "VND",
  VUV = "VUV",
  WST = "WST",
  XAF = "XAF",
  XAG = "XAG",
  XAU = "XAU",
  XBA = "XBA",
  XBB = "XBB",
  XBC = "XBC",
  XBD = "XBD",
  XCD = "XCD",
  XDR = "XDR",
  XOF = "XOF",
  XPD = "XPD",
  XPF = "XPF",
  XPT = "XPT",
  XSU = "XSU",
  XTS = "XTS",
  XUA = "XUA",
  XXX = "XXX",
  YER = "YER",
  ZAR = "ZAR",
  ZMW = "ZMW",
  ZWL = "ZWL"
}

export enum PreferredPaymentMethod {
  CARD = "CARD",
  PAYOUT = "PAYOUT",
  PAYOUT_CLIENT = "PAYOUT_CLIENT"
}

export enum RateType {
  commissionRates = "commissionRates",
  discounts = "discounts"
}

export enum ResourceType {
  ARTICLE = "ARTICLE",
  BOOK = "BOOK",
  COACH = "COACH",
  CONFERENCE = "CONFERENCE",
  COURSE = "COURSE",
  MEETUP = "MEETUP",
  ONLINE_COURSE = "ONLINE_COURSE",
  OTHER = "OTHER",
  PODCAST = "PODCAST",
  VIDEO = "VIDEO",
  WORKSHOP = "WORKSHOP"
}

export enum SemanticErrorCode {
  EXISTING_LINK = "EXISTING_LINK",
  INVALID_STATUS = "INVALID_STATUS",
  MISMATCH_CURRENCY = "MISMATCH_CURRENCY",
  NEGATIVE_ORDER_PRICE = "NEGATIVE_ORDER_PRICE",
  NON_REFUNDABLE_ORDER = "NON_REFUNDABLE_ORDER",
  NOT_FOUND = "NOT_FOUND",
  TRANSACTIONS_DONT_SUM_ZERO = "TRANSACTIONS_DONT_SUM_ZERO"
}

export enum SortBy {
  createdAt = "createdAt",
  id = "id",
  purchasedAt = "purchasedAt",
  requestedAt = "requestedAt"
}

export enum SortOrder {
  ASC = "ASC",
  DESC = "DESC"
}

export enum Status {
  CANCELLED = "CANCELLED",
  IN_PROGRESS = "IN_PROGRESS",
  ON_HOLD = "ON_HOLD",
  OPEN = "OPEN",
  PAID = "PAID",
  PURCHASED = "PURCHASED",
  REFUNDED = "REFUNDED"
}

export enum StripeAmazonOrderStatus {
  CANCELLED = "CANCELLED",
  CONFIRMED = "CONFIRMED",
  REJECTED = "REJECTED",
  SHIPPED = "SHIPPED",
  SUBMITTED = "SUBMITTED"
}

export enum StripeOrderRequestType {
  ADHOC = "ADHOC",
  BOOK = "BOOK",
  CATALOGUE = "CATALOGUE"
}

export enum StripeOrderStatus {
  IN_PROGRESS = "IN_PROGRESS",
  ON_HOLD = "ON_HOLD",
  OPEN = "OPEN",
  PURCHASED = "PURCHASED",
  WONT_PURCHASE = "WONT_PURCHASE"
}

export enum StripeTransactionStatus {
  CANCELLED = "CANCELLED",
  FAILED = "FAILED",
  IN_PROGRESS = "IN_PROGRESS",
  SETTLED = "SETTLED"
}

export enum StripeTransactionType {
  MANUAL_REFUND = "MANUAL_REFUND",
  PAYOUT = "PAYOUT",
  PAYOUT_PARTNER = "PAYOUT_PARTNER",
  PURCHASE = "PURCHASE",
  REFUND = "REFUND",
  TOPUP = "TOPUP"
}

export enum TransactionType {
  CANCELLED = "CANCELLED",
  FAILED = "FAILED",
  FIX = "FIX",
  PURCHASE = "PURCHASE",
  REFUND = "REFUND",
  TOPUP = "TOPUP"
}

export enum VatType {
  REDUCED = "REDUCED",
  STANDARD = "STANDARD"
}

export interface AccountFilters {
  isStripe?: boolean | null;
  isArchived?: boolean | null;
}

export interface AmazonDirectOrderSettingsInput {
  country: CountryCode;
  groupName: string;
  purchaseUrl: GQL.URL;
  username: string;
  secret: string;
  adminEmail?: string | null;
}

export interface AmazonFulfilmentAutomaticInput {
  orderId: string;
  amazonASIN: string;
  updatedGoodsPrice?: GQL.Fixed | null;
}

export interface CreateAccountInput {
  revolutAccountId?: string | null;
  name: string;
  currencyCode: CurrencyCode;
  isAmazonFulfilmentAutomatic: boolean;
  isPayByInvoiceActiveAt?: GQL.DateTime | null;
  isStripe?: boolean | null;
  amazonBusinessAccounts?: AmazonDirectOrderSettingsInput[] | null;
}

export interface CreatePurchaseProcessInput {
  supplierId: string;
  name: string;
  partnerAccountId?: string | null;
  description?: string | null;
  isPartnerPurchaseProcess: boolean;
}

export interface CreatePurchaseProcessStepInput {
  purchaseProcessId: string;
  position: number;
  title?: string | null;
  body?: string | null;
}

export interface CreateSupplierInput {
  name: string;
  link: string;
  countryCode: CountryCode;
  learnerblySupplierId?: string | null;
  note?: string | null;
  vatRatePercent?: GQL.Fixed | null;
}

export interface CreateTransactionInput {
  accountId?: string | null;
  amount: GQL.Fixed;
  type?: TransactionType | null;
  internalNote?: string | null;
  externalSource?: string | null;
  externalSourceId?: string | null;
  status?: string | null;
  cardId?: string | null;
  orderId?: string | null;
  lastCardDigits?: string | null;
  statementDescriptor?: string | null;
  externalSourceAccountId?: string | null;
  currency?: CurrencyCode | null;
}

export interface IssueStripePayoutInput {
  orderId: number;
  amount: GQL.Fixed;
  supplierId: string;
  payoutDestination?: string | null;
}

export interface OrderFilters {
  unassigned?: boolean | null;
  untyped?: boolean | null;
  assignedTo?: string[] | null;
  account?: string[] | null;
  requestedBy?: string[] | null;
  status?: Status[] | null;
  resourceType?: ResourceType[] | null;
  supplier?: string[] | null;
  isFulfilmentAutomatic?: boolean | null;
}

export interface StripeOrderFilters {
  unassigned?: boolean | null;
  unreconciled?: boolean | null;
  assignedTo?: string[] | null;
  account?: string[] | null;
  requestedBy?: string[] | null;
  status?: StripeOrderStatus[] | null;
  resourceType?: ResourceType[] | null;
  supplier?: string[] | null;
}

export interface StripeShippingDetailsInput {
  city?: string | null;
  state?: string | null;
  country?: string | null;
  postcode?: string | null;
  recipientEmail?: string | null;
  recipientName?: string | null;
  street?: string | null;
  telephone?: string | null;
  diallingCode?: string | null;
}

export interface SupplierFilters {
  isArchived?: boolean | null;
}

export interface UnlinkStripeOrderFromTransactionInput {
  transactionId: number;
  orderId: number;
}

export interface UpdateAccountInput {
  revolutAccountId?: string | null;
  name?: string | null;
  amazonBusinessAccounts?: AmazonDirectOrderSettingsInput[] | null;
  isAmazonFulfilmentAutomatic?: boolean | null;
  isPayByInvoiceActiveAt?: GQL.DateTime | null;
  archivedAt?: GQL.DateTime | null;
}

export interface UpdatePartnerAccountInput {
  name?: string | null;
  description?: string | null;
  commissionRates?: string | null;
  discounts?: string | null;
  contacts?: string | null;
  assets?: string | null;
}

export interface UpdatePurchaseProcessInput {
  partnerAccountId?: string | null;
  name?: string | null;
  description?: string | null;
  isPartnerPurchaseProcess?: boolean | null;
}

export interface UpdatePurchaseProcessStepInput {
  title?: string | null;
  body?: string | null;
}

export interface UpdateSupplierInput {
  name?: string | null;
  link?: string | null;
  countryCode?: CountryCode | null;
  learnerblySupplierId?: string | null;
  note?: string | null;
  webhook?: string | null;
  partnerAccountId?: string | null;
  preferredPaymentMethod?: PreferredPaymentMethod | null;
  isPayoutAllowed?: boolean | null;
  isCardAllowed?: boolean | null;
  isPayoutClientAllowed?: boolean | null;
  vatRatePercent?: GQL.Fixed | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
