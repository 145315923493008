import React, { useContext, useState, Fragment } from "react";
import { useMutation } from "@apollo/react-hooks";
import AppContext from "../App/AppContext";
import {
  UpdateStripeShippingDetailsVariables as TUpdateShippingDetailsVariables,
  UpdateStripeShippingDetails as TUpdateStripeShippingDetails
} from "./__generated__/UpdateStripeShippingDetails";

import { NotificationTypes } from "../Notification/Notification";
import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import TextField from "../TextField";

import { withFormState } from "../../util/form-validation";
import { ApolloError } from "apollo-client";
import { StripeShippingDetailsInput } from "../../config/globalTypes";

import updateShippingDetailsMutation from "./update-shipping-details.graphql";

interface TFormState {
  recipientName: string;
  city: string;
  state: string;
  country: string;
  postcode: string;
  recipientEmail: string;
  street: string;
  telephone: string;
  diallingCode: string;
}

const StripeShippingDetails = ({
  orderId,
  shippingDetails
}: {
  orderId: number;
  shippingDetails: StripeShippingDetailsInput;
}) => {
  const [mutation, { loading }] = useMutation<
    TUpdateStripeShippingDetails,
    TUpdateShippingDetailsVariables
  >(updateShippingDetailsMutation, {
    onCompleted: () => setIsOpen(false),
    onError: (error: ApolloError) =>
      handleShowNotification({
        type: NotificationTypes.error,
        message: error.message
      })
  });
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { handleShowNotification } = useContext(AppContext);

  const [formState, setFormState] = useState<TFormState>({
    city: shippingDetails.city || "",
    country: shippingDetails.country || "",
    diallingCode: shippingDetails.diallingCode || "",
    postcode: shippingDetails.postcode || "",
    recipientEmail: shippingDetails.recipientEmail || "",
    recipientName: shippingDetails.recipientName || "",
    state: shippingDetails.state || "",
    street: shippingDetails.street || "",
    telephone: shippingDetails.telephone || ""
  });

  const handleFieldChange = withFormState<TFormState>(formState, setFormState);

  return (
    <Fragment>
      <IconButton
        size="small"
        disabled={loading}
        onClick={() => setIsOpen(true)}
      >
        <EditIcon fontSize="small" />
      </IconButton>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Update the shipping address for this order below. We'll use this
            updated address when processing as an automatic order e.g Amazon.
          </DialogContentText>
          <form noValidate autoComplete="off">
            <TextField
              id="recipientName"
              name="recipientName"
              label="Recipient name"
              value={formState.recipientName}
              onChange={handleFieldChange<string>("recipientName")}
              required
            />
            <TextField
              id="recipientEmail"
              name="recipientEmail"
              label="Recipient email"
              value={formState.recipientEmail}
              onChange={handleFieldChange<string>("recipientEmail")}
              required
            />
            <TextField
              id="street"
              name="street"
              label="Street"
              value={formState.street}
              onChange={handleFieldChange<string>("street")}
              required
            />
            <TextField
              id="city"
              name="city"
              label="City"
              value={formState.city}
              onChange={handleFieldChange<string>("city")}
              required
            />
            <TextField
              id="state"
              name="state"
              label="State"
              value={formState.state}
              onChange={handleFieldChange<string>("state")}
              required
            />
            <TextField
              id="postcode"
              name="postcode"
              label="Postcode"
              value={formState.postcode}
              onChange={handleFieldChange<string>("postcode")}
              required
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)} disabled={loading}>
            Cancel
          </Button>
          <Button
            onClick={() =>
              mutation({
                variables: {
                  orderId,
                  shippingDetails: formState
                }
              })
            }
            color="primary"
            autoFocus
            disabled={loading}
          >
            {loading ? "Updating..." : "Update address"}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default StripeShippingDetails;
