import React, { useContext } from "react";
import { MenuItem } from "@material-ui/core";
import generateMessage from "./generateMessage";
import copy from "copy-to-clipboard";
import AppContext from "../App/AppContext";
import { NotificationTypes } from "../Notification/Notification";

interface Props {
  firstName: string;
  orderTitle: string;
}
const CopyKindleCardMenuItem = ({ firstName, orderTitle }: Props) => {
  const { handleShowNotification } = useContext(AppContext);

  const onClick = () => {
    const message = generateMessage({
      firstName,
      orderTitle
    });

    copy(message);

    handleShowNotification({
      type: NotificationTypes.info,
      message: "Copied gift card message"
    });
  };

  return <MenuItem onClick={onClick}>Copy gift card message</MenuItem>;
};

export default CopyKindleCardMenuItem;
