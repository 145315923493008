import gql from "graphql-tag";

export default gql`
  mutation rectifyCheckoutValues(
    $orderId: Int!
    $checkoutGrossPrice: Fixed!
    $vatAmount: Fixed!
    $vatRatePercent: Fixed
  ) {
    rectifyCheckoutValues(
      orderId: $orderId
      checkoutGrossPrice: $checkoutGrossPrice
      vatAmount: $vatAmount
      vatRatePercent: $vatRatePercent
    ) {
      id
      vatAmount
      vatRatePercent
      markedPurchased {
        checkoutGrossPrice
      }
    }
  }
`;
