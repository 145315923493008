import { makeStyles, createStyles, Theme } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import classnames from "classnames";
import { MatchStatusType } from "./types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginLeft: "1em",
      marginRight: "1em"
    },
    noDot: {
      width: "1rem" // Match the grid spacing
    },
    dot: {
      height: "8px",
      width: "8px",
      borderRadius: "50%",
      backgroundColor: "#000"
    },
    red: {
      backgroundColor: "#c2340b"
    },
    blue: {
      backgroundColor: "#9be1f1"
    },
    green: {
      backgroundColor: "#00a88a"
    },
    row: {
      whiteSpace: "nowrap"
    }
  })
);

interface Props {
  matchType: MatchStatusType;
  autoMatchScore?: number;
}

const MatchState = ({ matchType, autoMatchScore }: Props) => {
  const classes = useStyles();

  if (matchType === MatchStatusType.reconciled) {
    return (
      <>
        <Grid item>
          <div className={classes.dot} />
        </Grid>

        <Grid item>Reconciled!</Grid>
      </>
    );
  }

  if (matchType === MatchStatusType.manual) {
    return (
      <>
        <Grid item>
          <div className={classnames(classes.dot, classes.blue)} />
        </Grid>

        <Grid item>Manual</Grid>
      </>
    );
  }

  if (matchType === MatchStatusType.auto && autoMatchScore) {
    const colorClass = autoMatchScore > 50 ? classes.green : classes.red;

    return (
      <>
        <Grid item>
          <div className={classnames(classes.dot, colorClass)} />
        </Grid>

        <Grid item>
          <div className={classes.row}>
            <b>Auto</b> – {autoMatchScore.toFixed(0)}%
          </div>
          Confidence
        </Grid>
      </>
    );
  }

  return (
    <>
      <div className={classes.noDot} />
      <Grid item>No Match</Grid>
    </>
  );
};

const MatchStateContainer = (props: Props) => {
  const classes = useStyles();

  return (
    <Grid
      className={classes.container}
      container
      wrap="nowrap"
      alignItems="center"
      spacing={1}
    >
      <MatchState {...props} />
    </Grid>
  );
};

export default MatchStateContainer;
