import gql from "graphql-tag";

export default gql`
  mutation UpdateSupplier($id: ID!, $input: UpdateSupplierInput!) {
    updateSupplier(id: $id, input: $input) {
      supplier {
        id
        name
        note
        countryCode
        link
        learnerblySupplierId
        webhook
        preferredPaymentMethod
        isPayoutAllowed
        isCardAllowed
        isPayoutClientAllowed
        vatRatePercent
      }
      updateSupplierError {
        message
      }
    }
  }
`;
