import { useContext, useEffect, useMemo, useState } from "react";
import { useQuery } from "@apollo/react-hooks";

import { unionBy } from "lodash";
import { Link } from "react-router-dom";
import moment from "moment";

import ReconcileContext from "../Reconcile/ReconcileContext";

import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import MaterialLink from "@material-ui/core/Link";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import CheckIcon from "@material-ui/icons/Check";
import { createStyles, makeStyles } from "@material-ui/core";

import Error from "../Error";
import Loading from "../Loading";
import DataCell from "../DataCell";

import { UnreconciledStripeOrders } from "./__generated__/UnreconciledStripeOrders";
import { Status } from "../../config/globalTypes";
import { Order } from "../Reconcile/types";

import ordersQuery from "./orders.graphql";

const useStyles = makeStyles(() =>
  createStyles({
    link: {
      color: "#4556b7"
    },
    tableHead: {
      backgroundColor: "#f9fafa",
      "& th": {
        fontWeight: "bold",
        color: "#7d7d7d"
      }
    }
  })
);

const NUM_ROWS = 10;

interface Row {
  cursor: string;
  order: Order;
}

const formatRows = (data: UnreconciledStripeOrders): Array<Row> => {
  const edges = data.stripeOrders.edges;
  return edges.map(edge => ({
    cursor: edge.cursor,
    order: edge.node
  }));
};

const LoadingRow = () => {
  return (
    <TableRow>
      <DataCell colSpan={9}>
        <Loading />
      </DataCell>
    </TableRow>
  );
};

const MissingRows = () => {
  return (
    <TableRow>
      <DataCell colSpan={9} align="center">
        No Orders Found!
      </DataCell>
    </TableRow>
  );
};

interface Props {
  onConfirm: (order: Order) => void;
}

const OrdersTable = ({ onConfirm }: Props) => {
  const classes = useStyles();

  const [page, setPage] = useState<number>(0);
  const [paginationLoading, setPaginationLoading] = useState<boolean>(false);

  const [rows, setRows] = useState<Array<Row>>([]);

  const { account } = useContext(ReconcileContext);

  const { loading, error, data, fetchMore } =
    useQuery<UnreconciledStripeOrders>(ordersQuery, {
      variables: {
        sortBy: "purchasedAt",
        filters: {
          status: [Status.PURCHASED], // only match against purchased orders
          unreconciled: true,
          ...(account && { account: [account] })
        }
      }
    });
  const numberOfPages = useMemo(
    () => Math.ceil(rows.length / NUM_ROWS),
    [rows]
  );

  const currentRows = useMemo(() => {
    const currentIndex = page * NUM_ROWS;
    return rows.slice(currentIndex, currentIndex + NUM_ROWS);
  }, [page, rows]);

  useEffect(() => {
    if (data) {
      setRows(formatRows(data));
    }
  }, [data]);

  const fetchPage = async (index: string) => {
    try {
      setPaginationLoading(true);
      await fetchMore({
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            stripeOrders: {
              __typename: prev.stripeOrders.__typename,
              edges: unionBy(
                prev.stripeOrders.edges,
                fetchMoreResult.stripeOrders.edges,
                "node.id"
              ),
              totalCount: fetchMoreResult.stripeOrders.totalCount,
              pageInfo: fetchMoreResult.stripeOrders.pageInfo
            }
          };
        },
        variables: { after: index }
      });
    } finally {
      setPaginationLoading(false);
    }
  };

  const changePage = async (pageIndex: number) => {
    if (paginationLoading) return;
    if (pageIndex + 1 > numberOfPages)
      await fetchPage(rows[rows.length - 1].cursor);
    setPage(pageIndex);
  };

  if (error) {
    return <Error message={error.message} />;
  }

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="h5">
          <b>Unreconciled Orders</b>
        </Typography>
      </Box>
      <Table>
        <TableHead className={classes.tableHead}>
          <TableRow>
            <DataCell>Order Purchase Date</DataCell>
            <DataCell>Order Amount</DataCell>
            <DataCell>Supplier</DataCell>
            <DataCell>VAT</DataCell>
            <DataCell>Order Title</DataCell>
            <DataCell>Order Info</DataCell>
            <DataCell>Confirm?</DataCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {loading ? (
            <LoadingRow />
          ) : currentRows.length === 0 ? (
            <MissingRows />
          ) : (
            currentRows.map(row => (
              <TableRow key={row.order.id}>
                <DataCell>
                  {row.order.markedPurchased?.at
                    ? moment(row.order.markedPurchased.at).format(
                        "DD, MMM YYYY, HH:mm"
                      )
                    : undefined}
                </DataCell>
                <DataCell>
                  {row.order.markedPurchased?.checkoutGrossPrice}
                  {row.order.requestCurrencyCode &&
                    row.order.requestGrossPrice && (
                      <Typography variant="caption" color="textSecondary">
                        {" "}
                        ({row.order.requestGrossPrice.toFixed(2)}{" "}
                        {row.order.requestCurrencyCode})
                      </Typography>
                    )}
                </DataCell>
                <DataCell>{row.order.supplier?.name}</DataCell>
                <DataCell>{row.order.vatAmount}</DataCell>
                <DataCell>{row.order.title}</DataCell>
                <DataCell>
                  <MaterialLink
                    className={classes.link}
                    underline="none"
                    color="inherit"
                    component={Link}
                    target="_blank"
                    to={`/orders-stripe/${row.order.id}/`}
                  >
                    View Order
                  </MaterialLink>
                </DataCell>
                <DataCell padding="none">
                  <IconButton onClick={() => onConfirm(row.order)}>
                    <CheckIcon />
                  </IconButton>
                </DataCell>
              </TableRow>
            ))
          )}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              count={data?.stripeOrders.totalCount ?? 0}
              page={page}
              rowsPerPage={NUM_ROWS}
              onPageChange={async (_, pageIndex) => changePage(pageIndex)}
              rowsPerPageOptions={[]}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </Box>
  );
};

export default OrdersTable;
