import gql from "graphql-tag";

export default gql`
  mutation UpdateAccount($id: ID!, $input: UpdateAccountInput!) {
    updateAccount(id: $id, input: $input) {
      id
      name
      balance
      revolutAccountId
      isAmazonFulfilmentAutomatic
      isPayByInvoiceActiveAt
      amazonBusinessAccounts {
        country
        groupName
        purchaseUrl
        username
        secret
      }
    }
  }
`;
