import { useMutation } from "@apollo/react-hooks";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Card,
  Typography,
  Box,
  IconButton
} from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";
import { ExecutionResult } from "graphql";

import moment from "moment";
import { useContext, useState } from "react";
import AppContext from "../App/AppContext";
import DataCell from "../DataCell";
import { NotificationTypes } from "../Notification/Notification";
import { StripeOrder } from "./__generated__/StripeOrder";
import {
  UnlinkStripeOrderFromTransactionVariables as TUnlinkOrderFromTransactionVariables,
  UnlinkStripeOrderFromTransaction as TUnlinkOrderFromTransaction
} from "./__generated__/UnlinkStripeOrderFromTransaction";

import unconfirmTransactionMutation from "./unconfirm-transaction.graphql";
import StripeTransactionLink from "./StripeTransactionLink";

const MissingRows = () => {
  return (
    <TableRow>
      <DataCell align="center" colSpan={6}>
        No transactions found
      </DataCell>
    </TableRow>
  );
};

const UnlinkIconButton = ({
  orderId,
  transactionId,
  onClick,
  onError
}: {
  orderId: number;
  transactionId: number;
  onClick: (
    input: TUnlinkOrderFromTransactionVariables
  ) => Promise<ExecutionResult<TUnlinkOrderFromTransaction>>;
  onError: (error: Error) => void;
}) => {
  const [loading, setLoading] = useState(false);

  const handleClick = async (
    input: TUnlinkOrderFromTransactionVariables["input"]
  ) => {
    try {
      setLoading(true);
      const { data } = await onClick({ input });
      if (
        data?.unlinkStripeOrderFromTransaction.__typename === "SemanticError"
      ) {
        throw new Error(data?.unlinkStripeOrderFromTransaction.message);
      }
    } catch (err) {
      onError(err as Error);
      setLoading(false);
    }
  };

  return (
    <IconButton
      size="small"
      disabled={loading}
      onClick={() =>
        handleClick({
          transactionId,
          orderId
        })
      }
    >
      <Delete aria-label="unconfirm" />
    </IconButton>
  );
};

interface Props {
  transactions: StripeOrder["stripeOrder"]["transactions"];
  orderId: number;
}

const StripeTransactionTable = ({ transactions, orderId }: Props) => {
  const { handleShowNotification } = useContext(AppContext);

  const handleError = (error: Error) =>
    handleShowNotification({
      type: NotificationTypes.error,
      message: error.message
    });

  const [mutation] = useMutation<
    TUnlinkOrderFromTransaction,
    TUnlinkOrderFromTransactionVariables
  >(unconfirmTransactionMutation);

  return (
    <Card>
      <Box margin={2}>
        <Typography variant="h4" gutterBottom>
          Transactions
        </Typography>
      </Box>

      <Table>
        <TableHead>
          <TableRow>
            <DataCell>ID</DataCell>
            <DataCell>Created</DataCell>
            <DataCell>Last Card Digits</DataCell>
            <DataCell>Type</DataCell>
            <DataCell>Amount</DataCell>
            <DataCell>Stripe</DataCell>
            <DataCell>Remove</DataCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {!transactions || transactions.length === 0 ? (
            <MissingRows />
          ) : (
            transactions.map((transaction: Record<string, any>) => {
              return (
                <TableRow key={transaction.id}>
                  <DataCell>{transaction.id}</DataCell>
                  <DataCell>
                    {moment(transaction.createdAt).format(
                      "DD, MMM YYYY, hh:mm"
                    )}
                  </DataCell>
                  <DataCell>{transaction.lastCardDigits}</DataCell>
                  <DataCell>{transaction.type}</DataCell>
                  <DataCell>{transaction.amount}</DataCell>
                  <DataCell>
                    {transaction.stripeAccountId &&
                      transaction.stripeTransactionId && (
                        <StripeTransactionLink
                          type={transaction.type}
                          accountId={transaction.stripeAccountId}
                          transactionId={transaction.stripeTransactionId}
                        />
                      )}
                  </DataCell>
                  <TableCell>
                    <UnlinkIconButton
                      orderId={orderId}
                      transactionId={transaction.id}
                      onClick={variables => mutation({ variables })}
                      onError={handleError}
                    />
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </Card>
  );
};

export default StripeTransactionTable;
