import gql from "graphql-tag";

export default gql`
  mutation UpdateGoodsPrice($orderId: ID!, $updatedGoodsPrice: Fixed!) {
    setUpdatedGoodsPrice(orderId: $orderId, goodsPrice: $updatedGoodsPrice) {
      id
      goodsPrice
      updatedGoodsPrice
    }
  }
`;
