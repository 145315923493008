import { useMutation } from "@apollo/react-hooks";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import { ApolloError } from "apollo-client";

import { useContext, useState } from "react";
import { handleSubmit } from "../../util/form-validation";
import AppContext from "../App/AppContext";
import { NotificationTypes } from "../Notification/Notification";
import RectifyPriceForm from "./RectifyPriceForm";
import {
  rectifyCheckoutValues,
  rectifyCheckoutValuesVariables
} from "./__generated__/rectifyCheckoutValues";

import rectifyCheckoutValuesMutation from "./rectify-checkout-values.graphql";

interface StripeRectifyCheckoutValues {
  checkoutGrossPrice: GQL.Fixed;
  vatAmount?: GQL.Fixed;
  vatRatePercent?: GQL.Fixed;
  currencyCode: string;
}

interface StripeRectifyCheckoutProps {
  orderId: number;
  checkoutValues: StripeRectifyCheckoutValues;
}

interface TFormState {
  checkoutGrossPrice: GQL.Fixed;
  vatAmount: GQL.Fixed;
  vatRatePercent: GQL.Fixed;
}

const toFixed = (value: string | number) =>
  Number(Number.parseFloat(value.toString().replace(",", "")).toFixed(2));

const StripeRectifyPriceButton = ({
  orderId,
  checkoutValues: {
    checkoutGrossPrice,
    vatAmount,
    vatRatePercent,
    currencyCode
  }
}: StripeRectifyCheckoutProps) => {
  const { handleShowNotification } = useContext(AppContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [mutation, { loading }] = useMutation<
    rectifyCheckoutValues,
    rectifyCheckoutValuesVariables
  >(rectifyCheckoutValuesMutation, {
    onCompleted: () => setIsOpen(false),
    onError: (error: ApolloError) =>
      handleShowNotification({
        type: NotificationTypes.error,
        message: error.message
      })
  });

  const onSubmit = handleSubmit((formData: TFormState) => {
    return mutation({
      variables: {
        orderId,
        checkoutGrossPrice: toFixed(formData.checkoutGrossPrice),
        vatRatePercent: toFixed(formData.vatRatePercent),
        vatAmount: toFixed(formData.vatAmount)
      }
    });
  });

  return (
    <>
      <Button color="secondary" onClick={() => setIsOpen(true)} size="large">
        Update prices
      </Button>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>Update Prices</DialogTitle>
        <form onSubmit={onSubmit}>
          <DialogContent>
            <RectifyPriceForm
              currencyCode={currencyCode}
              formValues={{ checkoutGrossPrice, vatAmount, vatRatePercent }}
            />
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button color="primary" type="submit" disabled={loading}>
              Update Prices
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default StripeRectifyPriceButton;
