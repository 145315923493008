import gql from "graphql-tag";

export default gql`
  query WhoAmI {
    me {
      id
      firstName
      lastName
    }
  }
`;
