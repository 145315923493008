import gql from "graphql-tag";

export default gql`
  mutation ArchiveAccount($id: ID!) {
    archiveAccount(id: $id) {
      id
      archived
    }
  }
`;
