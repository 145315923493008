import React, { ChangeEvent, ReactElement } from "react";

import { SetQuery } from "use-query-params";
import { NonNullOrderFilters } from "../Filter/Filter";

import { SortOrder as TSortOrder } from "../../../config/globalTypes";
import SelectField from "../../SelectField";
import { getSortOrder } from "../../../util/text-display";

const SortOrder = ({
  onChange,
  sortOrder,
  filters
}: {
  onChange: SetQuery<{}>;
  sortOrder: TSortOrder;
  filters: NonNullOrderFilters;
}): ReactElement => {
  const handleSelectChange = (
    value: ChangeEvent<{
      value: unknown;
    }>
  ) => {
    onChange(
      {
        sortOrder: value,
        ...filters
      },
      "push"
    );
  };

  return (
    <form>
      <SelectField
        label="Sort by"
        value={sortOrder}
        onChange={handleSelectChange}
        values={Object.keys(TSortOrder).map(orderType => ({
          key: orderType,
          label: getSortOrder(orderType as TSortOrder)
        }))}
      />
    </form>
  );
};

export default SortOrder;
