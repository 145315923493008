import gql from "graphql-tag";

export default gql`
  query OpenAmazonOrders($accountId: ID!) {
    orders(
      first: 150
      filters: {
        supplier: [
          "dfd40e29-a0b7-44e1-b22d-e2078211de99"
          "84abf3d2-1568-467b-bb2d-df36ecdb4031"
          "0fc407f0-1cea-484c-b28d-c49211f63723"
          "06ad593a-e6a6-40e4-bbf8-5480bfd88241"
          "69ffac76-912e-4050-b0d9-4d1edf908da9"
          "2915f375-9ca8-4a75-984a-adfa73f1e516"
          "12f00acb-ea82-47a4-a042-f1f8c751ed0a"
          "69a0a2e7-9f21-44e9-8f92-e539aae99786"
          "9bc0c615-8cf5-4b27-adf2-63c716c33862"
          "427bdc97-0abf-43ef-8523-4220b4f31539"
        ]
        status: [OPEN, ON_HOLD]
        account: [$accountId]
      }
      sortOrder: DESC
    ) {
      edges {
        id
        node {
          id
          createdAt
          goodsPrice
          title
          productDescription
          status
          account {
            id
            currencyCode
          }
          supplier {
            id
            name
          }
          amazonData {
            amazonASIN
            externalId
            amazonConfirmed
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
