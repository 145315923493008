import React, { useContext } from "react";
import { useMutation } from "@apollo/react-hooks";
import AppContext from "../App/AppContext";
import {
  SetStripeOrderInProgressVariables as TSetInProgressVariables,
  SetStripeOrderInProgress as TSetInProgress
} from "./__generated__/SetStripeOrderInProgress";

import { NotificationTypes } from "../Notification/Notification";
import { Button } from "@material-ui/core";
import InProgressIcon from "@material-ui/icons/Pool";
import { ApolloError } from "apollo-client";

import setInProgressMutation from "./set-in-progress.graphql";

const StripeSetInProgressButton = ({ orderId }: { orderId: number }) => {
  const { handleShowNotification } = useContext(AppContext);
  const [mutation, { loading }] = useMutation<
    TSetInProgress,
    TSetInProgressVariables
  >(setInProgressMutation, {
    variables: { orderId },
    onError: (error: ApolloError) =>
      handleShowNotification({
        type: NotificationTypes.error,
        message: error.message
      })
  });

  return (
    <Button
      color="primary"
      disabled={loading}
      onClick={() => mutation()}
      size="large"
    >
      <InProgressIcon /> Set in progress
    </Button>
  );
};

export default StripeSetInProgressButton;
