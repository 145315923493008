import React, { Fragment, ReactElement, useState, ChangeEvent } from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  Typography,
  Fab,
  Tab,
  Tabs,
  Box,
  Grid
} from "@material-ui/core";

import { useQuery } from "@apollo/react-hooks";

import Error from "../Error";
import Loading from "../Loading";
import PageMeta from "../PageMeta";
import AccountOrders from "../AccountOrders";
import UpdateAccount from "../UpdateAccount";

import AccountBalance from "./components/AccountBalance";
import AmazonBusinessAccounts from "./components/AmazonBusinessAccounts";

import {
  Account as TAccount,
  AccountVariables as TAccountVariables
} from "./__generated__/Account";
import { useHistory, useParams } from "react-router";
import { omit } from "lodash";
import ArchiveAccountButton from "./components/ArchiveAccountButton";
import accountByIdQuery from "./account.graphql";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainAction: {
      position: "fixed",
      right: theme.spacing(4),
      bottom: theme.spacing(4)
    }
  })
);

const Account = (): ReactElement => {
  const classes = useStyles();
  const history = useHistory();
  const { accountId } = useParams<{ accountId: string }>();
  const [activeTab, setActiveTab] = useState<number>(0);
  const { loading, error, data } = useQuery<TAccount, TAccountVariables>(
    accountByIdQuery,
    {
      variables: { accountId }
    }
  );

  const [open, setOpen] = useState<boolean>(false);

  const handleModalOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const handleSetActiveTab = (event: ChangeEvent<{}>, newValue: number) =>
    setActiveTab(newValue);

  const handleArchiveAccount = () => history.replace("/accounts/");

  if (loading) {
    return (
      <>
        <PageMeta title="Account" />
        <Loading />
      </>
    );
  }

  if (error) {
    return (
      <>
        <PageMeta title="Account - Error" />
        <Error message={error.message} />
      </>
    );
  }

  if (data) {
    const {
      account: {
        balance,
        currencyCode,
        name,
        amazonBusinessAccounts,
        archived,
        isStripe
      }
    } = data;

    return (
      <Fragment>
        <PageMeta title={`Account - ${name}`} />
        <Box mb={4}>
          <Grid
            container
            justifyContent="space-between"
            spacing={2}
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h4">
                {name} - ({currencyCode})
              </Typography>
            </Grid>
            <Grid item>
              <AccountBalance
                accountId={accountId}
                balance={balance}
                currencyCode={currencyCode}
                showBalanceAdjustmentControls={!isStripe}
              />
            </Grid>
          </Grid>
        </Box>
        {!archived ? (
          <Box mt={-2} mb={4}>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <ArchiveAccountButton
                  accountId={accountId}
                  onConfirm={handleArchiveAccount}
                />
              </Grid>
            </Grid>
          </Box>
        ) : null}
        <Box mb={4}>
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            value={activeTab}
            onChange={handleSetActiveTab}
          >
            <Tab label="Orders" />
            <Tab label="Amazon Accounts" />
          </Tabs>
        </Box>
        <Typography component="div" role="tabpanel" hidden={activeTab !== 0}>
          <AccountOrders accountId={accountId} currency={currencyCode} />
        </Typography>
        <Typography component="div" role="tabpanel" hidden={activeTab !== 1}>
          <AmazonBusinessAccounts
            accountId={accountId}
            amazonBusinessAccounts={amazonBusinessAccounts?.map(account =>
              omit(account, "__typename")
            )}
          />
        </Typography>
        <div className={classes.mainAction}>
          <Fab color="primary" variant="extended" onClick={handleModalOpen}>
            Update Account
          </Fab>
        </div>

        <UpdateAccount
          account={data.account}
          isOpen={open}
          handleModalClose={handleModalClose}
        />
      </Fragment>
    );
  }

  return <div />;
};

export default Account;
