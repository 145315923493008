import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import OrdersTable from "./OrdersTable";
import TransactionSummary from "./TransactionSummary";

import { FormattedRow } from "../Reconcile/types";

interface Props {
  isOpen?: boolean;
  row: FormattedRow;

  handleModalClose: () => void;
  onConfirm: (order: FormattedRow["order"]) => void;
}

const ReconcileSearchModal = ({
  handleModalClose,
  row,
  onConfirm,
  isOpen = true
}: Props) => {
  return (
    <Dialog
      open={isOpen}
      onClose={handleModalClose}
      maxWidth="md"
      fullWidth={true}
    >
      <DialogContent>
        <Box mb={5} mt={2}>
          <TransactionSummary transaction={row.transaction} />
        </Box>
        <Box mb={5}>
          <OrdersTable onConfirm={onConfirm} />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ReconcileSearchModal;
