import gql from "graphql-tag";

export default gql`
  mutation CreatePurchaseProcessStep($input: CreatePurchaseProcessStepInput!) {
    createPurchaseProcessStep(input: $input) {
      id
      title
      body
      createdAt
      updatedAt
    }
  }
`;
