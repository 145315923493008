import { useContext, Fragment } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";

import AppContext from "../App/AppContext";
import { Redirect } from "react-router-dom";
import { NotificationTypes } from "../Notification/Notification";
import { useMutation } from "@apollo/react-hooks";
import { ApolloError } from "apollo-client";
import { responseToUnion } from "../../util/apollo";

import {
  UpdateSupplierVariables as TUpdateSupplierVariables,
  UpdateSupplier as TUpdateSupplier
} from "./__generated__/UpdateSupplier";

import updateSupplierMutation from "./update-supplier.graphql";
import SupplierForm from "../SupplierForm/SupplierForm";
import { SupplierSchemaType } from "../SupplierForm/schema";

interface Props {
  supplier: any;
  isOpen: boolean;
  handleModalClose: () => void;
}

const UpdateSupplier = ({ supplier, isOpen, handleModalClose }: Props) => {
  const [mutation, { loading, data: updateSupplierMutationData }] = useMutation<
    TUpdateSupplier,
    TUpdateSupplierVariables
  >(updateSupplierMutation);

  const { handleShowNotification } = useContext(AppContext);

  const onSubmit = async (values: SupplierSchemaType) => {
    const {
      name,
      countryCode,
      link,
      learnerblySupplierId,
      webhook,
      isPayoutAllowed,
      isCardAllowed,
      isPayoutClientAllowed,
      vatRatePercent,
      preferredPaymentMethod
    } = values;

    try {
      const { data } = await mutation({
        variables: {
          id: supplier.id,
          input: {
            name,
            countryCode,
            link,
            learnerblySupplierId,
            webhook,
            preferredPaymentMethod,
            isPayoutAllowed,
            isCardAllowed,
            isPayoutClientAllowed,
            vatRatePercent
          }
        }
      });

      if (data) {
        const result = responseToUnion(data.updateSupplier);

        if (result.__typename === "Supplier") {
          handleModalClose();
          handleShowNotification({
            type: NotificationTypes.success,
            message: "Supplier Updated"
          });
        } else {
          handleShowNotification({
            type: NotificationTypes.error,
            message: result.message
          });
        }
      }
    } catch (error) {
      handleShowNotification({
        type: NotificationTypes.error,
        message:
          error instanceof ApolloError
            ? error.message
            : "Oh no, there was a problem updating the supplier."
      });
    }
  };

  return (
    <Fragment>
      <Dialog open={isOpen} onClose={handleModalClose}>
        <DialogTitle>Update Supplier</DialogTitle>
        <DialogContent>
          <Fragment>
            <SupplierForm initialValues={supplier} onSubmit={onSubmit} />
          </Fragment>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose} color="primary">
            Cancel
          </Button>
          <Button
            form="supplier-form"
            type="submit"
            color="primary"
            disabled={loading}
          >
            {loading ? <CircularProgress size={30} /> : "Update"}
          </Button>
        </DialogActions>
        {!loading && updateSupplierMutationData && (
          <Redirect to={`/suppliers/${supplier.id}`} />
        )}
      </Dialog>
    </Fragment>
  );
};

export default UpdateSupplier;
