import React, { ReactElement } from "react";
import {
  default as MaterialTextField,
  TextFieldProps
} from "@material-ui/core/TextField";
import { default as InputAdornment } from "@material-ui/core/InputAdornment";
import NumberFormat from "react-number-format";

interface CustomProps {
  adornment?: string;
  decimalScale?: number;
  thousandSeparator?: string | boolean;
  onChange?: (value: string, floatValue: number) => void;
  readOnly?: boolean;
}

const CustomNumberInput = ({
  inputRef,
  onChange,
  decimalScale,
  thousandSeparator,
  ...other
}: any) => (
  <NumberFormat
    {...other}
    getInputRef={inputRef}
    onValueChange={({ value, floatValue }) => onChange(value, floatValue)}
    fixedDecimalScale
    decimalScale={decimalScale}
    thousandSeparator={thousandSeparator}
  />
);

const NumberField = ({
  fullWidth = true,
  adornment,
  decimalScale = 2,
  thousandSeparator = ",",
  error = false,
  onChange,
  readOnly = false,
  ...props
}: Omit<TextFieldProps, "onChange"> & CustomProps): ReactElement => (
  <MaterialTextField
    error={error}
    fullWidth={fullWidth}
    onChange={onChange as any}
    {...props}
    InputProps={{
      inputRef: props.inputRef,
      inputComponent: CustomNumberInput,
      startAdornment: (
        <InputAdornment position="start">
          {adornment ? adornment : ""}
        </InputAdornment>
      ),
      inputProps: {
        decimalScale,
        thousandSeparator,
        readOnly
      }
    }}
  />
);

export default NumberField;
