import moment from "moment";
import classnames from "classnames";
import { Link } from "react-router-dom";

import TableRow from "@material-ui/core/TableRow";
import MaterialLink from "@material-ui/core/Link";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Check from "@material-ui/icons/Check";
import {
  makeStyles,
  createStyles,
  Grid,
  Select,
  withStyles,
  InputBase,
  FormControl,
  CircularProgress
} from "@material-ui/core";

import { Accounts_accounts as TAccount } from "./__generated__/Accounts";
import { FormattedRow } from "./types";
import DataCell from "../DataCell";
import { useState } from "react";

const useStyles = makeStyles(() =>
  createStyles({
    transactionHeader: {
      "&&": {
        backgroundColor: "#e5e5e5"
      }
    },
    transactionCell: {
      borderColor: "white",
      backgroundColor: "#e8e9ea",
      fontWeight: "bold"
    },
    transactionSourceName: {
      textTransform: "capitalize"
    },
    link: {
      color: "#4556b7"
    }
  })
);

const Date = ({ date }: { date?: string }) => {
  if (!date) return null;
  return <>{moment(date).format("DD, MMM YYYY, hh:mm")}</>;
};

const TableInput = withStyles(() =>
  createStyles({ input: { "&:focus": { background: "none" } } })
)(InputBase);

const ReconcileTopUpTableRow = ({
  row,
  updateRow,
  accounts
}: {
  row: FormattedRow;
  updateRow: (updatedRow: FormattedRow) => Promise<void>;
  accounts: TAccount[];
}) => {
  const classes = useStyles();

  const [accountId, setAccountId] = useState<string>(
    row.transaction.account?.id || ""
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setAccountId(event.target.value as string);
  };

  const handleSubmit = async (accountId: string) => {
    setIsLoading(true);
    const account = accounts.find(({ id }) => id === accountId);
    if (account) {
      row.transaction.account = account;
    }
    await updateRow(row);
    setIsLoading(false);
  };

  return (
    <>
      <TableRow key={row.id}>
        <DataCell
          className={classnames(
            classes.transactionCell,
            classes.transactionHeader
          )}
        >
          {row.transaction.currency}
        </DataCell>
        <DataCell
          className={classnames(
            classes.transactionCell,
            classes.transactionHeader
          )}
        >
          <Date date={row.transaction.createdAt} />
        </DataCell>
        <DataCell className={classes.transactionCell}>
          {row.transaction.amount.toFixed(2)}
        </DataCell>
        <DataCell className={classes.transactionCell}>
          {row.transaction.statementDescriptor}
        </DataCell>
        <DataCell width="40%">
          <FormControl fullWidth>
            <Select
              value={accountId}
              displayEmpty
              onChange={handleChange}
              input={<TableInput placeholder="Select" />}
            >
              <MenuItem value="">Select</MenuItem>
              {accounts
                .filter(
                  ({ currencyCode }) =>
                    currencyCode === row.transaction.currency
                )
                .map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </DataCell>
        <DataCell width="10%">
          {accountId ? (
            <MaterialLink
              className={classes.link}
              underline="none"
              color="inherit"
              component={Link}
              to={`/accounts/${accountId}/`}
            >
              View Account
            </MaterialLink>
          ) : null}
        </DataCell>
        <DataCell>
          <Grid container direction="row" wrap="nowrap">
            <IconButton
              disabled={!accountId || isLoading}
              onClick={() => handleSubmit(accountId)}
              aria-label="confirm"
            >
              {isLoading && accountId ? (
                <CircularProgress size={20} />
              ) : (
                <Check />
              )}
            </IconButton>
          </Grid>
        </DataCell>
      </TableRow>
    </>
  );
};
export default ReconcileTopUpTableRow;
