import gql from "graphql-tag";

export default gql`
  mutation SetStripeInternalNote($orderId: Int!, $internalNote: String) {
    setStripeInternalNote(orderId: $orderId, internalNote: $internalNote) {
      id
      internalNote
    }
  }
`;
