import Auth from "@aws-amplify/auth";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import ApolloClient from "apollo-client";
import { IntrospectionFragmentMatcher } from "apollo-cache-inmemory";
import introspectionQueryResultData from "../config/fragmentTypes.json";

export function createApolloClient(uri: string) {
  const httpLink = createHttpLink({ uri });

  const authMiddleware = setContext(async (_, { headers }) => {
    try {
      const session = await Auth.currentSession();
      const token = session.getAccessToken();
      return {
        headers: {
          ...headers,
          authorization: token.getJwtToken()
        }
      };
    } catch (error) {
      alert(error);
      window.location.reload();
    }
  });

  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData
  });

  const apolloClient = new ApolloClient({
    link: authMiddleware.concat(httpLink),
    cache: new InMemoryCache({ fragmentMatcher })
  });

  return apolloClient;
}

export const responseToUnion = <MutationResponse extends { [k: string]: any }>(
  mutationResponse: MutationResponse
): NonNullable<
  Exclude<
    MutationResponse[keyof MutationResponse],
    MutationResponse["__typename"]
  >
> => {
  const key = Object.keys(mutationResponse).filter(
    k => k !== "__typename" && mutationResponse[k] != null
  )[0];

  const firstNonNull = mutationResponse[key];

  if (firstNonNull != null) return firstNonNull;

  // Necessary until GraphQL supports oneof fields, see:
  // https://github.com/graphql/graphql-spec/pull/825
  throw new Error(
    `Expected at least one value of mutation response to be not null`
  );
};
