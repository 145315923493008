import React from "react";

import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";

import { Snackbar, IconButton, SnackbarContent } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { green } from "@material-ui/core/colors";
import { ShowNotification } from "../App/AppContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    success: {
      backgroundColor: green[600]
    },
    error: {
      backgroundColor: theme.palette.error.dark
    },
    info: {
      backgroundColor: theme.palette.info.dark
    }
  })
);

export enum NotificationTypes {
  success = "success",
  error = "error",
  info = "info"
}

export type ShowNotificationState = {
  isVisible: boolean;
} & ShowNotification;

const Notification = ({
  handleNotificationClose,
  isVisible,
  type,
  message
}: {
  handleNotificationClose: () => void;
} & ShowNotificationState) => {
  const classes = useStyles();

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      open={isVisible}
      autoHideDuration={6000}
      onClose={handleNotificationClose}
    >
      <SnackbarContent
        message={message}
        className={classes[type]}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleNotificationClose}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
    </Snackbar>
  );
};

export default Notification;
