import React from "react";
import ReactDOM from "react-dom";
import Auth from "@aws-amplify/auth";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import Entry from "./components/Entry";
import "./index.css";
import Login from "./components/Login/Login";
import SSOCallback from "./components/SSOCallback";
import { envVars } from "./config/env-vars";
import { authInit } from "./util/aws";
import { registerLocale } from "@learnerbly/i18n-iso-countries";

registerLocale(require("@learnerbly/i18n-iso-countries/langs/en.json"));

if (envVars.sentryDsn != null) {
  Sentry.init({
    dsn: envVars.sentryDsn,
    environment: envVars.stage,
    integrations: [new BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0
  });
}

authInit(envVars);

// temp until we have routing
if (window.location.pathname === "/sso/") {
  ReactDOM.render(<SSOCallback />, document.getElementById("root"));
} else {
  Auth.currentSession()
    .then(() => ReactDOM.render(<Entry />, document.getElementById("root")))
    .catch(error => {
      if (error === "No current user") {
        ReactDOM.render(<Login />, document.getElementById("root"));
      }
    });
}
