import Badge from "@material-ui/core/Badge";
import Button, { ButtonProps } from "@material-ui/core/Button";

interface Props {
  reconcileCount: number;
  buttonProps?: ButtonProps;
}
const ReconcileButton = ({ reconcileCount, buttonProps }: Props) => {
  return (
    <Badge badgeContent={reconcileCount} color="secondary">
      <Button variant="contained" color="primary" {...buttonProps}>
        Reconcile
      </Button>
    </Badge>
  );
};

export default ReconcileButton;
