import React, { useContext, Fragment, ReactElement, useState } from "react";
import { Box } from "@material-ui/core";
import Joi from "joi";

import { useParams } from "react-router";

import { useMutation } from "@apollo/react-hooks";

import { NotificationTypes } from "../Notification/Notification";
import AppContext from "../App/AppContext";
import EditableTable from "../EditableTable";

import {
  UpdatePartnerAccount as TUpdatePartnerAccount,
  UpdatePartnerAccountVariables as TUpdatePartnerAccountVariables
} from "../_shared/__generated__/UpdatePartnerAccount";
import { PartnerAccount_partnerAccount_contacts } from "../PartnerAccount/__generated__/PartnerAccount";

import updatePartnerAccountMutation from "../_shared/update-partner-account.graphql";

type Props = {
  contacts: PartnerAccount_partnerAccount_contacts[] | null;
};

interface Row {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  note: string;
}

interface TableState {
  columns: any;
  data: Row[];
}

const validateRow = (row: Row) => {
  const schema = Joi.object({
    firstName: Joi.string().required(),
    lastName: Joi.string().required(),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required(),
    phoneNumber: Joi.string().regex(/^\d+$/).allow(null),
    note: Joi.string().allow(null)
  });

  return schema.validate(row, {
    abortEarly: false
  });
};

const PartnerContacts = ({ contacts }: Props): ReactElement => {
  const { handleShowNotification } = useContext(AppContext);

  const { partnerAccountId } = useParams<{
    partnerAccountId: string;
  }>();

  const [state, setState] = useState<TableState>({
    columns: [
      { title: "First Name", field: "firstName" },
      { title: "Last Name", field: "lastName" },
      { title: "Email", field: "email" },
      { title: "Phone", field: "phoneNumber" },
      { title: "Note", field: "note" }
    ],
    data:
      contacts?.map(contact => ({
        email: contact.email ?? "",
        firstName: contact.firstName ?? "",
        lastName: contact.lastName ?? "",
        note: contact.note ?? "",
        phoneNumber: contact.phoneNumber ?? ""
      })) || []
  });

  const [updatePartnerAccount] = useMutation<
    TUpdatePartnerAccount,
    TUpdatePartnerAccountVariables
  >(updatePartnerAccountMutation);

  const onRowAdd = async (newData: Row) => {
    const { error, value } = validateRow(newData);

    if (error) {
      const errors = error.details.map(({ message }) => message);

      handleShowNotification({
        type: NotificationTypes.error,
        message: `Could not add row because ${errors}`
      });

      return;
    }

    const updatedContacts = [...state.data, value];

    await updatePartnerAccount({
      variables: {
        id: partnerAccountId,
        input: {
          contacts: JSON.stringify(updatedContacts)
        }
      }
    });

    return setState(prevState => ({ ...prevState, data: updatedContacts }));
  };

  const onRowUpdate = async (newData: any, oldData: any) => {
    if (oldData) {
      const { error, value } = validateRow(newData);

      if (error) {
        const errors = error.details.map(({ message }) => message);

        handleShowNotification({
          type: NotificationTypes.error,
          message: `Could not update row because ${errors}`
        });

        return;
      }

      const updatedContacts = [...state.data];

      updatedContacts[updatedContacts.indexOf(oldData)] = value;

      await updatePartnerAccount({
        variables: {
          id: partnerAccountId,
          input: {
            contacts: JSON.stringify(updatedContacts)
          }
        }
      });

      return setState(prevState => ({ ...prevState, data: updatedContacts }));
    }
  };

  const onRowDelete = async (oldData: any) => {
    const updatedContacts = [...state.data];

    updatedContacts.splice(updatedContacts.indexOf(oldData), 1);

    await updatePartnerAccount({
      variables: {
        id: partnerAccountId,
        input: {
          contacts: JSON.stringify(updatedContacts)
        }
      }
    });

    return setState(prevState => ({ ...prevState, data: updatedContacts }));
  };

  return (
    <Fragment>
      <Box mb={2}>
        <EditableTable
          title="Contacts"
          tableState={state}
          onRowAdd={onRowAdd}
          onRowUpdate={onRowUpdate}
          onRowDelete={onRowDelete}
        />
      </Box>
    </Fragment>
  );
};

export default PartnerContacts;
