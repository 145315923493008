import gql from "graphql-tag";

export default gql`
  query OrdersByAccount($accountId: ID!, $after: String) {
    orders(
      after: $after
      first: 20
      filters: { account: [$accountId], status: [PAID] }
    ) {
      edges {
        id
        cursor
        node {
          id
          createdAt
          approvedAt
          purchasedAt
          paidAt
          title
          vatAmount
          resourceType
          netPrice
          grossPrice
          isAdhoc
          shippingDetails {
            recepientName
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`;
