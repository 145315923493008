import { useContext, useState } from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";

import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import MaterialLink from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles } from "@material-ui/core";
import { useMutation } from "@apollo/react-hooks";

import Actions from "./Actions";
import MatchState from "./MatchState";
import { FormattedRow, MatchStatusType } from "./types";
import moment from "moment";
import OrderIdSearchModal from "./OrderIdSearchModal";
import ReconcileSearchModal from "../ReconcileSearchModal";
import DataCell from "../DataCell";
import { isNil } from "lodash";
import {
  LinkStripeOrderToTransactionVariables as TLinkOrderToTransactionVariables,
  LinkStripeOrderToTransaction as TLinkOrderToTransaction
} from "./__generated__/LinkStripeOrderToTransaction";
import { NotificationTypes } from "../Notification/Notification";
import AppContext from "../App/AppContext";
import { ApolloError } from "apollo-client";

import confirmTransactionMutation from "./confirm-transaction.graphql";

const useStyles = makeStyles(() =>
  createStyles({
    transactionDate: {
      "&&": {
        backgroundColor: "#e5e5e5"
      }
    },
    transactionCell: {
      borderColor: "white",
      backgroundColor: "#e8e9ea",
      fontWeight: "bold"
    },
    link: {
      color: "#4556b7"
    }
  })
);

const Date = ({ date }: { date?: string }) => {
  if (!date) return null;
  return <>{moment(date).format("DD, MMM YYYY, hh:mm")}</>;
};

const calculateNetPrice = (grossPrice?: number | null, vat?: number | null) => {
  if (isNil(grossPrice) || isNil(vat)) return undefined;
  return grossPrice - vat;
};

const ReconcileTableRow = ({
  row,
  updateRow,
  onSelectChanged,
  isSelected
}: {
  row: FormattedRow;
  updateRow: (updatedRow: FormattedRow) => void;
  onSelectChanged: (isSelected: boolean) => void;
  isSelected: boolean;
}) => {
  const { handleShowNotification } = useContext(AppContext);

  const onLinkTransactionError = (error: ApolloError) => {
    handleShowNotification({
      type: NotificationTypes.error,
      message: error.message
    });
  };

  const onLinkTransactionComplete = (data: TLinkOrderToTransaction) => {
    if (!data) return;

    if (data?.linkStripeOrderToTransaction.__typename === "SemanticError") {
      handleShowNotification({
        type: NotificationTypes.error,
        message: data.linkStripeOrderToTransaction.message
      });
    } else {
      updateRow({
        ...row,
        matchStatus: { type: MatchStatusType.reconciled }
      });
    }
  };

  const [isEditModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [isSearchModalOpen, setSearchModalOpen] = useState<boolean>(false);
  const [mutation, { loading }] = useMutation<
    TLinkOrderToTransaction,
    TLinkOrderToTransactionVariables
  >(confirmTransactionMutation, {
    onError: onLinkTransactionError,
    onCompleted: onLinkTransactionComplete
  });

  const classes = useStyles();

  const openEditModal = () => {
    setEditModalOpen(true);
  };

  const closeEditModal = () => {
    setEditModalOpen(false);
  };

  const onConfirmClick = () => {
    if (row.order?.id == null) return false;

    mutation({
      variables: {
        transactionId: row.transaction.id,
        orderId: row.order?.id
      }
    });
  };

  return (
    <>
      <OrderIdSearchModal
        isOpen={isEditModalOpen}
        handleModalClose={closeEditModal}
        onConfirm={async order => {
          updateRow({
            ...row,
            matchStatus: {
              type: MatchStatusType.manual
            },
            order
          });
          setEditModalOpen(false);
        }}
      />
      <ReconcileSearchModal
        row={row}
        isOpen={isSearchModalOpen}
        handleModalClose={() => setSearchModalOpen(false)}
        onConfirm={order => {
          updateRow({
            ...row,
            matchStatus: {
              type: MatchStatusType.manual
            },
            order
          });
          setSearchModalOpen(false);
        }}
      />
      <TableRow key={row.id}>
        <DataCell
          className={classnames(
            classes.transactionCell,
            classes.transactionDate
          )}
        >
          <Date date={row.transaction.createdAt} />
        </DataCell>
        <DataCell className={classes.transactionCell}>
          <Typography variant="inherit" component="div">
            {row.transaction.amount.toFixed(2)} {row.transaction.currency}
          </Typography>
          {row.transaction.merchantAmount &&
            row.transaction.merchantCurrency && (
              <Typography variant="caption" color="textSecondary">
                ({row.transaction.merchantAmount.toFixed(2)}{" "}
                {row.transaction.merchantCurrency})
              </Typography>
            )}
        </DataCell>
        <DataCell className={classes.transactionCell}>
          {row.transaction.statementDescriptor}
        </DataCell>
        <DataCell padding="checkbox">
          <MatchState matchType={row.matchStatus.type} />
        </DataCell>
        <DataCell padding="checkbox">
          <Checkbox
            color="primary"
            role="checkbox"
            checked={isSelected}
            onChange={(e, checked) => onSelectChanged(checked)}
          />
        </DataCell>
        <DataCell>
          <Actions
            onEditClicked={openEditModal}
            onSearchClicked={() => setSearchModalOpen(true)}
            onConfirm={onConfirmClick}
            onCancel={() => {
              updateRow({
                ...row,
                order: undefined,
                matchStatus: { type: MatchStatusType.unreconciled }
              });
            }}
            value={row.matchStatus}
            disabled={loading}
          />
        </DataCell>
        <DataCell>
          <Date date={row.order?.createdAt} />
        </DataCell>
        <DataCell>
          <Typography variant="inherit" component="div">
            {row.order?.markedPurchased?.checkoutGrossPrice.toFixed(2)}
          </Typography>
          {row.order?.requestCurrencyCode && row.order?.requestGrossPrice && (
            <Typography variant="caption" color="textSecondary">
              ({row.order.requestGrossPrice.toFixed(2)}{" "}
              {row.order.requestCurrencyCode})
            </Typography>
          )}
        </DataCell>
        <DataCell>{row.order?.supplier?.name}</DataCell>
        <DataCell>
          {calculateNetPrice(
            row.order?.markedPurchased?.checkoutGrossPrice,
            row.order?.vatAmount
          )?.toFixed(2)}
        </DataCell>
        <DataCell>{row.order?.vatAmount}</DataCell>
        <DataCell>{row.order?.title}</DataCell>
        <DataCell>
          {row.order?.id ? (
            <MaterialLink
              className={classes.link}
              underline="none"
              color="inherit"
              component={Link}
              to={`/orders-stripe/${row.order?.id}/`}
            >
              View Order
            </MaterialLink>
          ) : undefined}
        </DataCell>
      </TableRow>
    </>
  );
};
export default ReconcileTableRow;
