import gql from "graphql-tag";

export default gql`
  query Suppliers {
    suppliers {
      id
      name
      link
      countryCode
      isArchived
      partnerAccount {
        id
      }
    }
  }
`;
