import React, { useContext, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import AppContext from "../App/AppContext";
import {
  SetPaidVariables as TSetPaidVariables,
  SetPaid as TSetPaid
} from "./__generated__/SetPaid";

import { NotificationTypes } from "../Notification/Notification";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  DialogActions,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader
} from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";

import { addCurrency } from "../../util/text-display";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { handleSubmit } from "../../util/form-validation";

import OrderDetailForm from "../SetPurchasedButton/OrderDetailForm";
import { toFixedFloat } from "../SetPurchasedButton/utils";

import setPaidMutation from "./set-paid.graphql";
interface SetPaidProps {
  orderId: string;
  currencyCode: string;
  goodsPrice: GQL.Fixed;
  vatAmount?: GQL.Fixed;
  vatRatePercent?: GQL.Fixed | null;
  shippingFees: GQL.Fixed;
  commissionAmount: GQL.Fixed;
  commissionVatAmount: GQL.Fixed;
}

interface TFormState {
  goodsPrice: number;
  netPrice: number;
  shippingFees: number;
  vatAmount: number;
  vatRatePercent: number | null;
  commissionPrice: number;
  commissionPriceVAT: number;
  totalCommissionPrice: number;
}

const useStyles = makeStyles(() =>
  createStyles({
    dialog: {
      width: 800
    }
  })
);

const SetPaidButton = ({
  orderId,
  currencyCode,
  goodsPrice = 0,
  vatAmount = 0,
  shippingFees = 0,
  vatRatePercent = null,
  commissionAmount,
  commissionVatAmount
}: SetPaidProps) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [formState, setFormState] = useState<TFormState>({
    goodsPrice,
    shippingFees,
    vatAmount,
    vatRatePercent,
    netPrice: goodsPrice - vatAmount,
    commissionPrice: commissionAmount - commissionVatAmount,
    commissionPriceVAT: commissionVatAmount,
    totalCommissionPrice: commissionAmount
  });

  const [mutation, { loading }] = useMutation<TSetPaid, TSetPaidVariables>(
    setPaidMutation
  );

  const { handleShowNotification } = useContext(AppContext);

  return (
    <>
      <Button color="primary" onClick={() => setIsOpen(true)} size="large">
        <DoneIcon /> Mark as Paid
      </Button>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)} maxWidth="lg">
        <form
          onSubmit={handleSubmit<TSetPaidVariables>(() =>
            mutation({
              variables: {
                orderId,
                commissionAmount: formState.totalCommissionPrice,
                commissionVatAmount: formState.commissionPriceVAT,

                // When a order is marked as paid, it assumes the goodsPrice is excluding VAT
                goodsPrice: formState.netPrice,
                shippingFees: formState.shippingFees,
                vatAmount: toFixedFloat(
                  formState.goodsPrice - formState.netPrice
                ),
                vatRatePercent: formState.vatRatePercent
              }
            })
              .catch(({ message }: { message: string }) =>
                handleShowNotification({
                  type: NotificationTypes.error,
                  message
                })
              )
              .finally(() => setIsOpen(false))
          )}
        >
          <DialogTitle>Mark as paid</DialogTitle>
          <DialogContent className={classes.dialog}>
            <Box mb={2}>
              <Grid spacing={1} container>
                <Grid xs={4} item>
                  <List
                    disablePadding
                    subheader={
                      <ListSubheader disableGutters>
                        Prices when purchased (incl VAT)
                      </ListSubheader>
                    }
                    dense
                  >
                    <ListItem disableGutters>
                      <ListItemText
                        primary="Goods Price"
                        secondary={addCurrency(
                          formState.goodsPrice,
                          currencyCode
                        )}
                      />
                    </ListItem>
                    <ListItem disableGutters>
                      <ListItemText
                        primary="Shipping & Other Transaction Fees"
                        secondary={addCurrency(
                          formState.shippingFees ?? 0,
                          currencyCode
                        )}
                      />
                    </ListItem>
                  </List>
                </Grid>
                <Grid xs={8} item>
                  <OrderDetailForm
                    currencyCode={currencyCode}
                    formValues={formState}
                    updateValues={values =>
                      setFormState({
                        ...formState,
                        ...values
                      })
                    }
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button color="primary" type="submit" disabled={loading}>
              Mark as Paid
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default SetPaidButton;
