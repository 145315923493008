import React, { ReactElement } from "react";

import Error from "../Error";
import Loading from "../Loading";
import PageMeta from "../PageMeta";
import StripeOrderCard from "../StripeOrderCard";

import { useQuery } from "@apollo/react-hooks";
import { useParams } from "react-router";
import StripeTransactionTable from "./StripeTransactionTable";
import {
  StripeOrder as TOrder,
  StripeOrderVariables as TOrderVariables
} from "./__generated__/StripeOrder";

import ordersQuery from "./stripeOrder.graphql";

const StripeOrder = (): ReactElement => {
  const { orderId } = useParams<{
    orderId: string;
  }>();
  const { loading, error, data } = useQuery<TOrder, TOrderVariables>(
    ordersQuery,
    { variables: { orderId: +orderId } } // Convert a string to a number due to the useParams hook returns a string and the query needs a number
  );

  if (loading) {
    return (
      <>
        <PageMeta title="Stripe Order" />
        <Loading />
      </>
    );
  }

  if (error) {
    return (
      <>
        <PageMeta title="Stripe Order - Error" />
        <Error message={error.message} />
      </>
    );
  }

  if (data) {
    const { stripeOrder } = data;
    return (
      <>
        <PageMeta title={`Order ${stripeOrder.id}`} />
        <StripeOrderCard {...stripeOrder} />
        <StripeTransactionTable
          transactions={stripeOrder.transactions}
          orderId={stripeOrder.id}
        />
      </>
    );
  }

  return <></>;
};

export default StripeOrder;
