import gql from "graphql-tag";

export default gql`
  query PartnerAccount($partnerAccountId: ID!) {
    partnerAccount(id: $partnerAccountId) {
      id
      name
      commissionRates {
        type
        amount
        description
      }
      discounts {
        type
        amount
        description
      }
      contacts {
        firstName
        lastName
        email
        phoneNumber
        note
      }
      assets {
        type
        value
        description
      }
    }
  }
`;
