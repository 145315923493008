import React, { useContext, useState, Fragment } from "react";
import { useMutation } from "@apollo/react-hooks";
import AppContext from "../App/AppContext";
import {
  UpdateShippingAddressVariables as TUpdateShippingAddressVariables,
  UpdateShippingAddress as TUpdateShippingAddress
} from "./__generated__/UpdateShippingAddress";
import { Order_order_shippingDetails as ShippingAddress } from "../Order/__generated__/Order";

import { NotificationTypes } from "../Notification/Notification";
import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import TextField from "../TextField";

import { withFormState } from "../../util/form-validation";

import updateOrderAddressMutation from "./update-order-address.graphql";

interface TFormState {
  recepientName: string;
  city: string;
  state: string;
  country: string;
  postcode: string;
  recepientEmail: string;
  street: string;
}

const UpdatePriceButton = ({
  orderId,
  shippingAddress
}: {
  orderId: string;
  shippingAddress: ShippingAddress;
}) => {
  const [mutation, { loading }] = useMutation<
    TUpdateShippingAddress,
    TUpdateShippingAddressVariables
  >(updateOrderAddressMutation);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { handleShowNotification } = useContext(AppContext);

  const [formState, setFormState] = useState<TFormState>({
    recepientName: shippingAddress.recepientName || "",
    city: shippingAddress.city || "",
    state: shippingAddress.state || "",
    country: shippingAddress.country || "",
    postcode: shippingAddress.postcode || "",
    recepientEmail: shippingAddress.recepientEmail || "",
    street: shippingAddress.street || ""
  });

  const handleFieldChange = withFormState<TFormState>(formState, setFormState);

  return (
    <Fragment>
      <IconButton
        size="small"
        disabled={loading}
        onClick={() => setIsOpen(true)}
      >
        <EditIcon fontSize="small" />
      </IconButton>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Update the shipping address for this order below. We’ll use this
            updated address when processing as an automatic order e.g Amazon.
          </DialogContentText>
          <form noValidate autoComplete="off">
            <TextField
              name="recepientName"
              label="Recepient name"
              value={formState.recepientName}
              onChange={handleFieldChange<string>("recepientName")}
              required
            />
            <TextField
              name="recepientEmail"
              label="Recepient email"
              value={formState.recepientEmail}
              onChange={handleFieldChange<string>("recepientEmail")}
              required
            />
            <TextField
              name="street"
              label="Street"
              value={formState.street}
              onChange={handleFieldChange<string>("street")}
              required
            />
            <TextField
              name="city"
              label="City"
              value={formState.city}
              onChange={handleFieldChange<string>("city")}
              required
            />
            <TextField
              name="state"
              label="State"
              value={formState.state}
              onChange={handleFieldChange<string>("state")}
              required
            />
            <TextField
              name="postcode"
              label="Postcode"
              value={formState.postcode}
              onChange={handleFieldChange<string>("postcode")}
              required
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)} disabled={loading}>
            Cancel
          </Button>
          <Button
            onClick={() =>
              mutation({
                variables: {
                  orderId,
                  shippingDetails: JSON.stringify(formState)
                }
              })
                .catch(({ message }: { message: string }) =>
                  handleShowNotification({
                    type: NotificationTypes.error,
                    message
                  })
                )
                .finally(() => setIsOpen(false))
            }
            color="primary"
            autoFocus
            disabled={loading}
          >
            {loading ? "Updating..." : "Update address"}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default UpdatePriceButton;
