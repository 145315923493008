import Link from "@material-ui/core/Link";
import React from "react";

export const AMAZON_ORDER_PATH =
  "gp/your-account/order-details/ref=ppx_yo_dt_b_order_details_o00?ie=UTF8&orderID=";

const AmazonOrderLink = ({
  children,
  domain,
  amazonConfirmationId
}: {
  children?: React.ReactNode;
  domain: string;
  amazonConfirmationId: string;
}) => {
  const amazonResourceLink = `${domain}${AMAZON_ORDER_PATH}${amazonConfirmationId}`;

  return (
    <Link
      target="_blank"
      rel="noopener noreferrer"
      style={{ display: "flex", alignItems: "center" }}
      href={amazonResourceLink}
    >
      {children || amazonConfirmationId}
    </Link>
  );
};

export default AmazonOrderLink;
