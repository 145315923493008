import React, { useState } from "react";
import RichTextEditor from "react-rte";

type Props = {
  value: any;
  onChange: any;
};

const StepBodyEditor = ({ value, onChange: onChangeBody }: Props) => {
  const initialState = value
    ? RichTextEditor.createValueFromString(JSON.parse(value), "html")
    : RichTextEditor.createEmptyValue();

  const [richTextValue, setRichTextValue] = useState(initialState);

  const onChange = (value: any) => {
    setRichTextValue(value);

    if (onChangeBody) {
      onChangeBody(JSON.stringify(value.toString("html")));
    }
  };

  return <RichTextEditor value={richTextValue} onChange={onChange} />;
};

export default StepBodyEditor;
