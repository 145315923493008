import { StripeTransactionType } from "../../config/globalTypes";
import { Button, Link as MaterialLink } from "@material-ui/core";
import { MoneyOff } from "@material-ui/icons";

const baseStripeUrl = "https://dashboard.stripe.com";

interface Props {
  type: StripeTransactionType;
  transactionId: string;
  accountId: string;
}

// Examples:
// https://dashboard.stripe.com/acct_1IsokpQjqwmkmLtp/payouts/po_1MM8nZQjqwmkmLtpknce2djX
// https://dashboard.stripe.com/acct_1IsokpQjqwmkmLtp/issuing/transactions/ipi_1L5Q4wQjqwmkmLtpdhSjNQ22

const getStripeLink = (
  type: StripeTransactionType,
  accountId: string,
  transactionId: string
) => {
  switch (type) {
    case StripeTransactionType.PAYOUT:
      return `${baseStripeUrl}/${accountId}/payouts/${transactionId}`;
    case StripeTransactionType.PURCHASE:
      return `${baseStripeUrl}/${accountId}/issuing/transactions/${transactionId}`;
    default:
      return null;
  }
};

const StripeTransactionLink = ({ type, accountId, transactionId }: Props) => {
  return (
    <MaterialLink
      target="_blank"
      rel="noopener noreferrer"
      href={getStripeLink(type, accountId, transactionId) ?? ""}
    >
      <Button color="primary">
        <MoneyOff />
        View on Stripe
      </Button>
    </MaterialLink>
  );
};

export default StripeTransactionLink;
