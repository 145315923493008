import gql from "graphql-tag";

export default gql`
  mutation SetStripeAssignedTo($orderId: Int!, $userId: ID!) {
    setStripeAssignedTo(orderId: $orderId, userId: $userId) {
      id
      assignedTo {
        id
        firstName
        lastName
      }
    }
  }
`;
