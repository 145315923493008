import React, { useContext, useState } from "react";
import { useMutation } from "@apollo/react-hooks";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  TextField
} from "@material-ui/core";

import AppContext from "../App/AppContext";
import { NotificationTypes } from "../Notification/Notification";
import {
  SetRefundedVariables as TSetRefundedVariables,
  SetRefunded as TSetRefunded
} from "./__generated__/SetRefunded";
import RefundedIcon from "@material-ui/icons/SettingsBackupRestore";

import setRefundedMutation from "./set-refunded.graphql";

interface SetRefundedProps {
  orderId: string;
}

const SetRefundedButton = ({ orderId }: SetRefundedProps) => {
  const { handleShowNotification } = useContext(AppContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [refundReason, setRefundReason] = useState<string>("");

  const [mutation, { loading }] = useMutation<
    TSetRefunded,
    TSetRefundedVariables
  >(setRefundedMutation);

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setRefundReason(event.target.value);
  };

  return (
    <>
      <Button
        color="secondary"
        disabled={loading}
        onClick={() => setIsOpen(true)}
        size="large"
      >
        <RefundedIcon /> Refund Order
      </Button>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will cancel the request for the learner and refund the
            transaction. This action can't be reverted
          </DialogContentText>
          <TextField
            autoFocus={true}
            margin="dense"
            id="refundReason"
            label="Reason for refund"
            type="text"
            fullWidth={true}
            multiline={true}
            rows={3}
            value={refundReason}
            onChange={handleChange}
            inputProps={{ maxLength: 1000 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)}>Don't refund</Button>
          <Button
            color="secondary"
            disabled={loading}
            onClick={() =>
              mutation({
                variables: {
                  orderId,
                  refundReason: refundReason || null
                }
              })
                .catch(({ message }: { message: string }) =>
                  handleShowNotification({
                    type: NotificationTypes.error,
                    message
                  })
                )
                .finally(() => setIsOpen(false))
            }
            autoFocus
          >
            Yes, I'm sure
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SetRefundedButton;
