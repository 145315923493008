import gql from "graphql-tag";

export default gql`
  mutation SetStripeOrderInProgress($orderId: Int!) {
    setStripeOrderInProgress(orderId: $orderId) {
      id
      status
      assignedTo {
        id
        firstName
        lastName
      }
    }
  }
`;
