import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  Card,
  Typography,
  Box
} from "@material-ui/core";
import moment from "moment";
import DataCell from "../DataCell";
import { Order } from "./__generated__/Order";

const MissingRows = () => {
  return (
    <TableRow>
      <DataCell align="center" colSpan={6}>
        No transactions found
      </DataCell>
    </TableRow>
  );
};

interface Props {
  transactions: Order["order"]["transactions"];
  orderId: string;
}

const TransactionTable = ({ transactions, orderId }: Props) => {
  return (
    <Card>
      <Box margin={2}>
        <Typography variant="h4" gutterBottom>
          Transactions
        </Typography>
      </Box>

      <Table>
        <TableHead>
          <TableRow>
            <DataCell>ID</DataCell>
            <DataCell>Created</DataCell>
            <DataCell>Last Card Digits</DataCell>
            <DataCell>Type</DataCell>
            <DataCell>Amount</DataCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {!transactions || transactions.length === 0 ? (
            <MissingRows />
          ) : (
            transactions.map(transaction => {
              if (transaction) {
                return (
                  <TableRow key={transaction.id}>
                    <DataCell>{transaction.id}</DataCell>
                    <DataCell>
                      {moment(transaction.createdAt).format(
                        "DD, MMM YYYY, hh:mm"
                      )}
                    </DataCell>
                    <DataCell>{transaction.lastCardDigits}</DataCell>
                    <DataCell>{transaction.type}</DataCell>
                    <DataCell>{transaction.amount}</DataCell>
                  </TableRow>
                );
              }
              return null;
            })
          )}
        </TableBody>
      </Table>
    </Card>
  );
};

export default TransactionTable;
