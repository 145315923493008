import React, { useContext, useState, Fragment, ChangeEvent } from "react";
import { useMutation } from "@apollo/react-hooks";
import AppContext from "../App/AppContext";
import {
  SetWontPurchaseVariables as TSetWontPurchaseVariables,
  SetWontPurchase as TSetWontPurchase
} from "./__generated__/SetWontPurchase";

import { NotificationTypes } from "../Notification/Notification";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  TextField
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/CancelOutlined";
import { ApolloError } from "apollo-client";

import setWontPurchaseMutation from "./set-wont-purchase.graphql";
import { handleSubmit } from "../../util/form-validation";

const SetWontPurchaseButton = ({
  orderId,
  disabled
}: {
  orderId: number;
  disabled: boolean;
}) => {
  const { handleShowNotification } = useContext(AppContext);
  const [mutation, { loading }] = useMutation<
    TSetWontPurchase,
    TSetWontPurchaseVariables
  >(setWontPurchaseMutation, {
    onError: (error: ApolloError) =>
      handleShowNotification({
        type: NotificationTypes.error,
        message: error.message
      })
  });
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [reason, setReason] = useState<string>("");

  const handleChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setReason(e.target.value);
  };

  const onSubmit = handleSubmit(() =>
    mutation({
      variables: {
        orderId,
        reason
      }
    })
  );

  return (
    <Fragment>
      <Button
        color="secondary"
        disabled={loading || disabled}
        onClick={() => setIsOpen(true)}
        size="large"
      >
        <CancelIcon /> Won't Purchase
      </Button>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <form onSubmit={onSubmit}>
          <DialogTitle>Are you sure?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              This will cancel the learner's request and cannot be reverted
            </DialogContentText>
            <TextField
              autoFocus={true}
              margin="dense"
              id="reason"
              label="Reason for not purchasing"
              type="text"
              fullWidth={true}
              multiline={true}
              rows={3}
              value={reason}
              onChange={handleChange}
              inputProps={{ maxLength: 1000 }}
              required
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsOpen(false)}>Go back</Button>
            <Button
              disabled={loading}
              color="secondary"
              autoFocus
              type="submit"
            >
              Yes, I'm sure
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Fragment>
  );
};

export default SetWontPurchaseButton;
