import { TableCellProps, TableCell } from "@material-ui/core";

const DataCell = ({
  children,
  className,
  ...props
}: {
  children: React.ReactNode;
  className?: string;
} & TableCellProps) => {
  return (
    <TableCell className={className} {...props}>
      {children ?? "─"}
    </TableCell>
  );
};

export default DataCell;
