import Chip from "@material-ui/core/Chip";
import { StripeTransactionStatus } from "../../config/globalTypes";

type MapState = Record<
  StripeTransactionStatus,
  {
    label: string;
    textColor: string;
    backgroundColor: string;
  }
>;

export const ReconcileChip = ({
  status
}: {
  status: StripeTransactionStatus;
}) => {
  const mapStateToChip: MapState = {
    [StripeTransactionStatus.CANCELLED]: {
      label: "Payment cancelled",
      textColor: "#FFFFFF",
      backgroundColor: "#333333"
    },
    [StripeTransactionStatus.FAILED]: {
      label: "Payment failed",
      textColor: "#000000",
      backgroundColor: "#FF7B56"
    },
    [StripeTransactionStatus.IN_PROGRESS]: {
      label: "Payout in progress",
      textColor: "#000000",
      backgroundColor: "#EBB800"
    },
    [StripeTransactionStatus.SETTLED]: {
      label: "Reconciled",
      textColor: "#FFFFFF",
      backgroundColor: "#32B83F"
    }
  };

  return (
    <Chip
      label={mapStateToChip[status].label}
      size="small"
      style={{
        background: mapStateToChip[status].backgroundColor,
        color: mapStateToChip[status].textColor
      }}
    />
  );
};
