import gql from "graphql-tag";

export default gql`
  mutation SetInternalNote($orderId: ID!, $internalNote: String!) {
    setInternalNote(orderId: $orderId, internalNote: $internalNote) {
      id
      internalNote
    }
  }
`;
