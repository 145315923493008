import React, { ReactElement, useState, useMemo } from "react";
import { Suppliers as TSuppliers } from "./__generated__/Suppliers";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Loading from "../Loading";
import PageMeta from "../PageMeta";
import Fab from "@material-ui/core/Fab";
import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import {
  makeStyles,
  Theme,
  createStyles,
  Link as MaterialLink
} from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";
import Error from "../Error";

import suppliersQuery from "./suppliers.graphql";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: "relative"
    },
    mainAction: {
      position: "fixed",
      right: theme.spacing(4),
      bottom: theme.spacing(4)
    }
  })
);

export enum SupplierStatus {
  "active" = "active",
  "isArchived" = "archived"
}

const Suppliers = (): ReactElement => {
  const classes = useStyles();
  const { loading, error, data } = useQuery<TSuppliers>(suppliersQuery);
  const [selectedTab, setSelectedTab] = useState<SupplierStatus>(
    SupplierStatus.active
  );

  const suppliers = useMemo(
    () => ({
      [SupplierStatus.active]:
        data?.suppliers.filter(supplier => !supplier.isArchived) || [],
      [SupplierStatus.isArchived]:
        data?.suppliers.filter(supplier => supplier.isArchived) || []
    }),
    [data?.suppliers]
  );
  const handleChange = (event: React.ChangeEvent<{}>, tab: SupplierStatus) => {
    setSelectedTab(tab);
  };

  if (loading) {
    return (
      <>
        <PageMeta title="Suppliers" />
        <Loading />
      </>
    );
  }

  if (error) {
    return (
      <>
        <PageMeta title="Suppliers - Error" />
        <Error message={error.message} />
      </>
    );
  }

  const codeToFlag = (countryCode: string) => {
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const REGIONAL_INDICATOR_SYMBOL_LETTER_A = 0x1f1e6;
    return countryCode
      .split("")
      .map(letter => alphabet.indexOf(letter))
      .map(i => REGIONAL_INDICATOR_SYMBOL_LETTER_A + i)
      .map(codePoint => String.fromCodePoint(codePoint))
      .join("");
  };

  if (data) {
    return (
      <div className={classes.container}>
        <PageMeta title="Suppliers" />
        <Typography variant="h4" gutterBottom>
          Suppliers
        </Typography>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={selectedTab}
          onChange={handleChange}
        >
          <Tab label="Active" value="active" />
          <Tab label="Archived" value="archived" />
        </Tabs>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Partner Account</TableCell>
              <TableCell>External website</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {suppliers[selectedTab].map(
              ({ id, name, countryCode, link, partnerAccount }) => (
                <TableRow key={id}>
                  <TableCell component="th" scope="row">
                    <MaterialLink
                      color="inherit"
                      component={Link}
                      to={`/suppliers/${id}/`}
                    >
                      {name}
                    </MaterialLink>
                    <span title={countryCode}> {codeToFlag(countryCode)}</span>
                  </TableCell>
                  <TableCell>{partnerAccount && <span>Active</span>}</TableCell>
                  <TableCell>
                    <MaterialLink href={link}>{link}</MaterialLink>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
        <div className={classes.mainAction}>
          <Fab
            {...({ component: Link, to: `/suppliers/create/` } as any)}
            color="primary"
            variant="extended"
          >
            <AddIcon /> Create new Supplier
          </Fab>
        </div>
      </div>
    );
  }

  return <div />;
};

export default Suppliers;
