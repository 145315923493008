import { ReactElement, useMemo, useState } from "react";
import { AllAccounts as TAllAccounts } from "./__generated__/AllAccounts";

import { addCurrency } from "../../util/text-display";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Loading from "../Loading";
import MaterialLink from "@material-ui/core/Link";
import { Link } from "react-router-dom";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";
import Error from "../Error";
import PageMeta from "../PageMeta";
import ReconcileButton from "./components/ReconcileButton";

import allAccountsQuery from "./accounts.graphql";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: "relative"
    },
    mainAction: {
      position: "fixed",
      right: theme.spacing(4),
      bottom: theme.spacing(4)
    },
    actionRow: {
      "& > *": {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1)
      }
    }
  })
);

export enum AccountStatus {
  "active" = "active",
  "archived" = "archived"
}

const Accounts = (): ReactElement => {
  const classes = useStyles();
  const { loading, error, data } = useQuery<TAllAccounts>(allAccountsQuery, {
    fetchPolicy: "network-only"
  });
  const [selectedTab, setSelectedTab] = useState<AccountStatus>(
    AccountStatus.active
  );
  const accounts = useMemo(
    () => ({
      [AccountStatus.active]:
        data?.accounts.filter(account => !account.archived) || [],
      [AccountStatus.archived]:
        data?.accounts.filter(account => account.archived) || []
    }),
    [data?.accounts]
  );

  const handleChange = (event: React.ChangeEvent<{}>, tab: AccountStatus) => {
    setSelectedTab(tab);
  };

  if (loading) {
    return (
      <>
        <PageMeta title="Accounts" />
        <Loading />
      </>
    );
  }

  if (error) {
    return (
      <>
        <PageMeta title="Accounts - Error" />
        <Error message={error.message} />
      </>
    );
  }

  if (data) {
    return (
      <div className={classes.container}>
        <PageMeta title="Accounts" />
        <Grid container>
          <Box flexGrow={1}>
            <Typography variant="h4" gutterBottom>
              Accounts
            </Typography>
          </Box>
          <Box>
            <Typography variant="h4" gutterBottom>
              <Box className={classes.actionRow}>
                <Button
                  component={Link}
                  to="/accounts/create/"
                  variant="contained"
                  color="primary"
                >
                  Create new account
                </Button>

                <Button
                  component={Link}
                  to="/reconcile"
                  variant="contained"
                  color="primary"
                >
                  Reconcile many
                </Button>
                <Button
                  component={Link}
                  to="/reconcile-topup"
                  variant="contained"
                  color="primary"
                >
                  Reconcile Topups
                </Button>
              </Box>
            </Typography>
          </Box>
        </Grid>

        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={selectedTab}
          onChange={handleChange}
        >
          <Tab label="Active" value="active" />
          <Tab label="Archived" value="archived" />
        </Tabs>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Balance</TableCell>
              <TableCell>Unreconciled Transactions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {accounts[selectedTab].map(row => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  <MaterialLink
                    color="inherit"
                    component={Link}
                    to={`/accounts/${row.id}/`}
                  >
                    {row.name} ({row.currencyCode})
                  </MaterialLink>
                </TableCell>
                <TableCell>
                  {addCurrency(row.balance, row.currencyCode)}
                </TableCell>
                <TableCell>
                  {row.stripeTransactions.totalCount > 0 ? (
                    <MaterialLink
                      color="inherit"
                      component={Link}
                      to={`/reconcile?accountId=${row.id}`}
                    >
                      <ReconcileButton
                        reconcileCount={row.stripeTransactions.totalCount}
                      />
                    </MaterialLink>
                  ) : (
                    "-"
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  }

  return <div />;
};

export default Accounts;
