import React, { useContext, Fragment, ReactElement, useState } from "react";
import { Box } from "@material-ui/core";
import { groupBy } from "lodash";
import Joi from "joi";

import { useParams } from "react-router";

import { useMutation } from "@apollo/react-hooks";

import { NotificationTypes } from "../Notification/Notification";
import AppContext from "../App/AppContext";
import EditableTable from "../EditableTable";

import {
  UpdatePartnerAccount as TUpdatePartnerAccount,
  UpdatePartnerAccountVariables as TUpdatePartnerAccountVariables
} from "../_shared/__generated__/UpdatePartnerAccount";
import {
  PartnerAccount_partnerAccount_commissionRates,
  PartnerAccount_partnerAccount_discounts
} from "../PartnerAccount/__generated__/PartnerAccount";
import { RateType } from "../../config/globalTypes";

import updatePartnerAccountMutation from "../_shared/update-partner-account.graphql";

type Props = {
  discounts: PartnerAccount_partnerAccount_discounts[] | null;
  commissionRates: PartnerAccount_partnerAccount_commissionRates[] | null;
};

interface Row {
  type: RateType;
  amount: number;
  description: string;
}

interface TableState {
  columns: any;
  data: Row[];
}

const formatRatesInput = (updatedRates: Row[]) => {
  const formattedRowAmount = updatedRates.map(({ amount, ...row }) => ({
    amount: amount * 100,
    ...row
  }));

  const { discounts, commissionRates } = groupBy(formattedRowAmount, "type");

  return {
    discounts: discounts && discounts.length ? JSON.stringify(discounts) : null,
    commissionRates:
      commissionRates && commissionRates.length
        ? JSON.stringify(commissionRates)
        : null
  };
};

const validateRow = (row: Row) => {
  const schema = Joi.object({
    type: Joi.string().required(),
    amount: Joi.number().positive().required(),
    description: Joi.string().required()
  });

  return schema.validate(row, {
    abortEarly: false
  });
};

const PartnerRates = ({ commissionRates, discounts }: Props): ReactElement => {
  const { handleShowNotification } = useContext(AppContext);

  const { partnerAccountId } = useParams<{
    partnerAccountId: string;
  }>();

  const data = [...(commissionRates || []), ...(discounts || [])];

  const [state, setState] = useState<TableState>({
    columns: [
      {
        title: "Type",
        field: "type",
        lookup: { discounts: "Discount", commissionRates: "Commission Rate" }
      },
      { title: "Amount %", field: "amount" },
      { title: "Description", field: "description" }
    ],
    data: data.map(item => ({
      amount: item.amount,
      description: item.description,
      type: item.type
    }))
  });

  const [updatePartnerAccount] = useMutation<
    TUpdatePartnerAccount,
    TUpdatePartnerAccountVariables
  >(updatePartnerAccountMutation);

  const onRowAdd = async (newData: Row) => {
    const { error, value } = validateRow(newData);

    if (error) {
      const errors = error.details.map(({ message }) => message);

      handleShowNotification({
        type: NotificationTypes.error,
        message: `Could not add row because ${errors}`
      });

      return;
    }

    const updatedRates = [...state.data, value];

    await updatePartnerAccount({
      variables: {
        id: partnerAccountId,
        input: formatRatesInput(updatedRates)
      }
    });

    return setState(prevState => ({ ...prevState, data: updatedRates }));
  };

  const onRowUpdate = async (newData: any, oldData: any) => {
    if (oldData) {
      const { error, value } = validateRow(newData);

      if (error) {
        const errors = error.details.map(({ message }) => message);

        handleShowNotification({
          type: NotificationTypes.error,
          message: `Could not update row because ${errors}`
        });

        return;
      }

      const updatedRates = [...state.data];

      updatedRates[updatedRates.indexOf(oldData)] = value;

      await updatePartnerAccount({
        variables: {
          id: partnerAccountId,
          input: formatRatesInput(updatedRates)
        }
      });

      return setState(prevState => ({ ...prevState, data: updatedRates }));
    }
  };

  const onRowDelete = async (oldData: any) => {
    const updatedRates = [...state.data];

    updatedRates.splice(updatedRates.indexOf(oldData), 1);

    await updatePartnerAccount({
      variables: {
        id: partnerAccountId,
        input: formatRatesInput(updatedRates)
      }
    });

    return setState(prevState => ({ ...prevState, data: updatedRates }));
  };

  return (
    <Fragment>
      <Box mb={2}>
        <EditableTable
          title="Rates / Discounts"
          tableState={state}
          onRowAdd={onRowAdd}
          onRowUpdate={onRowUpdate}
          onRowDelete={onRowDelete}
        />
      </Box>
    </Fragment>
  );
};

export default PartnerRates;
