import React, { useContext } from "react";
import { useMutation } from "@apollo/react-hooks";
import AppContext from "../App/AppContext";
import {
  SetOnHold as TSetOnHold,
  SetOnHoldVariables as TSetOnHoldVariables
} from "./__generated__/SetOnHold";

import { NotificationTypes } from "../Notification/Notification";
import { Button } from "@material-ui/core";
import BlockIcon from "@material-ui/icons/Block";

import setOnHoldMutation from "./set-on-hold.graphql";

const SetOnHoldButton = ({ orderId }: { orderId: string }) => {
  const [mutation, { loading }] = useMutation<TSetOnHold, TSetOnHoldVariables>(
    setOnHoldMutation
  );
  const { handleShowNotification } = useContext(AppContext);

  return (
    <Button
      color="primary"
      disabled={loading}
      onClick={() =>
        mutation({
          variables: {
            orderId
          }
        }).catch(({ message }: { message: string }) =>
          handleShowNotification({
            type: NotificationTypes.error,
            message
          })
        )
      }
      size="large"
    >
      <BlockIcon /> Set on hold
    </Button>
  );
};

export default SetOnHoldButton;
