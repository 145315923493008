import React, { ReactElement } from "react";
import InfiniteScroll from "react-infinite-scroller";

import moment from "moment";
import { useQuery } from "@apollo/react-hooks";
import { parse } from "json2csv";
import { Link } from "react-router-dom";

import {
  Box,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Link as MaterialLink
} from "@material-ui/core";

import Error from "../Error";
import Loading from "../Loading";

import {
  OrdersByAccount as TOrdersByAccount,
  OrdersByAccountVariables as TOrdersByAccountVariables
} from "./__generated__/OrdersByAccount";

import { addCurrency, getResourceTypeLabel } from "../../util/text-display";
import { Button, Grid } from "@material-ui/core";

import ordersByAccountQuery from "./orders.graphql";

interface Props {
  accountId: string;
  currency: string;
}

const AccountOrders = ({ accountId, currency }: Props): ReactElement => {
  const { loading, error, data, fetchMore } = useQuery<
    TOrdersByAccount,
    TOrdersByAccountVariables
  >(ordersByAccountQuery, {
    variables: { accountId }
  });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error message={error.message} />;
  }

  if (data) {
    const {
      orders: {
        edges,
        pageInfo: { hasNextPage, endCursor }
      }
    } = data;

    const csv = parse(
      edges.map(({ node }) => node),
      {
        fields: [
          { label: "Approved at", value: "approvedAt" },
          { label: "Purchased at", value: "purchasedAt" },
          { label: "Paid at", value: "paidAt" },
          { label: "Requester", value: "shippingDetails.recepientName" },
          { label: "Resource Title", value: "title" },
          { label: "Resource Type", value: "resourceType" },
          { label: "Net Price", value: "netPrice" },
          { label: "Gross Price", value: "grossPrice" },
          { label: "Total VAT", value: "vatAmount" },
          { label: "Adhoc", value: "isAdhoc" }
        ]
      }
    ).replace(/#/g, "");

    return (
      <>
        <Paper>
          <Box p={2}>
            <Grid
              container
              justifyContent="space-between"
              spacing={2}
              alignItems="center"
            >
              <Grid item>
                <Typography gutterBottom variant="h6">
                  Paid Orders
                </Typography>
              </Grid>
              <Grid item>
                <a href={`data:text/csv;charset=utf-8,${csv}`}>
                  <Button color="primary">Download CSV</Button>
                </a>
              </Grid>
            </Grid>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Approved at</TableCell>
                  <TableCell>Purchased at</TableCell>
                  <TableCell>Paid at</TableCell>
                  <TableCell>Requester</TableCell>
                  <TableCell>Resource Title</TableCell>
                  <TableCell>Resource Type</TableCell>
                  <TableCell>Net Price</TableCell>
                  <TableCell>Gross Price</TableCell>
                  <TableCell>Total VAT</TableCell>
                  <TableCell>Is Adhoc</TableCell>
                </TableRow>
              </TableHead>
              <InfiniteScroll
                element="tbody"
                hasMore={hasNextPage}
                loader={
                  <TableRow key="loading">
                    <TableCell component="th" scope="row" colSpan={4}>
                      <Loading key="loading" />
                    </TableCell>
                  </TableRow>
                }
                loadMore={() =>
                  fetchMore({
                    variables: {
                      after: endCursor
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                      if (!fetchMoreResult) {
                        return prev;
                      }
                      return {
                        orders: {
                          edges: [
                            ...prev.orders.edges,
                            ...fetchMoreResult.orders.edges
                          ],
                          pageInfo: {
                            ...prev.orders.pageInfo,
                            ...fetchMoreResult.orders.pageInfo
                          },
                          totalCount: prev.orders.totalCount,
                          __typename: prev.orders.__typename
                        }
                      };
                    }
                  })
                }
              >
                {edges.map(edge => {
                  const { node } = edge;
                  const {
                    id,
                    approvedAt,
                    purchasedAt,
                    paidAt,
                    title,
                    netPrice,
                    vatAmount,
                    grossPrice,
                    resourceType,
                    isAdhoc,
                    shippingDetails
                  } = node;

                  const { recepientName } = shippingDetails || {
                    recepientName: ""
                  };

                  return (
                    <TableRow key={id}>
                      <TableCell>
                        {moment(approvedAt).format("D MMM YYYY")}
                      </TableCell>
                      <TableCell>
                        {purchasedAt &&
                          moment(purchasedAt).format("D MMM YYYY")}
                      </TableCell>
                      <TableCell>
                        {paidAt && moment(paidAt).format("D MMM YYYY")}
                      </TableCell>
                      <TableCell>{recepientName}</TableCell>
                      <TableCell>
                        <MaterialLink
                          color="inherit"
                          component={Link}
                          to={`/orders/${id}/`}
                        >
                          {title}
                        </MaterialLink>
                      </TableCell>
                      <TableCell>
                        {resourceType && getResourceTypeLabel(resourceType)}
                      </TableCell>
                      <TableCell>
                        {netPrice && addCurrency(netPrice, currency)}
                      </TableCell>
                      <TableCell>
                        {grossPrice && addCurrency(grossPrice, currency)}
                      </TableCell>
                      <TableCell>
                        {vatAmount && addCurrency(vatAmount, currency)}
                      </TableCell>
                      <TableCell>{isAdhoc ? "Yes" : "No"}</TableCell>
                    </TableRow>
                  );
                })}
              </InfiniteScroll>
            </Table>
          </Box>
        </Paper>
      </>
    );
  }

  return <div />;
};

export default AccountOrders;
