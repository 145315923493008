import React, { useContext, Fragment, ReactElement, useState } from "react";
import { Box } from "@material-ui/core";
import Joi from "joi";

import { useParams } from "react-router";

import { useMutation } from "@apollo/react-hooks";

import { NotificationTypes } from "../Notification/Notification";
import AppContext from "../App/AppContext";
import EditableTable from "../EditableTable";

import {
  UpdatePartnerAccount as TUpdatePartnerAccount,
  UpdatePartnerAccountVariables as TUpdatePartnerAccountVariables
} from "../_shared/__generated__/UpdatePartnerAccount";
import { PartnerAccount_partnerAccount_assets } from "../PartnerAccount/__generated__/PartnerAccount";

import updatePartnerAccountMutation from "../_shared/update-partner-account.graphql";

interface Row {
  type: string;
  value: string;
  description: string;
}

interface TableState {
  columns: any;
  data: Row[];
}

type Props = {
  assets: PartnerAccount_partnerAccount_assets[] | null;
};

const validateRow = (row: Row) => {
  const schema = Joi.object({
    type: Joi.string().required(),
    value: Joi.string().required(),
    description: Joi.string().required()
  });

  return schema.validate(row, {
    abortEarly: false
  });
};

const PartnerAssets = ({ assets }: Props): ReactElement => {
  const { handleShowNotification } = useContext(AppContext);

  const { partnerAccountId } = useParams<{
    partnerAccountId: string;
  }>();

  const [state, setState] = useState<TableState>({
    columns: [
      {
        title: "Type",
        field: "type",
        lookup: { link: "Link", text: "Text" }
      },
      { title: "Description", field: "description" },
      { title: "Value (URL, Code etc)", field: "value" }
    ],
    data:
      assets?.map(asset => ({
        description: asset.description ?? "",
        type: asset.type,
        value: asset.value
      })) || []
  });

  const [updatePartnerAccount] = useMutation<
    TUpdatePartnerAccount,
    TUpdatePartnerAccountVariables
  >(updatePartnerAccountMutation);

  const onRowAdd = async (newData: Row) => {
    const { error, value } = validateRow(newData);

    if (error) {
      const errors = error.details.map(({ message }) => message);

      handleShowNotification({
        type: NotificationTypes.error,
        message: `Could not add row because ${errors}`
      });

      return;
    }

    const updatedAssets = [...state.data, value];

    await updatePartnerAccount({
      variables: {
        id: partnerAccountId,
        input: {
          assets: JSON.stringify(updatedAssets)
        }
      }
    });

    return setState(prevState => ({ ...prevState, data: updatedAssets }));
  };

  const onRowUpdate = async (newData: any, oldData: any) => {
    if (oldData) {
      const { error, value } = validateRow(newData);

      if (error) {
        const errors = error.details.map(({ message }) => message);

        handleShowNotification({
          type: NotificationTypes.error,
          message: `Could not update row because ${errors}`
        });

        return;
      }

      const updatedAssets = [...state.data];

      updatedAssets[updatedAssets.indexOf(oldData)] = value;

      await updatePartnerAccount({
        variables: {
          id: partnerAccountId,
          input: {
            assets: JSON.stringify(updatedAssets)
          }
        }
      });

      return setState(prevState => ({ ...prevState, data: updatedAssets }));
    }
  };

  const onRowDelete = async (oldData: any) => {
    const updatedAssets = [...state.data];

    updatedAssets.splice(updatedAssets.indexOf(oldData), 1);

    await updatePartnerAccount({
      variables: {
        id: partnerAccountId,
        input: {
          assets: JSON.stringify(updatedAssets)
        }
      }
    });

    return setState(prevState => ({ ...prevState, data: updatedAssets }));
  };

  return (
    <Fragment>
      <Box mb={2}>
        <EditableTable
          title="Assets"
          tableState={state}
          onRowAdd={onRowAdd}
          onRowUpdate={onRowUpdate}
          onRowDelete={onRowDelete}
        />
      </Box>
    </Fragment>
  );
};

export default PartnerAssets;
