import { FormLabel, Grid } from "@material-ui/core";
import { useFormik } from "formik";
import TextField from "../TextField";
import SelectField from "../SelectField";
import NumberField from "../NumberField";
import SwitchField from "../SwitchField";
import { SupplierSchemaType, SupplierSchema } from "./schema";
import { CountryCode, PreferredPaymentMethod } from "../../config/globalTypes";
import { getNames } from "@learnerbly/i18n-iso-countries";

const defaultValues: SupplierSchemaType = {
  name: "",
  vatRatePercent: null,
  link: "",
  countryCode: CountryCode.GB,
  webhook: null,
  learnerblySupplierId: "",
  isCardAllowed: true,
  isPayoutAllowed: false,
  isPayoutClientAllowed: false,
  preferredPaymentMethod: PreferredPaymentMethod.CARD
};

interface PaymentOption {
  key: PreferredPaymentMethod;
  label: string;
}

const paymentMethods: Array<PaymentOption> = [
  { key: PreferredPaymentMethod.CARD, label: "Card" },
  { key: PreferredPaymentMethod.PAYOUT, label: "Payout" },
  { key: PreferredPaymentMethod.PAYOUT_CLIENT, label: "Payout Client" }
];

interface CalculatedFields {
  preferredPaymentMethod?: PreferredPaymentMethod;
}

interface Props {
  onSubmit: (values: SupplierSchemaType & CalculatedFields) => void;
  initialValues?: SupplierSchemaType;
  isCreation?: boolean;
}

const SupplierForm = ({
  onSubmit,
  initialValues,
  isCreation = false
}: Props) => {
  const formik = useFormik({
    initialValues: initialValues || defaultValues,
    validationSchema: SupplierSchema,
    onSubmit: values => {
      const parsedValues = SupplierSchema.cast(values);

      // Creation only shows the a subset of fields, as the mutation does not support all fields
      if (isCreation) {
        onSubmit({
          name: parsedValues.name,
          vatRatePercent: parsedValues.vatRatePercent,
          learnerblySupplierId: parsedValues.learnerblySupplierId,
          link: parsedValues.link,
          countryCode: parsedValues.countryCode
        });
      } else {
        onSubmit(parsedValues);
      }
    }
  });

  const paymentMethodsObject = {
    paymentMethodsFlags: {
      [PreferredPaymentMethod.CARD]: formik.values.isCardAllowed,
      [PreferredPaymentMethod.PAYOUT]: formik.values.isPayoutAllowed,
      [PreferredPaymentMethod.PAYOUT_CLIENT]:
        formik.values.isPayoutClientAllowed
    },
    get options() {
      return paymentMethods.reduce(
        (acc: PaymentOption[], curr: PaymentOption) =>
          this.paymentMethodsFlags[curr.key] ? [...acc, curr] : acc,
        []
      );
    },
    get preferred() {
      return this.options.find(
        (paymentMethod: PaymentOption) =>
          paymentMethod.key === formik.values.preferredPaymentMethod
      )
        ? formik.values.preferredPaymentMethod
        : PreferredPaymentMethod.CARD;
    }
  };

  const countries = getNames("en");
  const countrySelectorItems = Object.keys(countries).map(country => ({
    key: country,
    label: countries[country]
  }));

  return (
    <form id="supplier-form" onSubmit={formik.handleSubmit}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            label="Name"
            error={!!formik.errors.name}
            helperText={formik.errors.name}
            required
            {...formik.getFieldProps("name")}
            // This is required because our underlying TextField onChange function uses a string, not an event
            onChange={value => formik.setFieldValue("name", value)}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Website"
            placeholder="https://foo.bar"
            helperText={formik.errors.link}
            error={!!formik.errors.link}
            {...formik.getFieldProps("link")}
            onChange={value => formik.setFieldValue("link", value)}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Learnerbly Supplier ID"
            error={!!formik.errors.learnerblySupplierId}
            helperText={formik.errors.learnerblySupplierId}
            {...formik.getFieldProps("learnerblySupplierId")}
            onChange={value =>
              formik.setFieldValue("learnerblySupplierId", value)
            }
          />
        </Grid>

        <Grid item xs={12}>
          <NumberField
            label="Vat Rate Percent %"
            error={!!formik.errors.vatRatePercent}
            helperText={formik.errors.vatRatePercent}
            {...formik.getFieldProps("vatRatePercent")}
            value={formik.values.vatRatePercent ?? ""} // VAT rate is nullable
            onChange={value => formik.setFieldValue("vatRatePercent", value)}
          />
        </Grid>

        <Grid item xs={12}>
          <SelectField
            label="Country"
            values={countrySelectorItems}
            {...formik.getFieldProps("countryCode")}
            onChange={(value: CountryCode) =>
              formik.setFieldValue("countryCode", value)
            }
          />
        </Grid>

        {!isCreation && (
          <>
            <Grid item xs={12}>
              <TextField
                label="Actions Webhook"
                error={!!formik.errors.webhook}
                helperText={formik.errors.webhook}
                {...formik.getFieldProps("webhook")}
                onChange={value => formik.setFieldValue("webhook", value)}
                value={formik.values.webhook ?? ""}
              />
            </Grid>

            <Grid item xs={12}>
              <FormLabel component="legend">
                Supported Payment methods
              </FormLabel>
              <SwitchField
                label="Payout"
                {...formik.getFieldProps("isPayoutAllowed")}
                checked={
                  paymentMethodsObject.paymentMethodsFlags[
                    PreferredPaymentMethod.PAYOUT
                  ]
                }
                onChange={value =>
                  formik.setFieldValue("isPayoutAllowed", value)
                }
              />

              <SwitchField
                label="Card"
                // currently disabling ability to un-able card as is currently available for all
                disabled={true}
                checked={true}
                {...formik.getFieldProps("isCardAllowed")}
                onChange={value => formik.setFieldValue("isCardAllowed", value)}
              />
              <SwitchField
                label="Payout Client"
                {...formik.getFieldProps("isPayoutClientAllowed")}
                checked={
                  paymentMethodsObject.paymentMethodsFlags[
                    PreferredPaymentMethod.PAYOUT_CLIENT
                  ]
                }
                onChange={value =>
                  formik.setFieldValue("isPayoutClientAllowed", value)
                }
              />
            </Grid>

            <Grid item xs={12}>
              <SelectField
                label="Default Payment Method"
                values={paymentMethodsObject.options}
                {...formik.getFieldProps("preferredPaymentMethod")}
                onChange={(value: PreferredPaymentMethod) =>
                  formik.setFieldValue("preferredPaymentMethod", value)
                }
              />
            </Grid>
          </>
        )}
      </Grid>
    </form>
  );
};

export default SupplierForm;
