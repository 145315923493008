import gql from "graphql-tag";

export default gql`
  query Account($accountId: ID!) {
    account(id: $accountId) {
      id
      balance
      currencyCode
      name
      revolutAccountId
      isAmazonFulfilmentAutomatic
      isPayByInvoiceActiveAt
      archived
      isStripe
      amazonBusinessAccounts {
        country
        username
        secret
        groupName
        purchaseUrl
        adminEmail
      }
    }
  }
`;
