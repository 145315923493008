import Select from "../SelectField";
import NumberField from "../NumberField";
import Loading from "../Loading";
import Error from "../Error";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useFormik } from "formik";

import { useMemo } from "react";
import { useQuery } from "@apollo/react-hooks";

import { FetchSuppliers } from "./__generated__/FetchSuppliers";
import { DialogContent, DialogActions, Button } from "@material-ui/core";

import supplierQuery from "./suppliers.graphql";
import { PayoutSchema, payoutSchema } from "./schema";
import { CurrencyCode } from "../../config/globalTypes";

const currencyOptions = [
  {
    key: "GBP",
    label: "Pound Sterling"
  },
  {
    key: "USD",
    label: "United States Dollar"
  },
  {
    key: "EUR",
    label: "Euro"
  }
];

interface Props {
  confirmPending: boolean;
  onConfirm: (values: PayoutSchema) => void;
  onCancel: () => void;
  initialValues?: {
    amount?: number;
    supplierId?: string;
    currencyCode?: string;
  };
}

const PayoutForm = ({
  onConfirm,
  onCancel,
  initialValues,
  confirmPending
}: Props) => {
  const onSubmit = async (values: PayoutSchema) => {
    onConfirm(values);
  };

  const formik = useFormik({
    initialValues: {
      amount: initialValues?.amount ?? 0,
      currency: initialValues?.currencyCode ?? "GBP",
      supplier: initialValues?.supplierId ?? ""
    },
    onSubmit: onSubmit,
    validationSchema: payoutSchema
  });

  const { data, loading, error } = useQuery<FetchSuppliers>(supplierQuery, {
    variables: { filters: { isArchived: false } }
  });

  const supplierOptions = useMemo(() => {
    return data?.suppliers.map(supplier => ({
      key: supplier.id,
      label: supplier.name
    }));
  }, [data?.suppliers]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    console.error(error);
    return <Error message={error.message} />;
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <DialogContent>
        <Select
          values={supplierOptions ?? []}
          label="Supplier"
          {...formik.getFieldProps("supplier")}
          onChange={(value: string) => formik.setFieldValue("supplier", value)}
        />

        <Select
          values={currencyOptions}
          label="Currency"
          {...formik.getFieldProps("currency")}
          onChange={(value: CurrencyCode) =>
            formik.setFieldValue("currency", value)
          }
        />

        <NumberField
          margin="normal"
          label="Gross Price (incl. VAT)"
          required
          {...formik.getFieldProps("amount")}
          helperText={formik.errors.amount}
          error={!!formik.errors.amount}
          onChange={(_: string, numVal: number) =>
            formik.setFieldValue("amount", numVal)
          }
        />
      </DialogContent>

      <DialogActions>
        <Button color="secondary" variant="contained" onClick={onCancel}>
          Cancel
        </Button>

        <Button
          color="primary"
          variant="contained"
          disabled={confirmPending}
          startIcon={
            confirmPending && <CircularProgress size="1rem" color="inherit" />
          }
          type="submit"
        >
          Confirm
        </Button>
      </DialogActions>
    </form>
  );
};

export default PayoutForm;
