import gql from "graphql-tag";

export default gql`
  mutation LinkAccountToTransaction($transactionId: Int!, $accountId: ID!) {
    linkAccountToStripeTransaction(
      transactionId: $transactionId
      accountId: $accountId
    ) {
      ... on LinkAccountToStripeTransactionOutput {
        transaction {
          id
          account {
            name
          }
          amount
        }
      }

      ... on SemanticError {
        message
      }
    }
  }
`;
