import React, { ReactElement, useState, Fragment } from "react";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import DeductIcon from "@material-ui/icons/Remove";

import { handleSubmit, withFormState } from "../../../../util/form-validation";
import { addCurrency, getCurrencySymbol } from "../../../../util/text-display";

import {
  CreateTransaction as TCreateTransaction,
  CreateTransactionVariables as TCreateTransactionVariables
} from "./__generated__/CreateTransaction";
import { useMutation } from "@apollo/react-hooks";
import TextField from "../../../TextField";
import NumberField from "../../../NumberField";
import { Link } from "react-router-dom";

import accountQuery from "../../account.graphql";
import createTransaction from "./create-transaction.graphql";
import getAccountTransactions from "./transactions-by-account.graphql";

enum AmendmentTypes {
  add = "Add",
  deduct = "Deduct"
}

interface CustomProps {
  accountId: string;
  balance: number;
  currencyCode: string;
  showBalanceAdjustmentControls: boolean;
}

interface DialogState {
  isOpen: boolean;
  amendmentType: AmendmentTypes;
}

interface TFormState {
  amount: GQL.Fixed;
  internalNote: string;
}

const AccountBalance = ({
  accountId,
  balance,
  currencyCode,
  showBalanceAdjustmentControls = true
}: CustomProps): ReactElement => {
  const [dialog, setIsDialogOpen] = useState<DialogState>({
    isOpen: false,
    amendmentType: AmendmentTypes.add
  });
  const { isOpen, amendmentType } = dialog;
  const [formState, setFormState] = useState<TFormState>({
    amount: 0,
    internalNote: ""
  });

  const handleFieldChange = withFormState<TFormState>(formState, setFormState);
  const [mutation] = useMutation<
    TCreateTransaction,
    TCreateTransactionVariables
  >(createTransaction);

  const handleDialog = (isOpen: boolean, amendmentType: AmendmentTypes): void =>
    setIsDialogOpen({ isOpen, amendmentType });

  const handleAmendBudget = () => {
    return mutation({
      variables: {
        input: {
          accountId,
          amount:
            amendmentType === "Deduct" ? -formState.amount : formState.amount,
          internalNote: formState.internalNote
        }
      },
      refetchQueries: [
        {
          query: accountQuery,
          variables: { accountId }
        },
        {
          query: getAccountTransactions,
          variables: { accountId }
        }
      ]
    }).then(() => handleDialog(false, AmendmentTypes.deduct));
  };

  return (
    <Fragment>
      <Grid container justifyContent="flex-end" spacing={2} alignItems="center">
        <Grid item>
          <Typography variant="subtitle1">
            Balance {addCurrency(balance, currencyCode)}
          </Typography>
        </Grid>

        <Grid item>
          <Button
            component={Link}
            to={`/open-amazon-orders/${accountId}/`}
            variant="outlined"
          >
            Open Amazon Orders
          </Button>
        </Grid>

        {showBalanceAdjustmentControls && (
          <>
            <Grid item>
              <Button
                onClick={() => handleDialog(true, AmendmentTypes.add)}
                variant="outlined"
              >
                <AddIcon /> Add funds
              </Button>
            </Grid>

            <Grid item>
              <Button
                onClick={() => handleDialog(true, AmendmentTypes.deduct)}
                variant="outlined"
              >
                <DeductIcon /> Deduct funds
              </Button>
            </Grid>
          </>
        )}
      </Grid>
      <Dialog
        open={isOpen}
        onClose={() => handleDialog(false, AmendmentTypes.add)}
      >
        <form onSubmit={handleSubmit(handleAmendBudget)}>
          <DialogTitle>{amendmentType} funds</DialogTitle>
          <DialogContent>
            <Box mb={2}>
              <NumberField
                name="amount"
                label={`${amendmentType} funds`}
                adornment={getCurrencySymbol(currencyCode)}
                value={formState.amount}
                onChange={handleFieldChange("amount")}
                margin="dense"
                required
              />
            </Box>
            <Box mb={2}>
              <TextField
                name="internalNote"
                label="Notes"
                value={formState.internalNote}
                onChange={handleFieldChange("internalNote")}
                margin="dense"
                required
                multiline
                rows="4"
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={() => handleDialog(false, AmendmentTypes.add)}
            >
              Cancel
            </Button>
            <Button color="primary" disabled={!formState.amount} type="submit">
              {amendmentType} funds
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Fragment>
  );
};

export default AccountBalance;
