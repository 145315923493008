import React, { useContext } from "react";
import { useMutation } from "@apollo/react-hooks";
import AppContext from "../App/AppContext";
import {
  SetStripeOnHoldVariables as TSetOnHoldVariables,
  SetStripeOnHold as TSetOnHold
} from "./__generated__/SetStripeOnHold";

import { NotificationTypes } from "../Notification/Notification";
import { Button } from "@material-ui/core";
import BlockIcon from "@material-ui/icons/Block";
import { ApolloError } from "apollo-client";

import setOnHoldMutation from "./set-on-hold.graphql";

const StripeSetOnHoldButton = ({ orderId }: { orderId: number }) => {
  const { handleShowNotification } = useContext(AppContext);
  const [mutation, { loading }] = useMutation<TSetOnHold, TSetOnHoldVariables>(
    setOnHoldMutation,
    {
      variables: { orderId },
      onError: (error: ApolloError) =>
        handleShowNotification({
          type: NotificationTypes.error,
          message: error.message
        })
    }
  );

  return (
    <Button
      color="primary"
      disabled={loading}
      onClick={() => mutation()}
      size="large"
    >
      <BlockIcon /> Set on hold
    </Button>
  );
};

export default StripeSetOnHoldButton;
