import gql from "graphql-tag";

export default gql`
  mutation UpdatePartnerAccount($id: ID!, $input: UpdatePartnerAccountInput!) {
    updatePartnerAccount(id: $id, input: $input) {
      id
      name
      commissionRates {
        type
        amount
        description
      }
      discounts {
        type
        amount
        description
      }
      contacts {
        firstName
        lastName
        email
        phoneNumber
        note
      }
    }
  }
`;
