import React, {
  Fragment,
  ReactElement,
  useMemo,
  useRef,
  useState
} from "react";
import moment from "moment";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import BrightnessAutoIcon from "@material-ui/icons/BrightnessAuto";
import CopyIcon from "@material-ui/icons/FileCopy";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import {
  PreferredPaymentMethod,
  StripeAmazonOrderStatus,
  StripeOrderStatus as TStatus,
  StripeTransactionType
} from "../../config/globalTypes";
import copy from "copy-to-clipboard";
import CopyKindleCardMenuItem from "../CopyKindleCardMenuItem";
import { addCurrency, getResourceTypeLabel } from "../../util/text-display";
import { StripeOrders_stripeOrders_edges_node } from "../StripeOrders/__generated__/StripeOrders";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import WarningIcon from "@material-ui/icons/Warning";
import {
  CardActions,
  CardContent,
  CardHeader,
  ClickAwayListener,
  Collapse,
  Divider,
  Grid,
  Grow,
  IconButton,
  Link as MaterialLink,
  MenuItem,
  MenuList,
  Paper,
  Popper
} from "@material-ui/core";

import StatusChip from "../StatusChip";
import { Link } from "react-router-dom";

import {
  DefaultComponentProps,
  OverridableTypeMap
} from "@material-ui/core/OverridableComponent";
import { PhoneNumberFormat, PhoneNumberUtil } from "google-libphonenumber";
import AmazonOrderLink from "./AmazonOrderLink";
import { createStyles, makeStyles } from "@material-ui/styles";
import StripeAssignToButton from "../StripeAssignToButton";
import StripeSetInProgressButton from "../StripeSetInProgressButton";
import StripeSetWontPurchaseButton from "../StripeSetWontPurchaseButton";
import StripeRectifyPriceButton from "../StripeRectifyPriceButton";
import StripeSetPurchasedButton from "../StripeSetPurchasedButton";
import StripeShippingDetails from "../StripeShippingDetails";
import StripeSetOnHoldButton from "../StripeSetOnHoldButton";
import StripeTriggerActionsButton from "../StripeTriggerActionsButton";
import PayoutButton from "../PayoutButton";
import StripeRectifyResourceTypeButton from "../StripeRectifyResourceTypeButton";
import StripeInternalNote from "../StripeInternalNote";
import StripeSetRefundedButton from "../StripeSetRefundedButton";
import { ReconcileChip } from "./ReconcileChip";

const phoneUtil = PhoneNumberUtil.getInstance();

const stripeChipStyles = makeStyles(() =>
  createStyles({
    button: {
      background:
        "linear-gradient(125deg, rgba(255,203,87,1) 0%, rgba(242,81,113,1) 65%, rgba(195,96,213,1) 100%)"
    }
  })
);

const CopyButton = (props: DefaultComponentProps<OverridableTypeMap>) => (
  <IconButton size="small" {...props}>
    <CopyIcon fontSize="small" />
  </IconButton>
);

const StripeChip = () => {
  const classes = stripeChipStyles();
  return <Chip color="secondary" label="Stripe" className={classes.button} />;
};

const CommissionableChip = () => (
  <Chip
    color="secondary"
    label="Commissionable"
    icon={<MonetizationOnIcon />}
  />
);

const FulfimentAutomaticChip = () => (
  <Chip
    color="secondary"
    label="Automatic"
    icon={<BrightnessAutoIcon />}
    style={{ background: "#742aea" }}
  />
);

const ReconciledChip = ({
  transactions
}: {
  transactions: StripeOrders_stripeOrders_edges_node["transactions"];
}) => {
  const [lastPurchaseOrPayoutTransaction] = transactions
    .filter(transaction =>
      [
        StripeTransactionType.PAYOUT,
        StripeTransactionType.PAYOUT_PARTNER,
        StripeTransactionType.PURCHASE
      ].includes(transaction.type)
    )
    .sort((a, b) => b.id - a.id);

  return lastPurchaseOrPayoutTransaction?.status ? (
    <Box mb={1}>
      <ReconcileChip status={lastPurchaseOrPayoutTransaction.status} />
    </Box>
  ) : null;
};

const ViewPurchaseProcessButton = ({
  supplierId
}: {
  supplierId: string | null;
}) => (
  <MaterialLink
    color="primary"
    href={`/suppliers/${supplierId}`}
    target="_blank"
    style={{
      display: "flex",
      textTransform: "uppercase",
      fontWeight: 500,
      fontSize: "0.9375rem"
    }}
  >
    <FormatListNumberedIcon fontSize="small" /> Purchase Process
  </MaterialLink>
);

const Order = ({
  id,
  status,
  refundedAt,
  refundedBy,
  requestedAt,
  additionalNote,
  approvedAt,
  markedPurchased,
  grossPriceAgreedByLearner,
  requestGrossPrice,
  requestCurrencyCode,
  purchaseLink,
  requestId,
  vatAmount,
  vatRatePercent,
  shippingDetails,
  resourceType,
  requestedBy,
  assignedTo,
  transactions,
  account: {
    id: accountId,
    name,
    currencyCode,
    balance,
    isStripe: isStripeAccount
  },
  isAmazonFulfilmentAutomatic,
  title,
  supplier,
  productEventDate,
  markedWontPurchase,
  amazonData,
  internalNote,
  productDescription
}: StripeOrders_stripeOrders_edges_node): ReactElement => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [open, setOpen] = useState(false);

  const anchorRef = useRef<HTMLButtonElement>(null);
  const payoutDialog = useRef<{ setPayoutModalOpen: (state: boolean) => void }>(
    null
  );
  const isKindleOrder = amazonData && amazonData.asin?.startsWith("B");
  const hasAmazonResourceUrl = amazonData?.confirmationId && supplier?.link;

  const StaticAmazonOrderLink = useMemo(
    () =>
      ({ children }: { children: React.ReactNode }) =>
        hasAmazonResourceUrl ? (
          <AmazonOrderLink
            domain={supplier?.link as string}
            confirmationId={amazonData?.confirmationId as string}
          >
            {children}
          </AmazonOrderLink>
        ) : null,
    [amazonData?.confirmationId, supplier?.link, hasAmazonResourceUrl]
  );

  const ViewAmazonButton = () => (
    <Button
      style={{
        display: "flex",
        textTransform: "uppercase",
        fontWeight: 500,
        fontSize: "0.9375rem"
      }}
    >
      <StaticAmazonOrderLink>
        <FormatListNumberedIcon fontSize="small" /> View on Amazon
      </StaticAmazonOrderLink>
    </Button>
  );

  const OriginalGrossPrice = () =>
    requestGrossPrice &&
    requestCurrencyCode &&
    requestCurrencyCode !== currencyCode ? (
      <small>[{addCurrency(requestGrossPrice, requestCurrencyCode)}]</small>
    ) : null;

  const SetRefundButton = ({ orderId }: { orderId: number }) =>
    !refundedAt &&
    transactions.some(transaction =>
      [StripeTransactionType.PURCHASE, StripeTransactionType.PAYOUT].includes(
        transaction.type
      )
    ) ? (
      <StripeSetRefundedButton orderId={orderId} />
    ) : null;

  const defaultEmptyShippingDetails = {
    city: "",
    country: "",
    diallingCode: "",
    postcode: "",
    recipientEmail: "",
    recipientName: "",
    state: "",
    street: "",
    telephone: ""
  };

  const shippingDisplayDetails = {
    ...defaultEmptyShippingDetails,
    ...shippingDetails,
    recipientEmail: shippingDetails?.recipientEmail || requestedBy.email,
    recipientName: shippingDetails?.recipientName || requestedBy.name
  };

  const {
    city,
    state,
    country,
    postcode,
    street,
    telephone,
    diallingCode,
    recipientName,
    recipientEmail
  } = shippingDisplayDetails;

  const handleExpandClick = () => setExpanded(!expanded);
  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const onStripeSetPurchasedFulfilled = () =>
    supplier?.preferredPaymentMethod === PreferredPaymentMethod.PAYOUT
      ? payoutDialog.current?.setPayoutModalOpen(true)
      : null;

  const checkoutValues = {
    checkoutGrossPrice:
      markedPurchased?.checkoutGrossPrice != null
        ? markedPurchased.checkoutGrossPrice
        : grossPriceAgreedByLearner,
    vatAmount: vatAmount ?? 0,
    vatRatePercent: vatRatePercent ?? 0,
    currencyCode
  };

  const netPrice = checkoutValues.checkoutGrossPrice - checkoutValues.vatAmount;

  return (
    <Box mb={2}>
      <Card>
        <Box>
          <CardHeader
            avatar={
              <Grid container spacing={1} justifyContent="flex-start">
                {isStripeAccount && (
                  <Grid item>
                    <StripeChip />
                  </Grid>
                )}
                <Grid item>
                  <StatusChip status={status} />
                </Grid>
                {supplier?.partnerAccount && (
                  <Grid item>
                    <CommissionableChip />
                  </Grid>
                )}
                {isAmazonFulfilmentAutomatic && (
                  <Grid item>
                    <FulfimentAutomaticChip />
                  </Grid>
                )}
              </Grid>
            }
            action={
              <>
                <IconButton
                  onClick={handleToggle}
                  aria-label="expand"
                  ref={anchorRef}
                >
                  <MoreHorizIcon />
                </IconButton>
                <Popper
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                  style={{ zIndex: 999 }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom"
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList autoFocusItem={open} id="order-menu">
                            <MaterialLink
                              target="_blank"
                              rel="noopener noreferrer"
                              href={`/orders-stripe/${id}/`}
                            >
                              <MenuItem>View order</MenuItem>
                            </MaterialLink>
                            <MenuItem
                              onClick={() =>
                                copy(
                                  `${window.location.host}/orders-stripe/${id}/`
                                )
                              }
                            >
                              Copy order URL
                            </MenuItem>

                            {isKindleOrder && (
                              <CopyKindleCardMenuItem
                                firstName={"PLACEHOLDER"}
                                orderTitle={title}
                              />
                            )}
                            <MaterialLink
                              target="_blank"
                              rel="noopener noreferrer"
                              href={`https://backoffice.app.learner.be/requests/request/${requestId}/`}
                            >
                              <MenuItem>View in Admin</MenuItem>
                            </MaterialLink>
                            <MenuItem onClick={handleExpandClick}>
                              {expanded ? "Hide Status Log" : "Show Status Log"}
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </>
            }
            title={
              assignedTo?.__typename === "User"
                ? `Assigned to ${assignedTo.firstName} ${assignedTo.lastName}`
                : "Unassigned"
            }
            subheader={`Approved ${moment(approvedAt).format(
              "MMMM Do YYYY, h:mm:ss a"
            )}`}
          />

          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Box mb={2}>
                  <Card>
                    <CardContent>
                      <Typography variant="caption">
                        {resourceType
                          ? getResourceTypeLabel(resourceType)
                          : "No type set"}{" "}
                        <StripeRectifyResourceTypeButton
                          orderId={id}
                          resourceType={resourceType}
                        />
                      </Typography>
                      <Typography variant="h5">{title}</Typography>
                      {productEventDate && (
                        <Typography variant="subtitle1">
                          {`Requested date: `}
                          {moment(productEventDate).format("MMMM Do YYYY")}
                        </Typography>
                      )}

                      {productDescription && (
                        <Typography variant="subtitle1">
                          {productDescription}
                        </Typography>
                      )}

                      {supplier && (
                        <Box py={2}>
                          Supplier:{" "}
                          <MaterialLink
                            color="inherit"
                            component={Link}
                            to={`/suppliers/${supplier.id}/`}
                          >
                            {supplier.name} 🔗
                          </MaterialLink>
                        </Box>
                      )}

                      <Box py={2}>
                        <ReconciledChip transactions={transactions} />
                        <div>
                          <strong>Final Gross Price</strong>{" "}
                          {addCurrency(
                            refundedAt ? 0 : checkoutValues.checkoutGrossPrice,
                            checkoutValues.currencyCode
                          )}
                        </div>
                        <div>
                          <strong>Net Price (excl VAT)</strong>{" "}
                          {addCurrency(
                            refundedAt ? 0 : netPrice,
                            checkoutValues.currencyCode
                          )}
                        </div>
                        <div>
                          <strong>Total VAT</strong>{" "}
                          {addCurrency(
                            refundedAt ? 0 : checkoutValues.vatAmount,
                            checkoutValues.currencyCode
                          )}
                        </div>
                      </Box>
                    </CardContent>

                    <CardActions>
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href={purchaseLink}
                      >
                        <Button type="button" size="small">
                          Provider
                        </Button>
                      </a>
                    </CardActions>
                  </Card>
                </Box>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  <Typography variant="caption" display="block" gutterBottom>
                    <b>Requested on</b>{" "}
                    <i>
                      {moment(requestedAt).format("MMMM Do YYYY, h:mm:ss a")}
                    </i>
                  </Typography>
                  {approvedAt && (
                    <Typography variant="caption" display="block" gutterBottom>
                      <b>Approved on</b>{" "}
                      <i>
                        {moment(approvedAt).format("MMMM Do YYYY, h:mm:ss a")}
                      </i>{" "}
                      by <b>not yet available</b>
                    </Typography>
                  )}
                  {markedPurchased && markedPurchased?.by && (
                    <Typography variant="caption" display="block" gutterBottom>
                      <b>Purchased on</b>{" "}
                      <i>
                        {moment(markedPurchased?.at).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )}
                      </i>{" "}
                      by{" "}
                      <b>
                        {markedPurchased.by.firstName}{" "}
                        {markedPurchased.by.lastName}
                      </b>
                    </Typography>
                  )}
                  {markedWontPurchase && markedWontPurchase.by && (
                    <Typography variant="caption" display="block" gutterBottom>
                      <b>Marked won't purchase on</b>{" "}
                      <i>
                        {moment(markedWontPurchase.at).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )}
                      </i>{" "}
                      by{" "}
                      <b>
                        {markedWontPurchase.by.firstName}{" "}
                        {markedWontPurchase.by.lastName}
                      </b>
                    </Typography>
                  )}
                  {refundedAt && refundedBy && (
                    <Typography variant="caption" display="block" gutterBottom>
                      <b>Refunded on</b>{" "}
                      <i>
                        {moment(refundedAt).format("MMMM Do YYYY, h:mm:ss a")}
                      </i>{" "}
                      by{" "}
                      <b>
                        {refundedBy.firstName} {refundedBy.lastName}
                      </b>
                    </Typography>
                  )}
                </Collapse>
              </Grid>
              <Grid item xs={4}>
                {
                  <Fragment>
                    <Typography variant="overline" gutterBottom>
                      Address{" "}
                      <StripeShippingDetails
                        orderId={id}
                        shippingDetails={shippingDisplayDetails}
                      />
                    </Typography>
                    <Typography
                      variant="body2"
                      gutterBottom
                      component="address"
                    >
                      {recipientName && (
                        <div>
                          {recipientName}{" "}
                          <CopyButton onClick={() => copy(recipientName)} />
                        </div>
                      )}
                      {recipientEmail && (
                        <div>
                          {recipientEmail}{" "}
                          <CopyButton onClick={() => copy(recipientEmail)} />
                        </div>
                      )}
                      {street && (
                        <div>
                          {street} <CopyButton onClick={() => copy(street)} />
                        </div>
                      )}
                      {postcode && city && (
                        <div>
                          {postcode}{" "}
                          <CopyButton onClick={() => copy(postcode)} />
                          {city} <CopyButton onClick={() => copy(city)} />
                        </div>
                      )}
                      {state && (
                        <div>
                          {state} <CopyButton onClick={() => copy(state)} />
                        </div>
                      )}
                      <div>
                        {country}{" "}
                        <CopyButton onClick={() => copy(country || "")} />
                      </div>
                      {telephone && diallingCode && (
                        <div>
                          {phoneUtil.format(
                            phoneUtil.parseAndKeepRawInput(
                              telephone,
                              diallingCode
                            ),
                            PhoneNumberFormat.E164
                          )}{" "}
                          <CopyButton
                            onClick={() =>
                              copy(
                                phoneUtil.format(
                                  phoneUtil.parseAndKeepRawInput(
                                    telephone,
                                    diallingCode
                                  ),
                                  PhoneNumberFormat.E164
                                )
                              )
                            }
                          />
                        </div>
                      )}
                    </Typography>
                    {additionalNote && (
                      <Fragment>
                        <Typography variant="overline" gutterBottom>
                          Note to Learnerbly
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {additionalNote}
                        </Typography>
                      </Fragment>
                    )}
                  </Fragment>
                }
              </Grid>
              <Grid item xs={4}>
                <StripeInternalNote orderId={id} internalNote={internalNote} />

                <Divider />
                <Box pt={2} pb={2}>
                  <Typography
                    display="block"
                    variant="overline"
                    gutterBottom
                    noWrap
                  >
                    Learnerbly Price
                  </Typography>
                  <Typography
                    display="block"
                    variant="body1"
                    gutterBottom
                    noWrap
                  >
                    {addCurrency(grossPriceAgreedByLearner, currencyCode)}{" "}
                    <OriginalGrossPrice /> (incl VAT)
                  </Typography>
                </Box>

                <Divider />
                <Box pt={2} pb={2}>
                  <Typography
                    display="block"
                    variant="overline"
                    gutterBottom
                    noWrap
                  >
                    About {name} <CopyButton onClick={() => copy(name)} /> (
                    {currencyCode})
                  </Typography>
                  <Typography
                    display="block"
                    variant="body1"
                    color={balance <= 0 ? "secondary" : "primary"}
                    gutterBottom
                    noWrap
                  >
                    {balance <= 0 && <WarningIcon fontSize="small" />}{" "}
                    {addCurrency(balance, currencyCode)} left in account
                  </Typography>
                  <MaterialLink
                    color="inherit"
                    component={Link}
                    to={`/accounts/${accountId}/`}
                  >
                    View Account
                  </MaterialLink>
                </Box>
                <Divider />
                <Box pt={2}>
                  <MaterialLink
                    rel="noopener noreferrer"
                    target="_blank"
                    href={`https://backoffice.app.learner.be/users/${requestedBy.id}/`}
                  >
                    View User in Admin
                  </MaterialLink>
                </Box>
              </Grid>
            </Grid>
          </CardContent>

          <CardActions>
            {[TStatus.OPEN, TStatus.ON_HOLD].includes(status) && (
              <>
                <StripeSetInProgressButton orderId={id} />
                <StripeAssignToButton orderId={id} />
              </>
            )}
            {hasAmazonResourceUrl ? <ViewAmazonButton /> : null}
            {[TStatus.IN_PROGRESS].includes(status) && (
              <Fragment>
                <StripeSetPurchasedButton
                  orderId={id}
                  checkoutValues={checkoutValues}
                  resourceType={resourceType}
                  supplierId={supplier?.id || ""}
                  onFulfilled={onStripeSetPurchasedFulfilled}
                />
              </Fragment>
            )}
            {[TStatus.OPEN, TStatus.IN_PROGRESS].includes(status) && (
              <Fragment>
                <StripeSetOnHoldButton orderId={id} />
              </Fragment>
            )}
            {[TStatus.IN_PROGRESS].includes(status) && (
              <Fragment>
                <StripeTriggerActionsButton
                  orderId={id}
                  hasActions={Boolean(supplier?.hasActions)}
                />
              </Fragment>
            )}
            {[TStatus.IN_PROGRESS, TStatus.PURCHASED].includes(status) && (
              <Fragment>
                {!amazonData?.asin ? (
                  <ViewPurchaseProcessButton
                    supplierId={supplier && supplier.id}
                  />
                ) : null}
              </Fragment>
            )}
            {[TStatus.PURCHASED].includes(status) &&
              (supplier?.isPayoutAllowed || currencyCode === "USD") && ( // Workaround to allow payout for all the orders linked to an account with USD currency
                <PayoutButton
                  orderId={id}
                  supplierId={supplier?.id ?? ""}
                  checkoutValues={checkoutValues}
                  dialogRef={payoutDialog}
                />
              )}
            {[TStatus.OPEN, TStatus.IN_PROGRESS, TStatus.ON_HOLD].includes(
              status
            ) && (
              <StripeSetWontPurchaseButton
                orderId={id}
                disabled={
                  amazonData != null &&
                  ![
                    StripeAmazonOrderStatus.REJECTED,
                    StripeAmazonOrderStatus.CANCELLED
                  ].includes(amazonData.status)
                }
              />
            )}
            {[TStatus.PURCHASED].includes(status) && (
              <>
                <StripeRectifyPriceButton
                  orderId={id}
                  checkoutValues={checkoutValues}
                />
                <SetRefundButton orderId={id} />
              </>
            )}
          </CardActions>
        </Box>
      </Card>
    </Box>
  );
};

export default Order;
