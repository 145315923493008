import React, { useContext, useState, Fragment, ChangeEvent } from "react";
import { useMutation } from "@apollo/react-hooks";
import AppContext from "../App/AppContext";
import {
  SetCancelledVariables as TSetCancelledVariables,
  SetCancelled as TSetCancelled
} from "./__generated__/SetCancelled";

import { NotificationTypes } from "../Notification/Notification";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  TextField,
  CircularProgress
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/CancelOutlined";

import setCancelledMutation from "./set-cancelled.graphql";

const SetCancelledButton = ({
  orderId,
  disabled
}: {
  orderId: string;
  disabled: boolean;
}) => {
  const [mutation, { loading }] = useMutation<
    TSetCancelled,
    TSetCancelledVariables
  >(setCancelledMutation);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [cancellationReason, setCancellationReason] = useState<string | null>(
    ""
  );
  const { handleShowNotification } = useContext(AppContext);

  const handleChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setCancellationReason(e.target.value);
  };

  return (
    <Fragment>
      <Button
        color="secondary"
        disabled={loading || disabled}
        onClick={() => setIsOpen(true)}
        size="large"
      >
        <CancelIcon /> Cancel Order
      </Button>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will cancel the request for the learner and can't be reverted
          </DialogContentText>
          <TextField
            autoFocus={true}
            margin="dense"
            id="cancellationReason"
            label="Reason for cancellation"
            type="text"
            fullWidth={true}
            multiline={true}
            rows={3}
            value={cancellationReason}
            onChange={handleChange}
            inputProps={{ maxLength: 1000 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)}>Don't cancel</Button>
          <Button
            onClick={() =>
              mutation({
                variables: {
                  orderId,
                  cancellationReason: cancellationReason || null
                }
              })
                .catch(({ message }: { message: string }) =>
                  handleShowNotification({
                    type: NotificationTypes.error,
                    message
                  })
                )
                .finally(() => setIsOpen(false))
            }
            color="secondary"
            autoFocus
            disabled={loading}
          >
            {loading ? <CircularProgress size={30} /> : "Yes, I'm sure"}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default SetCancelledButton;
