import { Grid, Box } from "@material-ui/core";
import React from "react";
import VatField from "./VatField";
import { getCurrencySymbol } from "../../util/text-display";
import NumberField from "../NumberField";
import { toFixedFloat } from "./utils";

interface FormValues {
  goodsPrice: number;
  netPrice: number;

  vatRatePercent: number | null;

  commissionPrice: number;
  commissionPriceVAT: number;
  totalCommissionPrice: number;

  shippingFees: number;
}

interface Props {
  formValues: FormValues;
  updateValues: (newValues: FormValues) => void;
  currencyCode: any;
}

const OrderDetailForm = ({ formValues, updateValues, currencyCode }: Props) => {
  const calculateVatPrices = (
    updatedState?: Partial<typeof formValues>
  ): Partial<typeof formValues> => {
    const values = { ...formValues, ...updatedState };

    const vatPercent = values.vatRatePercent ? values.vatRatePercent / 100 : 0;

    const commissionOnVat =
      updatedState?.commissionPriceVAT ||
      toFixedFloat(values.commissionPrice * vatPercent);

    return {
      vatRatePercent: values.vatRatePercent,
      totalCommissionPrice: values.commissionPrice + commissionOnVat,
      commissionPrice: values.commissionPrice,
      commissionPriceVAT: commissionOnVat,
      // goodsPrice: toFixedFloat(values.netPrice * (1 + vatPercent))
      netPrice: toFixedFloat(values.goodsPrice / (1 + vatPercent))
    };
  };

  const onFormChange = (fieldName: keyof typeof formValues) => {
    return (textValue: string | number, floatValue?: number) => {
      // Take preference to a floatValue when it's available
      const value = floatValue ? floatValue : textValue;

      if (value !== formValues[fieldName]) {
        return updateValues({
          ...formValues,
          ...calculateVatPrices({ [fieldName]: value }),
          [fieldName]: value
        });
      }
    };
  };

  const handleNumberFieldChange =
    (fieldName: keyof typeof formValues) =>
    (_value: string, floatValue: number) =>
      updateValues({ ...formValues, [fieldName]: floatValue });

  const currencySymbol = getCurrencySymbol(currencyCode);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box mt={2}>
          <NumberField
            name="goodsPrice"
            label="Goods Price (incl VAT)"
            adornment={currencySymbol}
            value={formValues.goodsPrice}
            onChange={onFormChange("goodsPrice")}
            required
          />
        </Box>
      </Grid>

      <Grid item xs={12}>
        <VatField
          value={formValues.vatRatePercent}
          onChange={onFormChange("vatRatePercent")}
        />
      </Grid>

      <Grid item xs={6}>
        <NumberField
          name="netPrice"
          label="Net Price"
          adornment={currencySymbol}
          value={formValues.netPrice}
          onChange={onFormChange("netPrice")}
          required
        />
      </Grid>

      <Grid item xs={6}>
        <NumberField
          name="vatAmount"
          label="VAT Amount"
          adornment={currencySymbol}
          value={formValues.goodsPrice - formValues.netPrice}
          disabled
        />
      </Grid>

      <Grid item xs={12}>
        <Box mt={2}>
          <NumberField
            name="commissionPrice"
            label="Commission (excl VAT)"
            adornment={currencySymbol}
            value={formValues.commissionPrice}
            onChange={onFormChange("commissionPrice")}
            required
          />
        </Box>
      </Grid>

      <Grid item xs={6}>
        <NumberField
          name="commissionPriceVAT"
          label="VAT on Commission"
          adornment={currencySymbol}
          value={formValues.commissionPriceVAT}
          onChange={onFormChange("commissionPriceVAT")}
        />
      </Grid>

      <Grid item xs={6}>
        <NumberField
          name="totalCommissionPrice"
          label="Total Commission (incl VAT)"
          adornment={currencySymbol}
          value={formValues.totalCommissionPrice}
          onChange={onFormChange("totalCommissionPrice")}
          disabled
        />
      </Grid>

      <Grid item xs={12}>
        <Box mt={2}>
          <NumberField
            name="shippingFees"
            label="Shipping & Other Transaction Fees (excl from VAT calculation)"
            adornment={currencySymbol}
            value={formValues.shippingFees}
            onChange={handleNumberFieldChange("shippingFees")}
            helperText="Optional"
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default OrderDetailForm;
