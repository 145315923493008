import gql from "graphql-tag";

export default gql`
  query Orders(
    $after: String
    $first: Int
    $filters: OrderFilters
    $sortOrder: SortOrder
  ) {
    orders(
      after: $after
      first: $first
      filters: $filters
      sortOrder: $sortOrder
    ) {
      edges {
        id
        cursor
        node {
          id
          account {
            id
            name
            currencyCode
            balance
            isStripe
          }
          assignedTo {
            id
            firstName
            lastName
          }
          productDescription
          title
          status
          requestId
          isAdhoc
          approvedAt
          approvedBy
          requestedAt
          internalNote
          additionalNote
          grossPrice
          goodsPrice
          updatedGoodsPrice
          shippingFees
          commissionAmount
          commissionVatAmount
          purchaseLink
          vatAmount
          vatRatePercent
          resourceType
          requesterOutstandingOrderCount
          requestedBy
          shippingDetails {
            city
            state
            country
            postcode
            street
            recepientEmail
            recepientName
            telephone
            diallingCode
          }
          purchasedAt
          purchasedBy {
            id
            ... on User {
              firstName
              lastName
            }
          }
          cancelledAt
          cancelledBy {
            id
            ... on User {
              firstName
              lastName
            }
          }
          refundedAt
          refundedBy {
            id
            ... on User {
              firstName
              lastName
            }
          }
          paidAt
          paidBy {
            id
            ... on User {
              firstName
              lastName
            }
          }
          supplier {
            id
            name
            hasActions
            link
            isPayoutAllowed
            preferredPaymentMethod
            partnerAccount {
              id
            }
          }
          eventDate
          isFulfilmentAutomatic
          amazonData {
            amazonASIN
            externalId
            amazonConfirmed
            amazonConfirmationId
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
