import { ChangeEvent, useEffect, useMemo, useState, useContext } from "react";

import { createStyles, makeStyles, Theme } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { useQuery, useMutation } from "@apollo/react-hooks";

import { Accounts as TAccounts } from "./__generated__/Accounts";
import {
  LinkAccountToTransaction as TLinkAccountToTransaction,
  LinkAccountToTransactionVariables as TLinkAccountToTransactionVariables
} from "./__generated__/LinkAccountToTransaction";
import { StripeTransactionType } from "../../config/globalTypes";
import { NotificationTypes } from "../Notification/Notification";
import ReconcileTopUpTableRow from "./ReconcileTopUpTableRow";
import { FormattedRow } from "./types";
import Loading from "../Loading";
import ErrorComponent from "../Error";
import AppContext from "../App/AppContext";
import { getErrorMessage } from "../../util/error-lib";

import accountsQuery from "./accounts.graphql";
import linkAccountToTransactionMutation from "./link-account-to-transaction.graphql";
import transactionsQuery from "./transactionsTopUp.graphql";

interface Props {
  rows: FormattedRow[];
  hasMoreRows?: boolean;
  fetchRows: (index: string, limit: number) => Promise<void>;
  updateRows: (rows: FormattedRow[]) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      "& th": {
        fontSize: "12px",
        color: "#7d7d7d"
      }
    },
    table: {
      "& td": {
        fontSize: "12px"
      }
    },
    mainAction: {
      position: "fixed",
      right: theme.spacing(4),
      bottom: theme.spacing(4)
    }
  })
);

const ReconcileTopUpTable = ({
  rows,
  updateRows,
  fetchRows,
  hasMoreRows = false
}: Props) => {
  const classes = useStyles();

  const [page, setPage] = useState<number>(0);
  const [numberOfRows, setNumberOfRows] = useState<number>(10);
  const [paginationLoading, setPaginationLoading] = useState<boolean>(false);
  const [currentRows, setCurrentRows] = useState<FormattedRow[]>([]);
  const {
    loading: accountsLoading,
    error: accountsError,
    data: accountsData
  } = useQuery<TAccounts>(accountsQuery, {
    variables: { filters: { isStripe: true, isArchived: false } }
  });
  const [linkAccountMutation] = useMutation<
    TLinkAccountToTransaction,
    TLinkAccountToTransactionVariables
  >(linkAccountToTransactionMutation);

  const { handleShowNotification } = useContext(AppContext);

  const numberOfPages = useMemo(
    () => Math.ceil(rows.length / numberOfRows),
    [rows, numberOfRows]
  );

  useEffect(() => {
    const currentIndex = page * numberOfRows;
    setCurrentRows(rows.slice(currentIndex, currentIndex + numberOfRows));
  }, [rows, page, numberOfRows]);

  const updateRow = async (updatedRow: FormattedRow) => {
    const {
      transaction: { id: transactionId, account }
    } = updatedRow;
    try {
      if (!account?.id) {
        throw new Error("please select an account");
      }

      const { data } = await linkAccountMutation({
        variables: { transactionId, accountId: account.id },
        refetchQueries: [
          {
            query: transactionsQuery,
            variables: {
              unreconciledOnly: true,
              types: [StripeTransactionType.TOPUP]
            }
          }
        ]
      });

      if (data) {
        const result = data.linkAccountToStripeTransaction;

        if (result.__typename !== "LinkAccountToStripeTransactionOutput") {
          handleShowNotification({
            type: NotificationTypes.error,
            message: result.message
          });
        }
      }
    } catch (error) {
      const message =
        getErrorMessage(error) ??
        "Oh no, there was a problem linking the account to the top up.";
      handleShowNotification({
        type: NotificationTypes.error,
        message
      });
    }
  };

  const fetchPage = async (cursor: string, numberOfRows: number) => {
    setPaginationLoading(true);

    try {
      await fetchRows(cursor, numberOfRows);
    } finally {
      setPaginationLoading(false);
    }
  };

  const onChangePage = async (pageIndex: number) => {
    if (paginationLoading) return;
    if (pageIndex + 1 > numberOfPages)
      await fetchPage(rows[rows.length - 1].cursor, numberOfRows);
    setPage(pageIndex);
  };

  const onNumberOfRowsChanged = async (newNumberOfRows: number) => {
    const shouldFetch = newNumberOfRows > rows.length;
    setNumberOfRows(newNumberOfRows);
    setPage(0); // Set page back to 0

    // Is requesting more rows than already has
    if (shouldFetch) await fetchPage(currentRows[0].cursor, newNumberOfRows);
  };

  if (accountsError) return <ErrorComponent message={accountsError.message} />;

  return (
    <>
      <Table className={classes.table}>
        <TableHead className={classes.header}>
          <TableRow>
            <TableCell>Currency</TableCell>
            <TableCell>Transaction Date</TableCell>
            <TableCell>Transaction Amount</TableCell>
            <TableCell>Transaction Statement</TableCell>
            <TableCell>Account</TableCell>
            <TableCell></TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>

        {paginationLoading || accountsLoading ? (
          <TableBody>
            <TableRow>
              <TableCell colSpan={14}>
                <Loading />
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <TableBody>
            {currentRows.map(row => (
              <ReconcileTopUpTableRow
                key={row.id}
                row={row}
                accounts={accountsData?.accounts || []}
                updateRow={updateRow}
              />
            ))}
          </TableBody>
        )}
        <TableFooter>
          <TableRow>
            <TablePagination
              count={hasMoreRows ? -1 : rows.length}
              page={page}
              rowsPerPage={numberOfRows}
              onPageChange={async (_, pageIndex) => onChangePage(pageIndex)}
              onRowsPerPageChange={(event: ChangeEvent<HTMLInputElement>) => {
                onNumberOfRowsChanged(Number(event.target.value));
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </>
  );
};

export default ReconcileTopUpTable;
