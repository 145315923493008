import gql from "graphql-tag";

export default gql`
  mutation SetStripePurchased(
    $orderId: Int!
    $checkoutGrossPrice: Fixed!
    $vatAmount: Fixed!
    $vatRatePercent: Fixed
    $supplierId: ID!
    $resourceType: ResourceType!
  ) {
    setStripePurchased(
      orderId: $orderId
      checkoutGrossPrice: $checkoutGrossPrice
      vatAmount: $vatAmount
      vatRatePercent: $vatRatePercent
      supplierId: $supplierId
      resourceType: $resourceType
    ) {
      id
      status
      vatAmount
      vatRatePercent
      resourceType
      supplier {
        id
        name
        link
        isPayoutAllowed
        hasActions
        preferredPaymentMethod
        partnerAccount {
          name
        }
      }
      markedPurchased {
        by {
          id
          email
          firstName
          lastName
        }
        at
        checkoutGrossPrice
      }
    }
  }
`;
