import React, { useContext, useState, Fragment } from "react";
import { useMutation } from "@apollo/react-hooks";
import AppContext from "../App/AppContext";
import {
  UpdateGoodsPriceVariables as TUUpdateGoodsPriceVariables,
  UpdateGoodsPrice as TUpdateGoodsPrice
} from "./__generated__/UpdateGoodsPrice";

import { NotificationTypes } from "../Notification/Notification";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText
} from "@material-ui/core";
import NumberField from "../NumberField";

import { getCurrencySymbol } from "../../util/text-display";
import { withFormState } from "../../util/form-validation";

import updatePriceMutation from "./update-goods-price.graphql";

interface TFormState {
  updatedGoodsPrice: number;
}

const UpdatePriceButton = ({
  orderId,
  currencyCode,
  updatedGoodsPrice
}: {
  orderId: string;
  currencyCode: string;
  updatedGoodsPrice: number;
}) => {
  const [mutation, { loading }] = useMutation<
    TUpdateGoodsPrice,
    TUUpdateGoodsPriceVariables
  >(updatePriceMutation);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { handleShowNotification } = useContext(AppContext);

  const [formState, setFormState] = useState<TFormState>({
    updatedGoodsPrice
  });

  const handleFieldChange = withFormState<TFormState>(formState, setFormState);

  const currencySymbol = getCurrencySymbol(currencyCode);

  return (
    <Fragment>
      <Button disabled={loading} onClick={() => setIsOpen(true)}>
        Update price
      </Button>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the new price of this order below. We’ll use this
            updated price when sending price change notifications to users.
          </DialogContentText>
          <form noValidate autoComplete="off">
            <NumberField
              id="updated-goods-price"
              adornment={currencySymbol}
              value={formState.updatedGoodsPrice}
              onChange={handleFieldChange<number>("updatedGoodsPrice")}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)} disabled={loading}>
            Cancel
          </Button>
          <Button
            onClick={() =>
              mutation({
                variables: {
                  orderId,
                  updatedGoodsPrice: formState.updatedGoodsPrice
                }
              })
                .catch(({ message }: { message: string }) =>
                  handleShowNotification({
                    type: NotificationTypes.error,
                    message
                  })
                )
                .finally(() => setIsOpen(false))
            }
            color="primary"
            autoFocus
            disabled={loading}
          >
            {loading ? "Updating..." : "Update price"}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default UpdatePriceButton;
