import { WhoAmI_me } from "./../Entry/__generated__/whoami";

import { createContext } from "react";
import { NotificationTypes } from "../Notification/Notification";

export type ShowNotification = {
  message: string;
  type: NotificationTypes;
};

export type HandleShowNotification = (args: ShowNotification) => void;

export interface AppStateValues {
  isMenuOpen: boolean;
  handleDrawerOpen: () => void;
  handleDrawerClose: () => void;
  handleShowNotification: HandleShowNotification;
  user: WhoAmI_me;
}

const AppContext = createContext<AppStateValues>({
  isMenuOpen: false,
  handleDrawerOpen: () => {},
  handleDrawerClose: () => {},
  handleShowNotification: () => {},
  user: {
    id: "",
    firstName: "",
    lastName: "",
    __typename: "User"
  }
}); // need to add those here because Reacts createContext expects a default value

export default AppContext;
