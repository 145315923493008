import gql from "graphql-tag";

export default gql`
  mutation SetOnHold($orderId: ID!) {
    setOnHold(orderId: $orderId) {
      id
      status
    }
  }
`;
