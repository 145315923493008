import gql from "graphql-tag";

export default gql`
  query StripeTransactions(
    $accountId: String
    $unreconciledOnly: Boolean
    $after: String
    $first: Int
  ) {
    stripeTransactions(
      after: $after
      first: $first
      filters: {
        unreconciledOnly: $unreconciledOnly
        accountId: $accountId
        types: [PURCHASE, REFUND, MANUAL_REFUND]
      }
    ) {
      totalCount
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          type
          amount
          statementDescriptor
          createdAt
          currency
          merchantAmount
          merchantCurrency
          account {
            id
            name
          }
          order {
            id
            createdAt
            markedPurchased {
              at
              checkoutGrossPrice
            }
            supplier {
              id
              name
            }
            requestCurrencyCode
            requestGrossPrice
            title
            vatAmount
          }
        }
      }
    }
  }
`;
