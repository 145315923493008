import { useMutation } from "@apollo/react-hooks";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import ShippingIcon from "@material-ui/icons/LocalShipping";
import { ApolloError } from "apollo-client";

import { useContext, useState } from "react";
import { ResourceType } from "../../config/globalTypes";
import { handleSubmit } from "../../util/form-validation";
import AppContext from "../App/AppContext";
import { NotificationTypes } from "../Notification/Notification";
import RectifyPriceForm from "../StripeRectifyPriceButton/RectifyPriceForm";
import StripeSetPurchasedForm from "./StripeSetPurchasedForm";
import {
  SetStripePurchased,
  SetStripePurchasedVariables
} from "./__generated__/SetStripePurchased";

import SetStripePurchasedMutation from "./set-stripe-purchased.graphql";

interface StripeSetPurchasedCheckoutValues {
  checkoutGrossPrice: GQL.Fixed;
  vatAmount?: GQL.Fixed;
  vatRatePercent?: GQL.Fixed;
  currencyCode: string;
}

interface StripeSetPurchasedButtonProps {
  orderId: number;
  checkoutValues: StripeSetPurchasedCheckoutValues;
  resourceType: ResourceType;
  supplierId: string;
  onFulfilled?: () => void;
}

interface TFormState {
  checkoutGrossPrice: GQL.Fixed;
  vatAmount: GQL.Fixed;
  vatRatePercent: GQL.Fixed;
  resourceType: ResourceType;
  supplierId: string;
}

const toFixed = (value: string | number) =>
  Number(Number.parseFloat(value.toString().replaceAll(",", "")).toFixed(2));

const StripeSetPurchasedButton = ({
  orderId,
  checkoutValues: {
    checkoutGrossPrice,
    vatAmount,
    vatRatePercent,
    currencyCode
  },
  resourceType,
  supplierId,
  onFulfilled
}: StripeSetPurchasedButtonProps) => {
  const { handleShowNotification } = useContext(AppContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [mutation, { loading }] = useMutation<
    SetStripePurchased,
    SetStripePurchasedVariables
  >(SetStripePurchasedMutation, {
    onCompleted: () => {
      setIsOpen(false);
      if (typeof onFulfilled === "function") {
        onFulfilled();
      }
    },
    onError: (error: ApolloError) =>
      handleShowNotification({
        type: NotificationTypes.error,
        message: error.message
      })
  });

  const onSubmit = handleSubmit((formData: TFormState) => {
    return mutation({
      variables: {
        orderId,
        resourceType: formData.resourceType,
        supplierId: formData.supplierId,
        checkoutGrossPrice: toFixed(formData.checkoutGrossPrice),
        vatRatePercent: toFixed(formData.vatRatePercent),
        vatAmount: toFixed(formData.vatAmount)
      }
    });
  });

  return (
    <>
      <Button color="primary" onClick={() => setIsOpen(true)} size="large">
        <ShippingIcon /> Mark as Purchased
      </Button>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>Mark as Purchased</DialogTitle>
        <form onSubmit={onSubmit}>
          <DialogContent>
            <RectifyPriceForm
              currencyCode={currencyCode}
              formValues={{
                checkoutGrossPrice,
                vatAmount,
                vatRatePercent
              }}
            />
            <StripeSetPurchasedForm formValues={{ resourceType, supplierId }} />
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button color="primary" type="submit" disabled={loading}>
              Mark as Purchased
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default StripeSetPurchasedButton;
