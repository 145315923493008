import gql from "graphql-tag";

export default gql`
  query Order($orderId: ID!) {
    order(id: $orderId) {
      id
      account {
        id
        name
        currencyCode
        balance
        isStripe
      }
      assignedTo {
        id
        firstName
        lastName
      }
      transactions {
        id
        createdAt
        lastCardDigits
        type
        amount
      }
      requesterOutstandingOrderCount
      requestedBy
      productDescription
      title
      status
      isAdhoc
      approvedAt
      approvedBy
      requestedAt
      internalNote
      additionalNote
      grossPrice
      goodsPrice
      updatedGoodsPrice
      shippingFees
      commissionAmount
      commissionVatAmount
      purchaseLink
      vatAmount
      vatRatePercent
      requestId
      resourceType
      shippingDetails {
        city
        state
        country
        postcode
        street
        recepientEmail
        recepientName
        telephone
        diallingCode
      }
      purchasedAt
      purchasedBy {
        id
        ... on User {
          firstName
          lastName
        }
      }
      cancelledAt
      cancelledBy {
        id
        ... on User {
          firstName
          lastName
        }
      }
      refundedAt
      refundedBy {
        id
        ... on User {
          firstName
          lastName
        }
      }
      paidAt
      paidBy {
        id
        ... on User {
          firstName
          lastName
        }
      }
      supplier {
        id
        name
        hasActions
        isPayoutAllowed
        preferredPaymentMethod
        link
        partnerAccount {
          id
        }
      }
      eventDate
      isFulfilmentAutomatic
      amazonData {
        amazonASIN
        externalId
        amazonConfirmed
        amazonConfirmationId
      }
      createdAt
      grossCost
      netPrice
    }
  }
`;
