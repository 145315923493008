import React, { Fragment, ReactElement, useState } from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  Breadcrumbs,
  Link,
  Box,
  Grid,
  Typography,
  Chip,
  Button,
  Fab
} from "@material-ui/core";
import LaunchIcon from "@material-ui/icons/Launch";
import CopyIcon from "@material-ui/icons/FileCopy";
import { capitalize } from "lodash";
import CopyToClipboard from "react-copy-to-clipboard";
import countries from "@learnerbly/i18n-iso-countries";
import { flag } from "country-emoji";

import { useQuery } from "@apollo/react-hooks";
import { useParams } from "react-router";

import Error from "../Error";
import Loading from "../Loading";
import PageMeta from "../PageMeta";

import UpdatePurchaseProcess from "../UpdatePurchaseProcess";
import Steps from "./components/Steps";

import {
  PurchaseProcess as TPurchaseProcess,
  PurchaseProcessVariables as TPurchaseProcessVariables
} from "./__generated__/PurchaseProcess";

import purchaseProcessByIdQuery from "./purchase-process.graphql";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainAction: {
      position: "fixed",
      right: theme.spacing(4),
      bottom: theme.spacing(4)
    },
    title: {
      fontSize: 16,
      fontWeight: 600
    },
    section: {
      marginBottom: 8
    },
    sectionHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    sectionTitle: {
      fontSize: 14,
      fontWeight: 500,
      textTransform: "uppercase",
      marginBottom: 16
    },
    sectionSubtitle: {
      fontSize: 14,
      fontWeight: 500
    },
    sectionRows: {
      marginTop: 8
    },
    sectionRow: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: 8,
      marginBottom: 8
    },
    sectionCard: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: 16,
      padding: 16,
      border: "1px solid #eee"
    },
    sectionCardTitle: {
      fontSize: 14,
      fontWeight: 500
    },
    panel: {
      background: "#fff",
      padding: "24px",
      border: "1px solid #eee",
      marginBottom: 24
    },
    emptyState: {
      display: "flex",
      justifyContent: "center",
      padding: 64
    },
    assetValue: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      color: "#6d6d6d",
      fontSize: 12,
      fontWeight: 500,
      textTransform: "uppercase"
    }
  })
);

const PurchaseProcess = (): ReactElement => {
  const classes = useStyles();

  const { supplierId, purchaseProcessId } = useParams<{
    supplierId: string;
    purchaseProcessId: string;
  }>();

  const [updating, setIsUpdating] = useState(false);

  const [open, setOpen] = useState<{
    updatePurchaseProcess: boolean;
  }>({
    updatePurchaseProcess: false
  });

  const handleModalOpen = (modalType: string) =>
    setOpen(modalState => ({ ...modalState, [modalType]: true }));

  const handleModalClose = (modalType: string) =>
    setOpen(modalState => ({ ...modalState, [modalType]: false }));

  const toggleUpdateSteps = () => setIsUpdating(prevState => !prevState);

  const { loading, error, data } = useQuery<
    TPurchaseProcess,
    TPurchaseProcessVariables
  >(purchaseProcessByIdQuery, {
    variables: { id: purchaseProcessId }
  });

  if (loading) {
    return (
      <>
        <PageMeta title="Purchase Process" />
        <Loading />
      </>
    );
  }

  if (error) {
    return (
      <>
        <PageMeta title="Purchase Process - Error" />
        <Error message={error.message} />
      </>
    );
  }

  if (data) {
    const {
      purchaseProcess: {
        name,
        supplier,
        partnerAccount,
        steps,
        isPartnerPurchaseProcess
      }
    } = data;

    return (
      <Fragment>
        <PageMeta title={`Purchase Process - ${name}`} />
        <Box mb={4}>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Suppliers</Typography>
            <Link color="inherit" href={`/suppliers/${supplierId}`}>
              {supplier.name}
            </Link>
            <Typography color="textPrimary">{name}</Typography>
          </Breadcrumbs>
          <Grid
            container
            spacing={1}
            justifyContent="space-between"
            alignItems="baseline"
            xs={12}
          >
            <Grid item xs={10}>
              <Typography variant="h4">
                {name}{" "}
                {isPartnerPurchaseProcess && (
                  <Chip size="small" label="Partner Process" color="primary" />
                )}
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              alignItems="flex-end"
              style={{ flexBasis: "auto" }}
            >
              <Button onClick={toggleUpdateSteps}>
                {updating ? "Finish Editing" : "Edit Steps"}
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <Box className={classes.panel}>
                <Box className={classes.section}>
                  <Box className={classes.sectionHeader}>
                    <Typography className={classes.sectionTitle} variant="h5">
                      VAT Rates
                    </Typography>
                    <Typography
                      className={classes.sectionCardTitle}
                      variant="h5"
                    >
                      {`${flag(supplier.countryCode)}${countries.getName(
                        supplier.countryCode,
                        "en"
                      )}`}
                    </Typography>
                  </Box>
                  <Box className={classes.sectionRows}>
                    {supplier.vatRates &&
                      supplier.vatRates.map(
                        ({
                          type,
                          amount
                        }: {
                          type: string;
                          amount: number;
                        }) => (
                          <Box className={classes.sectionRow}>
                            <span>{capitalize(type.toLowerCase())} Rate</span>
                            <span>{amount}%</span>
                          </Box>
                        )
                      )}
                  </Box>
                </Box>
              </Box>

              {partnerAccount && (
                <Box className={classes.panel}>
                  <Box className={classes.section}>
                    <Typography className={classes.sectionTitle} variant="h5">
                      Partner Account
                    </Typography>
                    <Box className={classes.sectionRows}>
                      {partnerAccount.commissionRates &&
                        partnerAccount.commissionRates.length && (
                          <>
                            <span className={classes.sectionSubtitle}>
                              Comission Rates
                            </span>
                            {partnerAccount.commissionRates.map(
                              ({ amount, description }) => (
                                <Box className={classes.sectionRow}>
                                  <span>
                                    {description || "Description not added"}
                                  </span>
                                  <span>{amount}%</span>
                                </Box>
                              )
                            )}
                          </>
                        )}

                      {partnerAccount.discounts &&
                        partnerAccount.discounts.length && (
                          <>
                            <span className={classes.sectionSubtitle}>
                              Discounts
                            </span>
                            {partnerAccount.discounts.map(
                              ({ type, amount, description }) => (
                                <Box className={classes.sectionRow}>
                                  <span>
                                    {description || "Description not added"}
                                  </span>
                                  <span>{amount}%</span>
                                </Box>
                              )
                            )}
                          </>
                        )}
                    </Box>
                    <Box>
                      {partnerAccount.contacts &&
                        partnerAccount.contacts.length && (
                          <>
                            <span className={classes.sectionSubtitle}>
                              Contacts
                            </span>
                            {partnerAccount.contacts.map(
                              ({ firstName, lastName, email, phoneNumber }) => (
                                <Box className={classes.sectionRow}>
                                  <span>
                                    {firstName} {lastName}
                                  </span>
                                  <Link href={`mailto:${email}`}>{email}</Link>
                                  <Link href={`tel:${phoneNumber}`}>
                                    {phoneNumber}
                                  </Link>
                                </Box>
                              )
                            )}
                          </>
                        )}
                    </Box>
                    <Box>
                      {partnerAccount.assets &&
                        partnerAccount.assets.length && (
                          <>
                            <span className={classes.sectionSubtitle}>
                              Assets
                            </span>
                            {partnerAccount.assets.map(
                              ({ type, value, description }) => (
                                <Box className={classes.sectionCard}>
                                  <span>{description}</span>
                                  {type === "link" ? (
                                    <Link href={value} target="_blank">
                                      <LaunchIcon />
                                    </Link>
                                  ) : (
                                    <CopyToClipboard text={value}>
                                      <span className={classes.assetValue}>
                                        ({value}){" "}
                                        <CopyIcon
                                          style={{
                                            color: "#3f51b5",
                                            marginLeft: "8px"
                                          }}
                                        />
                                      </span>
                                    </CopyToClipboard>
                                  )}
                                </Box>
                              )
                            )}
                          </>
                        )}
                    </Box>
                  </Box>
                </Box>
              )}
            </Grid>
            <Grid item xs={8}>
              <Steps
                purchaseProcessId={purchaseProcessId}
                steps={steps}
                updating={updating}
              />
            </Grid>
          </Grid>
        </Box>

        <Box className={classes.mainAction}>
          <Fab
            color="primary"
            variant="extended"
            onClick={() => handleModalOpen("updatePurchaseProcess")}
          >
            Update Process
          </Fab>
        </Box>

        <UpdatePurchaseProcess
          supplierId={supplierId}
          partnerAccountId={partnerAccount && partnerAccount.id}
          purchaseProcess={data.purchaseProcess}
          isOpen={open.updatePurchaseProcess}
          handleModalClose={() => handleModalClose("updatePurchaseProcess")}
        />
      </Fragment>
    );
  }

  return <Box />;
};

export default PurchaseProcess;
