import React, { useContext, Fragment, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Grid,
  Box
} from "@material-ui/core";

import AppContext from "../App/AppContext";
import { Redirect } from "react-router-dom";
import { NotificationTypes } from "../Notification/Notification";
import { useMutation } from "@apollo/react-hooks";
import { handleSubmit, withFormState } from "../../util/form-validation";
import TextField from "../TextField";
import SwitchField from "../SwitchField";

import {
  UpdatePurchaseProcessVariables as TUpdatePurchaseProcessVariables,
  UpdatePurchaseProcess as TUpdatePurchaseProcess
} from "./__generated__/UpdatePurchaseProcess";

import updatePurchaseProcessMutation from "./update-purchase-process.graphql";

interface Props {
  supplierId: string;
  partnerAccountId: string | null;
  purchaseProcess: any;
  isOpen: boolean;
  handleModalClose: () => void;
}

interface TFormState {
  id: string;
  name: string;
  description: string;
  isPartnerPurchaseProcess: boolean;
}

const UpdatePurchaseProcess = ({
  supplierId,
  partnerAccountId,
  purchaseProcess,
  isOpen,
  handleModalClose
}: Props) => {
  const [mutation, { loading, data: updatePurchaseProcessMutationData }] =
    useMutation<TUpdatePurchaseProcess, TUpdatePurchaseProcessVariables>(
      updatePurchaseProcessMutation
    );

  const { handleShowNotification } = useContext(AppContext);

  const [formState, setFormState] = useState<TFormState>({
    ...purchaseProcess
  });
  const handleFieldChange = withFormState<TFormState>(formState, setFormState);

  const onSubmit = async () => {
    const { id, name, description, isPartnerPurchaseProcess } = formState;

    try {
      if (isPartnerPurchaseProcess && !partnerAccountId) {
        throw new Error(
          "Please activate the partner account before updating this process"
        );
      }

      await mutation({
        variables: {
          id,
          input: {
            name,
            description,
            isPartnerPurchaseProcess
          }
        }
      });

      handleModalClose();
      handleShowNotification({
        type: NotificationTypes.success,
        message: "Process Updated"
      });
    } catch (error) {
      handleShowNotification({
        type: NotificationTypes.error,
        message: `Oh no, there was a problem updating the process. Reason: ${error.message}`
      });
    }
  };

  return (
    <Fragment>
      <Dialog open={isOpen} onClose={handleModalClose}>
        <DialogTitle>Update Process</DialogTitle>
        <DialogContent>
          <Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={3}>
                <Grid item>
                  <Box mb={2}>
                    <TextField
                      name="name"
                      label="Name"
                      value={formState.name}
                      onChange={handleFieldChange<string>("name")}
                      required
                    />
                    <TextField
                      name="description"
                      label="Description"
                      value={formState.description}
                      onChange={handleFieldChange<string>("description")}
                      required
                    />
                    <SwitchField
                      checked={formState.isPartnerPurchaseProcess}
                      onChange={handleFieldChange<boolean>(
                        "isPartnerPurchaseProcess"
                      )}
                      name="isPartnerPurchaseProcess"
                      label="This process is for partner orders"
                      inputProps={{
                        "aria-label": "This process is for partner orders"
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Fragment>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose} color="primary">
            Cancel
          </Button>
          <Button onClick={onSubmit} color="primary" disabled={loading}>
            {loading ? <CircularProgress size={30} /> : "Update"}
          </Button>
        </DialogActions>
        {!loading && updatePurchaseProcessMutationData && (
          <Redirect
            to={`/suppliers/${supplierId}/process/${purchaseProcess.id}`}
          />
        )}
      </Dialog>
    </Fragment>
  );
};

export default UpdatePurchaseProcess;
