import React, { StrictMode, useState, Fragment } from "react";
import Button from "@material-ui/core/Button";
import Main from "../Main";
import { makeStyles } from "@material-ui/styles";
import Auth, { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { Hub } from "@aws-amplify/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import { envVars } from "../../config/env-vars";

const useStyles = makeStyles({
  container: {
    backgroundColor: "var(--neutralLightest)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    minHeight: "100vh",
    textAlign: "center"
  }
});

const onTryAgainClick = () =>
  Auth.signOut().then(() =>
    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google })
  );

const SSOCallback = () => {
  const [loading, setLoading] = useState(true);

  Hub.listen("auth", ({ payload: { event } }) => {
    if (event === "signIn") {
      window.location.replace(envVars.webUrl);
    }

    if (event === "signIn_failure") {
      setLoading(false);
    }
  });
  const classes = useStyles({});

  return (
    <StrictMode>
      <Main>
        <div className={classes.container}>
          {loading && <CircularProgress />}
          {!loading && (
            <Fragment>
              <Typography variant="h4" gutterBottom>
                User not found
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={onTryAgainClick}
              >
                Try again
              </Button>
            </Fragment>
          )}
        </div>
      </Main>
    </StrictMode>
  );
};

export default SSOCallback;
