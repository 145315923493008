import React, { useContext, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import AppContext from "../App/AppContext";
import {
  SetResourceTypeVariables as TSetResourceTypeVariables,
  SetResourceType as TSetResourceType
} from "./__generated__/SetResourceType";

import { NotificationTypes } from "../Notification/Notification";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  DialogActions
} from "@material-ui/core";
import { ResourceType as TResourceType } from "../../config/globalTypes";
import SelectField from "../SelectField";
import { getResourceTypeLabel } from "../../util/text-display";

import assignToMutation from "./set-resource-type.graphql";

const SetResourceTypeButton = ({
  orderId,
  resourceType
}: {
  orderId: string;
  resourceType: TResourceType | null;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [formState, setFormState] = useState<TResourceType | null>(
    resourceType
  );
  const [mutation, { loading }] = useMutation<
    TSetResourceType,
    TSetResourceTypeVariables
  >(assignToMutation);

  const { handleShowNotification } = useContext(AppContext);

  return (
    <>
      <Button color="primary" onClick={() => setIsOpen(true)} size="small">
        Update
      </Button>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)} maxWidth="lg">
        <DialogTitle>Set Resource Type</DialogTitle>
        <DialogContent>
          <Box mb={2}>
            <SelectField<TResourceType>
              label="Resource Type"
              value={formState || ""}
              onChange={(value: TResourceType) => setFormState(value)}
              values={Object.values(TResourceType).map(type => ({
                key: type,
                label: getResourceTypeLabel(type)
              }))}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button
            color="primary"
            type="button"
            size="small"
            onClick={() =>
              mutation({
                variables: {
                  resourceType: formState as TResourceType,
                  orderId
                }
              })
                .catch(({ message }: { message: string }) =>
                  handleShowNotification({
                    type: NotificationTypes.error,
                    message
                  })
                )
                .finally(() => setIsOpen(false))
            }
            disabled={loading || !formState}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SetResourceTypeButton;
