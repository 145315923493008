import * as env from "env-var";

export const envVars = {
  webUrl: env.get("REACT_APP_WEB_URL").required().asString(),
  apiUrl: env.get("REACT_APP_API_URL").required().asString(),
  stage: env.get("REACT_APP_STAGE").required().asString(),
  sentryDsn: env.get("REACT_APP_SENTRY_DSN").asString(),
  identityPoolId: env.get("REACT_APP_IDENTITY_POOL_ID").required().asString(),
  region: env.get("REACT_APP_REGION").required().asString(),
  userPoolId: env.get("REACT_APP_USER_POOL_ID").required().asString(),
  userPoolWebClientId: env
    .get("REACT_APP_USER_POOL_WEB_CLIENT_ID")
    .required()
    .asString(),
  cognitoDomain: env.get("REACT_APP_COGNITO_DOMAIN").required().asString()
};
