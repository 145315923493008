import { Grid } from "@material-ui/core";
import React from "react";
import { getCurrencySymbol } from "../../util/text-display";
import NumberField from "../NumberField";

interface FormValues {
  goodsPrice: number;
  netPrice: number;
}

interface Props {
  formValues: FormValues;
  updateValues: (newValues: FormValues) => void;
  currencyCode: any;
}

const OrderDetailBasicForm = ({
  formValues,
  updateValues,
  currencyCode
}: Props) => {
  const onFormChange = (fieldName: keyof typeof formValues) => {
    return (textValue: string | number, floatValue?: number) => {
      // Take preference to a floatValue when it's available
      const value = floatValue ? floatValue : textValue;

      if (value !== formValues[fieldName]) {
        return updateValues({
          ...formValues,
          [fieldName]: value
        });
      }
    };
  };

  const currencySymbol = getCurrencySymbol(currencyCode);

  return (
    <Grid item xs={12}>
      <NumberField
        name="goodsPrice"
        label="Goods Price (incl VAT)"
        adornment={currencySymbol}
        value={formValues.goodsPrice}
        onChange={onFormChange("goodsPrice")}
        required
      />
    </Grid>
  );
};

export default OrderDetailBasicForm;
