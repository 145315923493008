import gql from "graphql-tag";

export default gql`
  query PurchaseProcess($id: ID!) {
    purchaseProcess(id: $id) {
      id
      name
      isPartnerPurchaseProcess
      supplier {
        id
        name
        vatRates {
          type
          amount
        }
        countryCode
      }
      steps {
        id
        position
        title
        body
      }
      partnerAccount {
        id
        name
        commissionRates {
          type
          amount
          description
        }
        discounts {
          type
          amount
          description
        }
        contacts {
          firstName
          lastName
          email
          phoneNumber
        }
        assets {
          type
          value
          description
        }
      }
      updatedAt
    }
  }
`;
