import React, { ChangeEvent, ReactElement, useContext } from "react";
import {
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Select,
  Chip,
  InputLabel,
  Switch
} from "@material-ui/core";

import {
  StripeOrderStatus,
  ResourceType,
  SortOrder
} from "../../../config/globalTypes";
import {
  getStatusLabel,
  getResourceTypeLabel
} from "../../../util/text-display";
import { useQuery } from "@apollo/react-hooks";
import {
  FilterAllStripeUsers as TFilterAllUsers,
  FilterAllStripeUsers_users as TFilterAllUsers_users
} from "./__generated__/FilterAllStripeUsers";
import { FilterAllStripeAccounts as TFilterAllAccounts } from "./__generated__/FilterAllStripeAccounts";
import {
  FilterAllStripeSuppliers as TFilterAllSuppliers,
  FilterAllStripeSuppliers_suppliers as TFilterAllSuppliers_suppliers
} from "./__generated__/FilterAllStripeSuppliers";

import Loading from "../../Loading";
import AppContext from "../../App/AppContext";
import { SetQuery } from "use-query-params";

import usersQuery from "./users.graphql";
import accountsQuery from "./accounts.graphql";
import suppliersQuery from "./suppliers.graphql";

export interface NonNullOrderFilters {
  assignedTo: string[];
  status: StripeOrderStatus[];
  account: string[];
  supplier: string[];
  resourceType: ResourceType[];
  unassigned?: boolean;
  untyped?: boolean;
  isFulfilmentAutomatic?: boolean;
}

type FilterProps = {
  onChange: SetQuery<{}>;
  filters: NonNullOrderFilters;
  sortOrder: SortOrder;
};

const Filter = ({
  onChange,
  filters,
  sortOrder
}: FilterProps): ReactElement => {
  const statuses = new Set<StripeOrderStatus>(filters.status);
  const assignedTo = new Set<string>(filters.assignedTo);
  const accounts = new Set<string>(filters.account);
  const suppliers = new Set<string>(filters.supplier);
  const resourceTypes = new Set<ResourceType>(filters.resourceType);

  const { loading: usersLoading, data: usersData } =
    useQuery<TFilterAllUsers>(usersQuery);
  const { loading: accountsLoading, data: accountsData } =
    useQuery<TFilterAllAccounts>(accountsQuery, {
      variables: { filters: { isArchived: false, isStripe: true } }
    });
  const { loading: suppliersLoading, data: suppliersData } =
    useQuery<TFilterAllSuppliers>(suppliersQuery, {
      variables: { filters: { isArchived: false } }
    });

  const {
    user: { id: currentUserId }
  } = useContext(AppContext);

  const pushState = (update?: {}) =>
    onChange(
      {
        status: [...statuses],
        assignedTo: [...assignedTo],
        account: [...accounts],
        supplier: [...suppliers],
        resourceType: [...resourceTypes],
        unassigned: filters.unassigned,
        untyped: filters.untyped,
        isFulfilmentAutomatic: filters.isFulfilmentAutomatic,
        sortOrder,
        ...update
      },
      "push"
    );

  function handleCheckboxChange<T>(set: Set<T>, value: T) {
    return ({ target }: ChangeEvent<HTMLInputElement>) => {
      target.checked ? set.add(value as T) : set.delete(value);
      pushState();
    };
  }

  const handleSelectChange =
    (set: Set<string>) =>
    (
      event: ChangeEvent<{
        value: unknown;
      }>
    ) => {
      const values = event.target.value as string[];
      set.clear();
      values.forEach(value => set.add(value));
      pushState();
    };

  const handleSwitchChange =
    (field: string) => (event: ChangeEvent<HTMLInputElement>) => {
      pushState({
        [field]: event.target.checked
      });
    };

  const getSelectedAccountComponent = (selected: string[]) => {
    return selected.map(value => {
      const account = accountsData?.accounts.find(({ id }) => id === value);

      if (!account) return null;

      return (
        <Chip key={value} label={`${account.name} (${account.currencyCode})`} />
      );
    });
  };

  return (
    <form>
      <FormControl component="fieldset" margin="normal" fullWidth>
        <FormLabel component="legend">Status</FormLabel>
        <FormGroup>
          {Object.values(StripeOrderStatus).map(status => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.status?.includes(status)}
                  onChange={handleCheckboxChange<StripeOrderStatus>(
                    statuses,
                    status
                  )}
                />
              }
              label={getStatusLabel(StripeOrderStatus[status])}
              key={status}
            />
          ))}
        </FormGroup>
      </FormControl>
      <FormControl component="fieldset" margin="normal" fullWidth>
        <FormLabel component="legend">Assigned to</FormLabel>
        <FormControlLabel
          control={
            <Checkbox
              checked={
                filters.assignedTo && filters.assignedTo.includes(currentUserId)
              }
              onChange={handleCheckboxChange<string>(assignedTo, currentUserId)}
            />
          }
          label={`Myself`}
          key={currentUserId}
        />
        <FormControlLabel
          control={
            <Switch
              checked={filters.unassigned}
              onChange={handleSwitchChange("unassigned")}
              value="unassigned"
            />
          }
          label="Unassigned"
        />
      </FormControl>
      <FormControl component="fieldset" margin="normal" fullWidth>
        <InputLabel>Filter by User</InputLabel>
        {usersLoading && <Loading />}
        {usersData && (
          <Select
            multiple
            value={filters.assignedTo}
            onChange={handleSelectChange(assignedTo)}
            renderValue={selected =>
              (selected as string[]).map(value => {
                const { firstName, lastName } = usersData.users.find(
                  ({ id }) => id === value
                ) as TFilterAllUsers_users;
                return <Chip key={value} label={`${firstName} ${lastName}`} />;
              })
            }
          >
            {usersData.users.map(({ id, firstName, lastName }) => (
              <MenuItem key={id} value={id}>
                {firstName}
              </MenuItem>
            ))}
          </Select>
        )}
      </FormControl>
      <FormControl component="fieldset" margin="normal" fullWidth>
        <InputLabel>Filter by Account</InputLabel>
        {accountsLoading && <Loading />}
        {accountsData && (
          <Select
            multiple
            value={filters.account}
            onChange={handleSelectChange(accounts)}
            renderValue={selected =>
              getSelectedAccountComponent(selected as string[])
            }
          >
            {accountsData.accounts.map(({ id, name, currencyCode }) => (
              <MenuItem key={id} value={id}>
                {name} ({currencyCode})
              </MenuItem>
            ))}
          </Select>
        )}
      </FormControl>
      <FormControl component="fieldset" margin="normal" fullWidth>
        <InputLabel>Filter by Supplier</InputLabel>
        {suppliersLoading && <Loading />}
        {suppliersData && (
          <Select
            multiple
            value={filters.supplier}
            onChange={handleSelectChange(suppliers)}
            renderValue={selected =>
              (selected as string[]).map(value => {
                const { name } = suppliersData.suppliers.find(
                  ({ id }) => id === value
                ) as TFilterAllSuppliers_suppliers;
                return <Chip key={value} label={name} />;
              })
            }
          >
            {suppliersData.suppliers.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
        )}
      </FormControl>
      <FormControl component="fieldset" margin="normal" fullWidth>
        <FormLabel component="legend">Resource Type</FormLabel>
        <FormGroup>
          {Object.values(ResourceType).map(resourceType => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.resourceType?.includes(resourceType)}
                  onChange={handleCheckboxChange<ResourceType>(
                    resourceTypes,
                    resourceType
                  )}
                />
              }
              label={getResourceTypeLabel(resourceType)}
              key={resourceType}
            />
          ))}
        </FormGroup>
      </FormControl>
      <FormControlLabel
        control={
          <Switch
            checked={filters.untyped}
            onChange={handleSwitchChange("untyped")}
            value="untyped"
          />
        }
        label="No Type"
      />
      <FormControlLabel
        control={
          <Switch
            checked={filters.isFulfilmentAutomatic}
            onChange={handleSwitchChange("isFulfilmentAutomatic")}
            value="isFulfilmentAutomatic"
          />
        }
        label="Automatic Fulfilment"
      />
    </form>
  );
};

export default Filter;
