import React, { ReactElement, useContext } from "react";
import Drawer from "@material-ui/core/Drawer";
import {
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import SettingsIcon from "@material-ui/icons/Settings";
import HomeIcon from "@material-ui/icons/Home";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import StoreIcon from "@material-ui/icons/Store";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import MoneyOff from "@material-ui/icons/MoneyOff";
import classnames from "classnames";
import { Link } from "react-router-dom";
import AppContext from "../App/AppContext";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap"
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(7) + 1
      }
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0 8px",
      ...theme.mixins.toolbar
    }
  })
);

const Menu = (): ReactElement => {
  const classes = useStyles();
  const { handleDrawerClose, isMenuOpen } = useContext(AppContext);

  return (
    <Drawer
      className={classnames(classes.drawer, {
        [classes.drawerOpen]: isMenuOpen,
        [classes.drawerClose]: !isMenuOpen
      })}
      classes={{
        paper: classnames({
          [classes.drawerOpen]: isMenuOpen,
          [classes.drawerClose]: !isMenuOpen
        })
      }}
      variant="permanent"
    >
      <div className={classes.toolbar}>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        <ListItem
          {...({ component: Link, to: `/` } as any)}
          button
          key={"Home"}
        >
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem
          {...({ component: Link, to: `/accounts/` } as any)}
          button
          key={"Accounts"}
        >
          <ListItemIcon>
            <AccountBalanceIcon />
          </ListItemIcon>
          <ListItemText primary="Accounts" />
        </ListItem>
        <ListItem
          {...({ component: Link, to: `/orders/` } as any)}
          button
          key={"Orders"}
        >
          <ListItemIcon>
            <AttachMoneyIcon />
          </ListItemIcon>
          <ListItemText primary="Orders" />
        </ListItem>
        <ListItem
          {...({ component: Link, to: `/orders-stripe/` } as any)}
          button
          key={"StripeOrders"}
        >
          <ListItemIcon>
            <MoneyOff />
          </ListItemIcon>
          <ListItemText primary="Stripe Orders" />
        </ListItem>
        <ListItem
          {...({ component: Link, to: `/suppliers/` } as any)}
          button
          key={"Suppliers"}
        >
          <ListItemIcon>
            <StoreIcon />
          </ListItemIcon>
          <ListItemText primary="Suppliers" />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button key={"Settings"}>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Soon" />
        </ListItem>
      </List>
    </Drawer>
  );
};

export default Menu;
