import gql from "graphql-tag";

export default gql`
  query Assignee($orderId: ID!) {
    order(id: $orderId) {
      id
      assignedTo {
        id
        firstName
        lastName
      }
    }
  }
`;
