import React, { useState } from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  Box,
  Typography,
  Button
} from "@material-ui/core";

import { handleSubmit, withFormState } from "../../../../util/form-validation";

import TextField from "../../../TextField";
import StepBodyEditor from "../StepBodyEditor";

type Props = {
  step: any;
  toggleEditStep: any;
  onUpdateStep: any;
  onDeleteStep: any;
  updating: boolean;
  loading: boolean;
};

interface TFormState {
  id: string;
  position: number;
  title: string;
  body: string;
  isEditing: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      background: "#fff",
      border: "1px solid #eee",
      marginBottom: 24
    },
    content: {
      padding: 24
    },
    contentRow: {
      display: "flex",
      alignItems: "baseline"
    },
    contentActions: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end"
    },
    field: {
      marginBottom: 16
    },
    positionWrapper: {
      height: 40,
      width: 40,
      backgroundColor: "#eee",
      borderRadius: "50%",
      marginRight: 16
    },
    position: {
      fontSize: 20,
      fontWeight: 800,
      marginTop: 4,
      textAlign: "center"
    },
    title: {
      fontSize: 16,
      fontWeight: 500,
      textTransform: "uppercase",
      marginBottom: 16
    },
    body: {
      fontSize: 14
    }
  })
);

const Step = ({
  step,
  updating,
  toggleEditStep,
  onUpdateStep,
  onDeleteStep,
  loading
}: Props) => {
  const classes = useStyles();

  const [formState, setFormState] = useState<TFormState>(step);

  const handleFieldChange = withFormState<TFormState>(formState, setFormState);

  const onSave = async () => onUpdateStep(step.id, formState);

  return (
    <Box className={classes.container}>
      {step.isEditing ? (
        <Box className={classes.content}>
          <form onSubmit={handleSubmit(onSave)}>
            <Box className={classes.field}>
              <TextField
                name="title"
                label="Do this next"
                value={formState.title}
                onChange={handleFieldChange<string>("title")}
              />
            </Box>
            <Box className={classes.field}>
              <StepBodyEditor
                value={formState.body}
                onChange={handleFieldChange<string>("body")}
              />
            </Box>
            <Box className={classes.contentActions}>
              <Button
                onClick={() => toggleEditStep(step.id)}
                color="secondary"
                disabled={loading}
              >
                Cancel
              </Button>
              <Button type="submit" color="primary" disabled={loading}>
                Save
              </Button>
            </Box>
          </form>
        </Box>
      ) : (
        <Box className={classes.content}>
          <Box className={classes.contentRow}>
            <Box className={classes.positionWrapper}>
              <Typography
                className={classes.position}
              >{`${step.position}`}</Typography>
            </Box>
            {step.title ? (
              <Typography className={classes.title}>{step.title}</Typography>
            ) : (
              <Typography className={classes.title}>Do this next...</Typography>
            )}
          </Box>
          <Box className={classes.contentRow}>
            {step.body ? (
              <div
                className={classes.body}
                dangerouslySetInnerHTML={{ __html: JSON.parse(step.body) }}
              />
            ) : (
              <Typography className={classes.body} variant="body1">
                No step description added yet
              </Typography>
            )}
          </Box>
          {updating && (
            <Box className={classes.contentActions}>
              <Button onClick={() => onDeleteStep(step.id)} color="secondary">
                Delete
              </Button>
              <Button onClick={() => toggleEditStep(step.id)} color="primary">
                Edit
              </Button>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default Step;
