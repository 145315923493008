import React, { ReactElement, useContext, Fragment, useState } from "react";
import Typography from "@material-ui/core/Typography";
import {
  Button,
  CircularProgress,
  Grid,
  Box,
  FormControlLabel,
  Switch
} from "@material-ui/core";
import currencies from "currency-codes/data";
import {
  CreateAccountVariables as TCreateAccountVariables,
  CreateAccount as TCreateAccount
} from "./__generated__/CreateAccount";

import AppContext from "../App/AppContext";
import { Redirect } from "react-router-dom";
import { NotificationTypes } from "../Notification/Notification";
import { CurrencyCode } from "../../config/globalTypes";
import { useMutation } from "@apollo/react-hooks";
import { handleSubmit, withFormState } from "../../util/form-validation";
import TextField from "../TextField";
import SelectField from "../SelectField";
import PageMeta from "../PageMeta";

import createAccountMutation from "./create-account.graphql";
import accountsQuery from "../Accounts/accounts.graphql";

type Currency = (typeof currencies)[number];
const orderByCommonFirst = (a: Currency, b: Currency) => {
  if (["GBP", "EUR", "USD", "SEK"].includes(b.code)) return 1;
  if (["GBP", "EUR", "USD", "SEK"].includes(a.code)) return -1;
  return 0;
};
const currencySelectorItems = currencies
  .slice()
  .sort(orderByCommonFirst)
  .map(({ code, currency }) => ({
    key: code,
    label: currency
  }));

interface TFormState {
  name: string;
  currencyCode: CurrencyCode;
  revolutAccountId: string;
  isAmazonFulfilmentAutomatic: boolean;
  isStripe: boolean;
  isPayByInvoiceActiveAt: string | null;
}

const CreateAccount = (): ReactElement => {
  const [mutation, { loading, data }] = useMutation<
    TCreateAccount,
    TCreateAccountVariables
  >(createAccountMutation);
  const { handleShowNotification } = useContext(AppContext);

  const [formState, setFormState] = useState<TFormState>({
    name: "",
    currencyCode: CurrencyCode.GBP,
    revolutAccountId: "",
    isAmazonFulfilmentAutomatic: false,
    isStripe: false,
    isPayByInvoiceActiveAt: null
  });

  const handleFieldChange = withFormState<TFormState>(formState, setFormState);

  const onAmazonFulfilmentAutomaticToggle = () =>
    setFormState({
      ...formState,
      isAmazonFulfilmentAutomatic: !formState.isAmazonFulfilmentAutomatic
    });

  const onStripeToggle = () =>
    setFormState({
      ...formState,
      isStripe: !formState.isStripe
    });

  const onAmazonPayByInvoiceToggle = () =>
    setFormState({
      ...formState,
      isPayByInvoiceActiveAt: formState.isPayByInvoiceActiveAt
        ? null
        : new Date().toISOString()
    });

  const onSubmit = () =>
    mutation({
      variables: {
        account: formState
      },
      refetchQueries: [
        {
          query: accountsQuery
        }
      ]
    }).then(
      () =>
        handleShowNotification({
          type: NotificationTypes.success,
          message: "Account Created"
        }),
      () =>
        handleShowNotification({
          type: NotificationTypes.error,
          message: "Oh no, things blew up"
        })
    );

  return (
    <Fragment>
      <PageMeta title="Create Account" />
      <Typography variant="h4" gutterBottom>
        Create Account
      </Typography>
      <Typography variant="body1" gutterBottom>
        Account names and currencies can not be changed once created
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={5}>
            <Box mb={2}>
              <TextField
                name="name"
                label="Name"
                value={formState.name}
                onChange={handleFieldChange<string>("name")}
                required
              />
              <SelectField
                label="Currency"
                value={formState.currencyCode || ""}
                onChange={handleFieldChange<string>("currencyCode")}
                values={currencySelectorItems}
              />
              <TextField
                name="revolutAccountId"
                label="Revolut Account ID"
                value={formState.revolutAccountId}
                onChange={handleFieldChange<string>("revolutAccountId")}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={formState.isAmazonFulfilmentAutomatic}
                    onChange={() => onAmazonFulfilmentAutomaticToggle()}
                    name="isAmazonFulfilmentAutomatic"
                    color="primary"
                  />
                }
                label="Automatic Amazon Fulfilment"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={formState.isStripe}
                    onChange={() => onStripeToggle()}
                    name="isStripe"
                    color="primary"
                  />
                }
                label="Stripe Account"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={Boolean(formState.isPayByInvoiceActiveAt)}
                    onChange={() => onAmazonPayByInvoiceToggle()}
                    name="isPayByInvoiceActiveAt"
                    color="primary"
                  />
                }
                label="Amazon Pay By Invoice"
              />
            </Box>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
            >
              {loading ? <CircularProgress size={30} /> : "Create Account"}
            </Button>
          </Grid>
        </Grid>
        {!loading && data && <Redirect to="/accounts/" />}
      </form>
    </Fragment>
  );
};

export default CreateAccount;
