const numberOfPrecision = (value: number) => {
  if (Math.floor(value) === value) return 0;
  if (Number.isNaN(value)) return 0;

  return value.toString().split(".")?.[1]?.length || 0;
};

const getScaleFactor = (firstNum: number, secondNum: number) => {
  const firstPrecision = numberOfPrecision(firstNum);
  const secondPrecision = numberOfPrecision(secondNum);

  const precision =
    firstPrecision > secondPrecision ? firstPrecision : secondPrecision;

  return Math.pow(10, precision);
};

export const safeSubtract = (firstNum: number, secondNum: number) => {
  const scaleFactor = getScaleFactor(firstNum, secondNum);
  return (firstNum * scaleFactor - secondNum * scaleFactor) / scaleFactor;
};

export const safeDivide = (firstNum: number, secondNum: number) => {
  const scaleFactor = getScaleFactor(firstNum, secondNum);
  const result = (firstNum * scaleFactor) / (secondNum * scaleFactor);
  return result;
};
