import React, { ReactElement, useState, useContext } from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  Typography,
  Box,
  Button
} from "@material-ui/core";

import { useMutation } from "@apollo/react-hooks";

import AppContext from "../../../App/AppContext";
import { NotificationTypes } from "../../../Notification/Notification";

import Step from "../Step";

import {
  DeletePurchaseProcessStep as TDeletePurchaseProcessStep,
  DeletePurchaseProcessStepVariables as TDeletePurchaseProcessStepVariables
} from "./__generated__/DeletePurchaseProcessStep";

import {
  UpdatePurchaseProcessStep as TUpdatePurchaseProcessStep,
  UpdatePurchaseProcessStepVariables as TUpdatePurchaseProcessStepVariables
} from "./__generated__/UpdatePurchaseProcessStep";

import {
  CreatePurchaseProcessStep as TCreatePurchaseProcessStep,
  CreatePurchaseProcessStepVariables as TCreatePurchaseProcessStepVariables
} from "./__generated__/CreatePurchaseProcessStep";

import updateStepMutation from "./update-step-mutation.graphql";
import deleteStepMutation from "./delete-step-mutation.graphql";
import createStepMutation from "./create-step-mutation.graphql";
import purchaseProcessQuery from "../../purchase-process.graphql";

type Props = {
  purchaseProcessId: string;
  steps: any;
  updating: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    emptyState: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      padding: 64
    },
    addStepContainer: {
      display: "flex",
      justifyContent: "flex-end"
    },
    addStep: {
      display: "none",
      "&:hover": {
        display: "block"
      }
    }
  })
);

const EmptyState = ({ loading, onAddStep }: any): ReactElement => {
  const classes = useStyles();

  return (
    <Box className={classes.emptyState}>
      <Typography variant="h6">
        Purchase process steps will appear here
      </Typography>
      <Typography variant="body1">
        No steps have been created yet. Add the first action to take
      </Typography>
      <Button onClick={onAddStep}>
        {loading ? "Creating..." : "Add step"}
      </Button>
    </Box>
  );
};

const Steps = ({ purchaseProcessId, steps, updating }: Props): ReactElement => {
  const classes = useStyles();

  const { handleShowNotification } = useContext(AppContext);

  const initialState = steps.map((step: any) => ({
    ...step,
    isEditing: false
  }));

  const [renderSteps, setRenderSteps] = useState(initialState);

  const [deleteStep, { loading: deleteStepLoading }] = useMutation<
    TDeletePurchaseProcessStep,
    TDeletePurchaseProcessStepVariables
  >(deleteStepMutation);
  const [updateStep, { loading: updateStepLoading }] = useMutation<
    TUpdatePurchaseProcessStep,
    TUpdatePurchaseProcessStepVariables
  >(updateStepMutation);
  const [createStep, { loading: createStepLoading }] = useMutation<
    TCreatePurchaseProcessStep,
    TCreatePurchaseProcessStepVariables
  >(createStepMutation);

  const getStepIndex = (stepId: string) =>
    renderSteps &&
    renderSteps.findIndex(({ id }: { id: string }) => id === stepId);

  const onAddStep = async () => {
    const position = steps.length + 1;

    try {
      await createStep({
        variables: { input: { purchaseProcessId, position } },
        refetchQueries: [
          { query: purchaseProcessQuery, variables: { id: purchaseProcessId } }
        ],
        awaitRefetchQueries: true,
        update: (cache, { data }) => {
          if (!data) return;

          const { createPurchaseProcessStep: step } = data;

          const newStep = {
            ...step,
            position,
            title: null,
            body: null,
            isEditing: true
          };

          setRenderSteps([...renderSteps, newStep]);
        }
      });
    } catch (error) {
      handleShowNotification({
        type: NotificationTypes.error,
        message: "Oh no, there was a problem creating this step"
      });
    }
  };

  const toggleEditStep = (stepId: string) => {
    const editableStep = getStepIndex(stepId);

    const updatedRenderSteps = [...renderSteps];

    updatedRenderSteps[editableStep].isEditing =
      !updatedRenderSteps[editableStep].isEditing;

    setRenderSteps(updatedRenderSteps);
  };

  const onUpdateStep = async (stepId: string, step: any) => {
    const input = {
      title: step.title,
      body: step.body
    };

    try {
      await updateStep({
        variables: { id: stepId, input },
        update: () => {
          const editableStep = getStepIndex(stepId);

          const updatedStep = {
            ...step,
            isEditing: false
          };

          const updatedSteps = [...renderSteps];

          updatedSteps.splice(editableStep, 1);

          setRenderSteps([...updatedSteps, updatedStep]);
        }
      });
    } catch (error) {
      handleShowNotification({
        type: NotificationTypes.error,
        message: "Oh no, there was a problem updating this step"
      });
    }
  };

  const onDeleteStep = async (stepId: string) => {
    const deletableStep = getStepIndex(stepId);

    try {
      await deleteStep({
        variables: { id: stepId },
        update: (_, { data }) => {
          if (!data) return;

          const {
            deletePurchaseProcessStep: { status }
          } = data;

          if (!status) {
            handleShowNotification({
              type: NotificationTypes.error,
              message: "Oh no, there was a problem deleting this step"
            });

            return;
          }

          const updatedSteps = [...renderSteps];

          updatedSteps.splice(deletableStep, 1);

          setRenderSteps(updatedSteps);
        }
      });
    } catch (error) {
      handleShowNotification({
        type: NotificationTypes.error,
        message: "Oh no, there was a problem deleting this step"
      });
    }
  };

  const isLoading = createStepLoading || updateStepLoading || deleteStepLoading;

  return (
    <>
      {renderSteps && renderSteps.length ? (
        renderSteps.map((step: any, index: number, allSteps: any) => {
          const isLast = allSteps.length === index + 1;

          return (
            <Box key={step.id}>
              <Step
                step={step}
                toggleEditStep={toggleEditStep}
                onUpdateStep={onUpdateStep}
                onDeleteStep={onDeleteStep}
                loading={isLoading}
                updating={updating}
              />

              {isLast && updating && (
                <Box className={classes.addStepContainer}>
                  <Button onClick={onAddStep}>Add step</Button>
                </Box>
              )}
            </Box>
          );
        })
      ) : (
        <EmptyState loading={createStepLoading} onAddStep={onAddStep} />
      )}
    </>
  );
};

export default Steps;
