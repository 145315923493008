import { useEffect, useState } from "react";

import { useQuery } from "@apollo/react-hooks";

import { StringParam, useQueryParam } from "use-query-params";
import ReconcileContext from "./ReconcileContext";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Error from "../Error";
import Loading from "../Loading";
import PageMeta from "../PageMeta";

import { FormattedRow } from "./types";
import {
  TransactionsTopUp,
  TransactionsTopUp_stripeTransactions_edges
} from "./__generated__/TransactionsTopUp";
import ReconcileTopUpTable from "./ReconcileTopUpTable";
import { unionBy } from "lodash";
import { StripeTransactionType } from "../../config/globalTypes";

import transactionsQuery from "./transactionsTopUp.graphql";

const formatRows = (rows: TransactionsTopUp_stripeTransactions_edges[]) => {
  return rows.reduce<FormattedRow[]>((prev, edge) => {
    return [
      ...prev,
      {
        cursor: edge.cursor,
        id: edge.node.id,
        transaction: edge.node
      }
    ];
  }, [] as FormattedRow[]);
};

const MissingRows = () => {
  return (
    <Grid container direction="row" justifyContent="center">
      <Typography>No Top-ups found!</Typography>
    </Grid>
  );
};

const ReconcileTopUpOverviewPage = () => {
  const [accountId] = useQueryParam("accountId", StringParam);

  const { loading, error, data, fetchMore } = useQuery<TransactionsTopUp>(
    transactionsQuery,
    {
      variables: {
        accountId,
        unreconciledOnly: true,
        types: [StripeTransactionType.TOPUP]
      }
    }
  );

  const [rows, setRows] = useState<FormattedRow[]>([]);

  useEffect(() => {
    if (data) {
      const edges = data?.stripeTransactions.edges;
      const formattedEdges = formatRows(edges);
      setRows(formattedEdges);
    }
  }, [data]);

  const fetchPage = async (index: string, limit: number) => {
    await fetchMore({
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          stripeTransactions: {
            edges: unionBy(
              prev.stripeTransactions.edges,
              fetchMoreResult.stripeTransactions.edges,
              "node.id"
            ),
            pageInfo: fetchMoreResult.stripeTransactions.pageInfo,
            totalCount: prev.stripeTransactions.totalCount,
            __typename: prev.stripeTransactions.__typename
          }
        };
      },
      variables: {
        accountId,
        unreconciledOnly: true,
        after: index,
        first: limit
      }
    });
  };

  const updateRows = (newRows: FormattedRow[]) => {
    setRows(newRows);
  };

  if (loading) {
    return (
      <>
        <PageMeta title="Top Up" />
        <Loading />
      </>
    );
  }

  if (error) {
    return (
      <>
        <PageMeta title="Top Up - Error" />
        <Error message={error.message} />
      </>
    );
  }

  if (!rows.length)
    return (
      <>
        <PageMeta title="Top Up - Missing Rows" />
        <MissingRows />
      </>
    );

  const appStateValues = { account: accountId };

  return (
    <ReconcileContext.Provider value={appStateValues}>
      <div>
        <PageMeta title="Top Up" />
        <Typography variant="h4" gutterBottom>
          Top-up Reconciliation
        </Typography>

        <ReconcileTopUpTable
          hasMoreRows={data?.stripeTransactions.pageInfo.hasNextPage ?? true}
          rows={rows}
          fetchRows={fetchPage}
          updateRows={updateRows}
        />
      </div>
    </ReconcileContext.Provider>
  );
};

export default ReconcileTopUpOverviewPage;
