import { Helmet } from "react-helmet";

const PageMeta = ({
  title,
  description
}: {
  title?: string;
  description?: string;
}) => (
  <Helmet>
    <title>Fulfilment{title ? ` | ${title}` : ""}</title>
    {description && <meta name="description" content={description} />}
  </Helmet>
);

export default PageMeta;
