import {
  Status as TStatus,
  StripeOrderStatus as TStripeStatus,
  ResourceType as TResourceType,
  SortOrder as TSortOrder
} from "../config/globalTypes";

export const addCurrency = (
  value: number,
  currency: string,
  minimumFractionDigits = 2
): string => {
  return new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency,
    minimumFractionDigits
  }).format(value);
};

export const getCurrencySymbol = (currency: string) => {
  const formatted = new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency
  }).formatToParts();
  const currencyFormat = formatted.find(({ type }) => type === "currency");

  return currencyFormat?.value ?? currency;
};

export const getStatusLabel = (status: TStatus | TStripeStatus): string => {
  const statusesToLabels: { [key in TStatus | TStripeStatus]: string } = {
    OPEN: "Open",
    CANCELLED: "Cancelled",
    ON_HOLD: "On hold",
    PURCHASED: "Purchased",
    IN_PROGRESS: "In progress",
    PAID: "Done",
    REFUNDED: "Refunded",
    WONT_PURCHASE: "Won't purchase"
  };

  return statusesToLabels[status];
};

export const getResourceTypeLabel = (resourceType: TResourceType): string => {
  const resourceTypesToLabels: { [key in TResourceType]: string } = {
    ARTICLE: "Article",
    BOOK: "Book",
    COACH: "Coach",
    CONFERENCE: "Conference",
    COURSE: "Course",
    MEETUP: "Meetup",
    ONLINE_COURSE: "Online Course",
    OTHER: "Other",
    PODCAST: "Podcast",
    VIDEO: "Video",
    WORKSHOP: "Workshop"
  };

  return resourceTypesToLabels[resourceType] ?? resourceType;
};

export const getSortOrder = (sortOrder: TSortOrder): string => {
  const sortOrdersToLabels: { [key in TSortOrder]: string } = {
    DESC: "Newest first",
    ASC: "Oldest first"
  };

  return sortOrdersToLabels[sortOrder];
};
