import React, { Fragment } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { ApolloProvider, useQuery } from "@apollo/react-hooks";
import { QueryParamProvider } from "use-query-params";

import { createApolloClient } from "../../util/apollo";
import { envVars } from "../../config/env-vars";

import { WhoAmI as TWhoAmI } from "./__generated__/whoami";
import Loading from "../Loading";
import Error from "../Error";
import App from "../App";
import ErrorBoundary from "../ErrorBoundary";
import { setupUserContext } from "../../util/error-logging";

import whoAmIQuery from "./whoami.graphql";

const client = createApolloClient(envVars.apiUrl);

const Me = () => {
  const { loading, error, data } = useQuery<TWhoAmI>(whoAmIQuery);

  if (data?.me) {
    setupUserContext(data.me);
  }

  return (
    <Fragment>
      {error && <Error message={error.message} />}
      {loading ? <Loading /> : data && <App user={data.me} />}
    </Fragment>
  );
};

const Entry = () => (
  <ErrorBoundary>
    <BrowserRouter>
      <ApolloProvider client={client}>
        <QueryParamProvider ReactRouterRoute={Route}>
          <Me />
        </QueryParamProvider>
      </ApolloProvider>
    </BrowserRouter>
  </ErrorBoundary>
);

export default Entry;
