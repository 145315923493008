import React from "react";
import OpenIcon from "@material-ui/icons/FiberNewOutlined";
import CancelIcon from "@material-ui/icons/CancelOutlined";
import BlockIcon from "@material-ui/icons/Block";
import InProgressIcon from "@material-ui/icons/Pool";
import DoneIcon from "@material-ui/icons/Done";
import UndoIcon from "@material-ui/icons/Undo";
import ShippingIcon from "@material-ui/icons/LocalShipping";
import RefundedIcon from "@material-ui/icons/SettingsBackupRestore";

import {
  Status as TStatus,
  StripeOrderStatus as TStripeStatus
} from "../../config/globalTypes";
import { Chip } from "@material-ui/core";
import { getStatusLabel } from "../../util/text-display";

const StatusChip = ({ status }: { status: TStatus | TStripeStatus }) => {
  switch (status) {
    case TStatus.OPEN:
    case TStripeStatus.OPEN:
      return (
        <Chip
          color="primary"
          label={getStatusLabel(status)}
          icon={<OpenIcon />}
        />
      );
    case TStatus.CANCELLED:
    case TStripeStatus.WONT_PURCHASE:
      return <Chip label={getStatusLabel(status)} icon={<CancelIcon />} />;
    case TStatus.ON_HOLD:
    case TStripeStatus.ON_HOLD:
      return (
        <Chip
          color="primary"
          label={getStatusLabel(status)}
          icon={<BlockIcon />}
        />
      );
    case TStatus.PURCHASED:
    case TStripeStatus.PURCHASED:
      return (
        <Chip
          color="primary"
          label={getStatusLabel(status)}
          icon={<ShippingIcon />}
        />
      );
    case TStatus.IN_PROGRESS:
    case TStripeStatus.IN_PROGRESS:
      return (
        <Chip
          color="primary"
          label={getStatusLabel(status)}
          icon={<InProgressIcon />}
        />
      );
    case TStatus.PAID:
      return (
        <Chip
          color="primary"
          label={getStatusLabel(status)}
          icon={<DoneIcon />}
        />
      );
    case TStatus.REFUNDED:
      return <Chip label={getStatusLabel(status)} icon={<UndoIcon />} />;
    default:
      return <Chip label={status} icon={<RefundedIcon />} />;
  }
};

export default StatusChip;
