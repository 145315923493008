import gql from "graphql-tag";

export default gql`
  mutation SetStripeWontPurchase($orderId: Int!, $reason: String!) {
    setStripeWontPurchase(orderId: $orderId, reason: $reason) {
      id
      status
      markedWontPurchase {
        reason
        by {
          id
        }
        at
      }
    }
  }
`;
