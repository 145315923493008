import React, { useContext, useState, Fragment } from "react";
import { useMutation } from "@apollo/react-hooks";
import AppContext from "../App/AppContext";
import {
  setAmazonFulfilmentAutomaticVariables as TSetAmazonFulfilmentAutomaticVariables,
  setAmazonFulfilmentAutomatic as TSetAmazonFulfilmentAutomatic
} from "./__generated__/setAmazonFulfilmentAutomatic";

import { NotificationTypes } from "../Notification/Notification";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  DialogActions
} from "@material-ui/core";
import TextField from "../TextField";
import NumberField from "../NumberField";
import ShippingIcon from "@material-ui/icons/LocalShipping";

import { handleSubmit, withFormState } from "../../util/form-validation";
import { getCurrencySymbol } from "../../util/text-display";

import setAmazonFulfilmentAutomaticMutation from "./set-amazon-fulfilment-automatic.graphql";

interface SetAmazonFulfilmentAutomaticProps {
  orderId: string;
  currencyCode: string;
  amazonASIN: string | null;
  buttonText: string;
}

interface TFormState {
  amazonASIN: string;
  updatedGoodsPrice: number | null;
}

const isASIN = (value: string): boolean => {
  const regex = new RegExp(/^[a-z0-9]{10}$/i);

  return value.length === 10 && regex.test(value);
};

const SetAmazonFulfilmentAutomaticButton = ({
  orderId,
  currencyCode,
  amazonASIN,
  buttonText
}: SetAmazonFulfilmentAutomaticProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [formState, setFormState] = useState<TFormState>({
    updatedGoodsPrice: null,
    amazonASIN: amazonASIN || ""
  });

  const [amazonASINError, setAmazonASINValidationError] =
    useState<boolean>(false);

  const handleFieldChange = withFormState<TFormState>(formState, setFormState);

  const [mutation, { loading }] = useMutation<
    TSetAmazonFulfilmentAutomatic,
    TSetAmazonFulfilmentAutomaticVariables
  >(setAmazonFulfilmentAutomaticMutation);

  const { handleShowNotification } = useContext(AppContext);

  const currencySymbol = getCurrencySymbol(currencyCode);

  const onSubmit = async () => {
    if (!isASIN(formState.amazonASIN)) {
      return setAmazonASINValidationError(true);
    }

    await mutation({
      variables: {
        input: {
          orderId,
          amazonASIN: formState.amazonASIN,
          updatedGoodsPrice: formState?.updatedGoodsPrice
        }
      }
    })
      .catch(({ message }: { message: string }) =>
        handleShowNotification({
          type: NotificationTypes.error,
          message
        })
      )
      .finally(() => setIsOpen(false));
  };

  return (
    <Fragment>
      <Button color="primary" onClick={() => setIsOpen(true)} size="large">
        <ShippingIcon /> {buttonText}
      </Button>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>Trigger Amazon Fulfilment</DialogTitle>
          <DialogContent>
            <Box mb={2}>
              <TextField
                name="amazonASIN"
                label={
                  amazonASINError
                    ? "Invalid ASIN: ASIN must be 10 alphanumeric characters"
                    : "Amazon ASIN"
                }
                value={formState.amazonASIN}
                onChange={handleFieldChange<string>("amazonASIN")}
                error={amazonASINError}
                required
              />
            </Box>
            <Box mb={2}>
              <NumberField
                id="uupdatedGoodsPrice"
                label="Updated goods price"
                adornment={currencySymbol}
                value={formState.updatedGoodsPrice}
                onChange={handleFieldChange<number>("updatedGoodsPrice")}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button
              color="primary"
              type="submit"
              disabled={loading || !formState.amazonASIN}
            >
              Send
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Fragment>
  );
};

export default SetAmazonFulfilmentAutomaticButton;
