import gql from "graphql-tag";

export default gql`
  mutation UnlinkStripeOrderFromTransaction(
    $input: UnlinkStripeOrderFromTransactionInput!
  ) {
    unlinkStripeOrderFromTransaction(input: $input) {
      ... on UnlinkStripeOrderFromTransactionOutput {
        order {
          id
          transactions {
            id
            createdAt
            lastCardDigits
            type
            amount
          }
        }
      }
      ... on SemanticError {
        message
        code
      }
    }
  }
`;
