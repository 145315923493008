import gql from "graphql-tag";

export default gql`
  query SetPurchasedSuppliers($filters: SupplierFilters) {
    suppliers(filters: $filters) {
      id
      name
      link
      countryCode
    }
  }
`;
