interface MessageVariables {
  firstName: string;
  orderTitle: string;
}

const generateMessage = ({ firstName, orderTitle }: MessageVariables) => {
  return `Hi ${firstName}, thank you for requesting through Learnerbly!\
 Here is your e-gift voucher to purchase ${orderTitle}.\
 Please purchase the Kindle as soon as possible as prices may change. Thanks, Learnerbly`;
};

export default generateMessage;
