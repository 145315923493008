import React, { ReactElement, Fragment } from "react";
import InfiniteScroll from "react-infinite-scroller";

import {
  useQueryParams,
  ArrayParam,
  BooleanParam,
  StringParam
} from "use-query-params";

import Error from "../Error";
import Loading from "../Loading";
import PageMeta from "../PageMeta";
import OrderCard from "../StripeOrderCard";
import Filter from "./Filter";
import { NonNullOrderFilters } from "./Filter/Filter";

import {
  StripeOrders as TOrders,
  StripeOrdersVariables as TOrdersVariables,
  StripeOrders_stripeOrders_edges_node
} from "./__generated__/StripeOrders";
import {
  StripeOrderStatus as OrderStatus,
  ResourceType,
  SortOrder as TSortOrder
} from "../../config/globalTypes";

import { useQuery } from "@apollo/react-hooks";
import { Grid, Typography } from "@material-ui/core";
import SortOrder from "./SortOrder";

import stripeOrdersQuery from "./stripe-orders.graphql";

const StripeOrderList = ({
  filters,
  sortOrder
}: {
  filters: NonNullOrderFilters;
  sortOrder: TSortOrder;
}): ReactElement => {
  const { loading, error, data, fetchMore } = useQuery<
    TOrders,
    TOrdersVariables
  >(stripeOrdersQuery, { variables: { first: 8, filters, sortOrder } });

  if (loading) {
    return (
      <>
        <PageMeta title="Stripe Orders" />
        <Loading />
      </>
    );
  }

  if (error) {
    return (
      <>
        <PageMeta title="Stripe Orders - Error" />
        <Error message={error.message} />
      </>
    );
  }

  if (data) {
    const {
      stripeOrders: {
        edges,
        pageInfo: { hasNextPage, endCursor }
      }
    } = data;

    return (
      <>
        <PageMeta title="Stripe Orders" />
        <InfiniteScroll
          hasMore={hasNextPage}
          loader={<Loading key="loading" />}
          loadMore={() =>
            fetchMore({
              variables: {
                after: endCursor
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) {
                  return prev;
                }
                return {
                  stripeOrders: {
                    edges: [
                      ...prev.stripeOrders.edges,
                      ...fetchMoreResult.stripeOrders.edges
                    ],
                    pageInfo: {
                      ...prev.stripeOrders.pageInfo,
                      ...fetchMoreResult.stripeOrders.pageInfo
                    },
                    __typename: prev.stripeOrders.__typename
                  }
                };
              }
            })
          }
        >
          {edges.map(
            ({ node }: { node: StripeOrders_stripeOrders_edges_node }) => (
              <OrderCard key={node.id} {...node} />
            )
          )}
        </InfiniteScroll>
      </>
    );
  }
  return <></>;
};

const StripeOrders = (): ReactElement => {
  const [queryParams, setQueryParams] = useQueryParams({
    assignedTo: ArrayParam,
    status: ArrayParam,
    account: ArrayParam,
    supplier: ArrayParam,
    requestedBy: ArrayParam,
    resourceType: ArrayParam,
    unassigned: BooleanParam,
    untyped: BooleanParam,
    isFulfilmentAutomatic: BooleanParam,
    sortOrder: StringParam
  });

  const filters = {
    status: (queryParams.status as OrderStatus[]) || [
      OrderStatus.OPEN,
      OrderStatus.IN_PROGRESS,
      OrderStatus.ON_HOLD
    ],
    assignedTo: queryParams.assignedTo || [],
    account: queryParams.account || [],
    supplier: queryParams.supplier || [],
    resourceType: (queryParams.resourceType as ResourceType[]) || [],
    requestedBy: queryParams.requestedBy || [],
    unassigned: queryParams.unassigned,
    untyped: queryParams.untyped,
    isFulfilmentAutomatic: queryParams.isFulfilmentAutomatic
  };

  const sortOrder = (queryParams.sortOrder as TSortOrder) || TSortOrder.DESC;

  return (
    <Fragment>
      <Typography variant="h4" gutterBottom>
        Stripe Orders
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={9}>
          <StripeOrderList filters={filters} sortOrder={sortOrder} />
        </Grid>
        <Grid item xs={3}>
          <SortOrder
            onChange={setQueryParams}
            sortOrder={sortOrder}
            filters={filters}
          />
          <Filter
            onChange={setQueryParams}
            filters={filters}
            sortOrder={sortOrder}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default StripeOrders;
