import Grid from "@material-ui/core/Grid";

import IconButton from "@material-ui/core/IconButton";

import DeleteIcon from "@material-ui/icons/Delete";
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";

import { makeStyles, createStyles, Theme } from "@material-ui/core";
import { useEffect, useState } from "react";
import { MatchStatus, MatchStatusType } from "./types";

export enum ActionStates {
  unmatched,
  unconfirmed,
  confirmed
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      fontSize: "18px",
      "& svg": {
        display: "flex",
        color: "#91979d"
      },

      "& button[disabled]": {
        "& svg": {
          color: "#ced1d5"
        }
      }
    }
  })
);

const getStateFromStatus = (status: MatchStatus) => {
  if (status.type === MatchStatusType.reconciled) {
    return ActionStates.confirmed;
  }

  if (status.type !== MatchStatusType.unreconciled) {
    return ActionStates.unconfirmed;
  }

  return ActionStates.unmatched;
};

interface Props {
  value?: MatchStatus;

  disabled?: boolean;

  onSearchClicked: () => void;
  onEditClicked: () => void;
  onConfirm: () => void;
  onCancel: () => void;
}
const Actions = ({
  value,
  onSearchClicked,
  onConfirm,
  onCancel,
  onEditClicked,
  disabled = false
}: Props) => {
  const classes = useStyles();
  const [currentState, setCurrentState] = useState<ActionStates>(
    value ? getStateFromStatus(value) : ActionStates.unmatched
  );

  useEffect(() => {
    if (value) setCurrentState(getStateFromStatus(value));
  }, [value]);

  const ButtonStateContents: { [key in ActionStates]: JSX.Element } = {
    [ActionStates.unmatched]: (
      <Grid container direction="row" wrap="nowrap">
        <IconButton disabled={disabled} onClick={onSearchClicked}>
          <SearchIcon aria-label="search" />
        </IconButton>

        <IconButton disabled={disabled} onClick={onEditClicked}>
          <EditIcon aria-label="edit" />
        </IconButton>
      </Grid>
    ),
    [ActionStates.unconfirmed]: (
      <Grid container direction="row" wrap="nowrap">
        <IconButton
          disabled={disabled}
          onClick={() => {
            setCurrentState(ActionStates.unmatched);
            onCancel();
          }}
        >
          <DeleteIcon aria-label="delete" />
        </IconButton>
        <IconButton disabled={disabled} onClick={onConfirm}>
          <CheckIcon aria-label="confirm" />
        </IconButton>
      </Grid>
    ),
    [ActionStates.confirmed]: (
      <Grid container item xs={12} justifyContent="center">
        🎉
      </Grid>
    )
  };

  return (
    <Grid container spacing={2} className={classes.container}>
      {ButtonStateContents[currentState]}
    </Grid>
  );
};

export default Actions;
