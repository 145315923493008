import gql from "graphql-tag";

export default gql`
  mutation setAmazonFulfilmentAutomatic(
    $input: AmazonFulfilmentAutomaticInput!
  ) {
    setAmazonFulfilmentAutomatic(input: $input) {
      id
      status
      updatedGoodsPrice
      amazonData {
        amazonASIN
      }
      isFulfilmentAutomatic
    }
  }
`;
