import React, { ReactElement } from "react";

import Error from "../Error";
import Loading from "../Loading";
import PageMeta from "../PageMeta";
import OrderCard from "../OrderCard";

import {
  Order as TOrder,
  OrderVariables as TOrderVariables
} from "./__generated__/Order";

import { useQuery } from "@apollo/react-hooks";
import { useParams } from "react-router";
import TransactionTable from "./TransactionTable";

import ordersQuery from "./order.graphql";

const Order = (): ReactElement => {
  const { orderId } = useParams<{
    orderId: string;
  }>();
  const { loading, error, data } = useQuery<TOrder, TOrderVariables>(
    ordersQuery,
    { variables: { orderId } }
  );

  if (loading) {
    return (
      <>
        <PageMeta title="Order" />
        <Loading />
      </>
    );
  }

  if (error) {
    return (
      <>
        <PageMeta title="Order - Error" />
        <Error message={error.message} />
      </>
    );
  }

  if (data) {
    const { order } = data;

    return (
      <>
        <PageMeta title={`Order ${order.id}`} />
        <OrderCard {...order} />
        <TransactionTable
          transactions={order.transactions}
          orderId={order.id}
        />
      </>
    );
  }

  return <></>;
};

export default Order;
