import Amplify from "@aws-amplify/core";

export const authInit = ({
  identityPoolId,
  region,
  userPoolId,
  userPoolWebClientId,
  cognitoDomain,
  webUrl
}: {
  identityPoolId: string;
  region: string;
  userPoolId: string;
  userPoolWebClientId: string;
  cognitoDomain: string;
  webUrl: string;
}) =>
  Amplify.configure({
    Auth: {
      identityPoolId,
      region,
      userPoolId,
      userPoolWebClientId,
      oauth: {
        domain: cognitoDomain,
        scope: [
          "email",
          "profile",
          "openid",
          "fulfilment/read:all",
          "fulfilment/write:all"
        ],
        redirectSignIn: `${webUrl}sso/`,
        redirectSignOut: `${webUrl}logout/`,
        responseType: "code",
        options: {
          AdvancedSecurityDataCollectionFlag: true
        }
      }
    }
  });
