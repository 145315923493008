import gql from "graphql-tag";

export default gql`
  mutation CreateSupplier($input: CreateSupplierInput!) {
    createSupplier(input: $input) {
      id
      name
      countryCode
      link
      learnerblySupplierId
      vatRatePercent
    }
  }
`;
