import gql from "graphql-tag";

export default gql`
  mutation TriggerActions($orderId: ID!) {
    triggerActions(orderId: $orderId) {
      id
      status
    }
  }
`;
