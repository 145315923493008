import React, { useContext, useState, ChangeEvent } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import AppContext from "../App/AppContext";
import {
  AssignToVariables as TAssignToVariables,
  AssignTo as TAssignTo
} from "./__generated__/AssignTo";
import {
  AssigneeVariables as TAssigneeVariables,
  Assignee as TAssignee
} from "./__generated__/Assignee";

import { NotificationTypes } from "../Notification/Notification";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  DialogActions,
  Select,
  MenuItem,
  Typography
} from "@material-ui/core";
import { AssignAllUsers as TAssignAllUsers } from "./__generated__/AssignAllUsers";
import Loading from "../Loading";
import AssignToIcon from "@material-ui/icons/AssignmentInd";

import assignToMutation from "./assign-to.graphql";
import allUsersQuery from "./users.graphql";
import assigneeQuery from "./assignee.graphql";

const AssignToDialog = ({
  orderId,
  close
}: {
  orderId: string;
  close: () => void;
}) => {
  const {
    user: { id: currentUserId }
  } = useContext(AppContext);
  const [formState, setFormState] = useState<string>(currentUserId);
  const [mutation, { loading: mutationLoading }] = useMutation<
    TAssignTo,
    TAssignToVariables
  >(assignToMutation);
  const { loading: allUsersLoading, data: allUsersData } =
    useQuery<TAssignAllUsers>(allUsersQuery);
  const { loading: assigneeLoading, data: assigneeData } = useQuery<
    TAssignee,
    TAssigneeVariables
  >(assigneeQuery, {
    fetchPolicy: "network-only", // always fetch in case someone already assigned
    variables: {
      orderId
    }
  });

  const loading = allUsersLoading || assigneeLoading;

  const handleChange = (
    event: ChangeEvent<{
      value: unknown;
    }>
  ) => {
    const value = event.target.value as string;
    setFormState(value);
  };

  const handleMutation = async () => {
    try {
      await mutation({ variables: { userId: formState, orderId } });
    } catch (err) {
      const { message }: { message: string } = err as Error;
      handleShowNotification({ type: NotificationTypes.error, message });
    } finally {
      close();
    }
  };

  const { handleShowNotification } = useContext(AppContext);

  return (
    <Dialog onClose={close} maxWidth="lg" open>
      <DialogTitle>Assign to</DialogTitle>
      <DialogContent>
        <Box mb={2}>
          {loading && <Loading />}
          {assigneeData?.order.assignedTo && (
            <Typography align="center" color="secondary" gutterBottom>
              Achtung: This order is already assigned to{" "}
              {assigneeData.order.assignedTo.firstName}
            </Typography>
          )}
          {!loading && allUsersData && (
            <Select value={formState} onChange={handleChange}>
              {allUsersData.users.map(({ id, firstName, lastName }) => (
                <MenuItem key={id} value={id}>
                  {firstName}
                </MenuItem>
              ))}
            </Select>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={close}>
          Cancel
        </Button>
        <Button
          color="primary"
          type="button"
          onClick={handleMutation}
          disabled={mutationLoading}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const AssignToButton = ({ orderId }: { orderId: string }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <Button color="primary" onClick={() => setIsOpen(true)} size="large">
        <AssignToIcon /> Assign to
      </Button>
      {isOpen && (
        <AssignToDialog close={() => setIsOpen(false)} orderId={orderId} />
      )}
    </>
  );
};

export default AssignToButton;
