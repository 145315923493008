import React, { useContext, Fragment, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Grid,
  Box,
  FormControlLabel,
  Switch
} from "@material-ui/core";

import AppContext from "../App/AppContext";
import { Redirect } from "react-router-dom";
import { NotificationTypes } from "../Notification/Notification";
import { useMutation } from "@apollo/react-hooks";
import { handleSubmit, withFormState } from "../../util/form-validation";
import TextField from "../TextField";

import {
  UpdateAccountVariables as TUpdateAccountVariables,
  UpdateAccount as TUpdateAccount
} from "./__generated__/UpdateAccount";

import updateAccountMutation from "../_shared/update-account.graphql";

interface Props {
  account: any;
  isOpen: boolean;
  handleModalClose: () => void;
}

interface TFormState {
  id: string;
  name: string;
  revolutAccountId: string;
  isAmazonFulfilmentAutomatic: boolean;
  isPayByInvoiceActiveAt: string | null;
}

const UpdateAccount = ({ account, isOpen, handleModalClose }: Props) => {
  const [mutation, { loading, data: updateAccountMutationData }] = useMutation<
    TUpdateAccount,
    TUpdateAccountVariables
  >(updateAccountMutation);

  const { handleShowNotification } = useContext(AppContext);

  const { amazonBusinessAccounts, ...accountSettings } = account;

  const [formState, setFormState] = useState<TFormState>(accountSettings);

  const handleFieldChange = withFormState<TFormState>(formState, setFormState);

  const onAmazonFulfilmentAutomaticToggle = () =>
    setFormState({
      ...formState,
      isAmazonFulfilmentAutomatic: !formState.isAmazonFulfilmentAutomatic
    });

  const onAmazonPayByInvoiceToggle = () =>
    setFormState({
      ...formState,
      isPayByInvoiceActiveAt: formState.isPayByInvoiceActiveAt
        ? null
        : new Date().toISOString()
    });

  const onSubmit = async () => {
    const {
      id,
      name,
      revolutAccountId,
      isAmazonFulfilmentAutomatic,
      isPayByInvoiceActiveAt
    } = formState;

    try {
      await mutation({
        variables: {
          id,
          input: {
            name,
            revolutAccountId,
            isAmazonFulfilmentAutomatic,
            isPayByInvoiceActiveAt
          }
        }
      });

      handleModalClose();
      handleShowNotification({
        type: NotificationTypes.success,
        message: "Account Updated"
      });
    } catch (error) {
      handleShowNotification({
        type: NotificationTypes.error,
        message: "Oh no, there was a problem updating the account."
      });
    }
  };

  return (
    <Fragment>
      <Dialog open={isOpen} onClose={handleModalClose}>
        <DialogTitle>Update Account</DialogTitle>
        <DialogContent>
          <Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={3}>
                <Grid item>
                  <Box mb={2}>
                    <TextField
                      name="name"
                      label="Name"
                      value={formState.name}
                      onChange={handleFieldChange<string>("name")}
                      disabled
                    />
                    <TextField
                      name="revolutAccountId"
                      label="Revolut Account ID"
                      value={formState.revolutAccountId || ""}
                      onChange={handleFieldChange<string>("revolutAccountId")}
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formState.isAmazonFulfilmentAutomatic}
                          onChange={() => onAmazonFulfilmentAutomaticToggle()}
                          name="isAmazonFulfilmentAutomatic"
                          color="primary"
                        />
                      }
                      label="Automatic Amazon Fulfilment"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={Boolean(formState.isPayByInvoiceActiveAt)}
                          onChange={() => onAmazonPayByInvoiceToggle()}
                          name="isPayByInvoiceActiveAt"
                          color="primary"
                        />
                      }
                      label="Amazon Pay By Invoice"
                    />
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Fragment>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose} color="primary">
            Cancel
          </Button>
          <Button onClick={onSubmit} color="primary" disabled={loading}>
            {loading ? <CircularProgress size={30} /> : "Update"}
          </Button>
        </DialogActions>
        {!loading && updateAccountMutationData && <Redirect to="/accounts/" />}
      </Dialog>
    </Fragment>
  );
};

export default UpdateAccount;
