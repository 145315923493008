import React, { ReactElement, ChangeEvent } from "react";
import {
  default as MaterialSwitchField,
  SwitchProps
} from "@material-ui/core/Switch";

interface CustomProps {
  onChange: (value: boolean | undefined) => void;
  label: string;
}

const SwitchField = ({
  onChange,
  label,
  ...props
}: Omit<SwitchProps, "onChange"> & CustomProps): ReactElement => (
  <>
    <MaterialSwitchField
      {...props}
      onChange={(event: ChangeEvent<HTMLInputElement>) =>
        onChange(event.target.checked)
      }
    />
    <span>{label}</span>
  </>
);

export default SwitchField;
