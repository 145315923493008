import gql from "graphql-tag";

export default gql`
  mutation IssueStripePayout($input: IssueStripePayoutInput!) {
    issueStripePayout(input: $input) {
      order {
        id
      }
      notStripeAccountError {
        message
      }
      unsupportedCurrencyError {
        message
      }
      unsupportedStripeAccountError {
        message
      }
    }
  }
`;
