import gql from "graphql-tag";

export default gql`
  query StripeAssignee($orderId: Int!) {
    stripeOrder(id: $orderId) {
      id
      assignedTo {
        id
        firstName
        lastName
      }
    }
  }
`;
