import gql from "graphql-tag";

export default gql`
  mutation SetPurchased(
    $orderId: ID!
    $goodsPrice: Fixed!
    $shippingFees: Fixed!
    $vatAmount: Fixed
    $vatRatePercent: Fixed
    $commissionAmount: Fixed
    $commissionVatAmount: Fixed
    $supplierId: ID!
    $resourceType: ResourceType!
  ) {
    setPurchased(
      orderId: $orderId
      goodsPrice: $goodsPrice
      shippingFees: $shippingFees
      supplierId: $supplierId
      vatAmount: $vatAmount
      vatRatePercent: $vatRatePercent
      commissionAmount: $commissionAmount
      commissionVatAmount: $commissionVatAmount
      resourceType: $resourceType
    ) {
      id
      status
      goodsPrice
      shippingFees
      vatAmount
      vatRatePercent
      resourceType
      commissionAmount
      commissionVatAmount
      purchasedAt
      purchasedBy {
        ... on User {
          id
          firstName
          lastName
        }
      }
      supplier {
        id
        name
        link
      }
    }
  }
`;
