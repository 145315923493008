import React, { useContext, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import AppContext from "../App/AppContext";
import {
  RectifyResourceTypeVariables as TRectifyResourceTypeVariables,
  RectifyResourceType as TRectifyResourceType
} from "./__generated__/RectifyResourceType";

import { NotificationTypes } from "../Notification/Notification";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  DialogActions
} from "@material-ui/core";
import { ResourceType as TResourceType } from "../../config/globalTypes";
import SelectField from "../SelectField";
import { getResourceTypeLabel } from "../../util/text-display";

import assignToMutation from "./rectify-resource-type.graphql";

const StripeRectifyResourceTypeButton = ({
  orderId,
  resourceType
}: {
  orderId: number;
  resourceType: TResourceType;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [formState, setFormState] = useState<TResourceType>(resourceType);
  const [mutation, { loading }] = useMutation<
    TRectifyResourceType,
    TRectifyResourceTypeVariables
  >(assignToMutation, {
    onCompleted: () => setIsOpen(false),
    onError: error =>
      handleShowNotification({
        type: NotificationTypes.error,
        message: error.message
      })
  });

  const { handleShowNotification } = useContext(AppContext);

  return (
    <>
      <Button color="primary" onClick={() => setIsOpen(true)} size="small">
        Update
      </Button>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)} maxWidth="lg">
        <DialogTitle>Set Resource Type</DialogTitle>
        <DialogContent>
          <Box mb={2}>
            <SelectField<TResourceType>
              label="Resource Type"
              value={formState}
              onChange={(value: TResourceType) => setFormState(value)}
              values={Object.values(TResourceType).map(type => ({
                key: type,
                label: getResourceTypeLabel(type)
              }))}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button
            color="primary"
            type="button"
            size="small"
            onClick={() =>
              mutation({
                variables: {
                  resourceType: formState,
                  orderId
                }
              })
            }
            disabled={loading || !formState}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default StripeRectifyResourceTypeButton;
