import { StripeTransactionType, CurrencyCode } from "../../config/globalTypes";

export enum MatchStatusType {
  "reconciled",
  "auto",
  "manual",
  "unreconciled"
}

export interface MatchStatus {
  type: MatchStatusType;
  score?: number;
}

export interface Order {
  id: number;
  createdAt: string;
  markedPurchased: {
    at: string;
    checkoutGrossPrice: GQL.Fixed;
  } | null;
  supplier: { id: string; name: string } | null;
  title: string;
  vatAmount: GQL.Fixed | null;
  requestCurrencyCode: CurrencyCode | null;
  requestGrossPrice: GQL.Fixed | null;
}

export interface Transaction {
  id: number;
  type: StripeTransactionType;
  amount: GQL.Fixed;
  statementDescriptor: string | null;
  createdAt: GQL.DateTime;
  currency: string | null;
  account: { id: string; name: string } | null;
  order: Order | null;
  merchantAmount: GQL.Fixed | null;
  merchantCurrency: string | null;
}

export interface FormattedRow {
  id: string;
  cursor: string;
  matchStatus: MatchStatus;
  order?: Order;
  transaction: Transaction;
}
