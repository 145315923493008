import { Button } from "@material-ui/core";
import { Payment } from "@material-ui/icons";
import { useImperativeHandle, useState } from "react";
import PayoutModal from "../PayoutModal";

interface StripePayoutCheckoutValues {
  checkoutGrossPrice: GQL.Fixed;
  currencyCode: string;
}

interface PayoutButtonProps {
  orderId: number;
  supplierId: string;
  checkoutValues: StripePayoutCheckoutValues;
  dialogRef?: React.Ref<{ setPayoutModalOpen: (state: boolean) => void }>;
}

const PayoutButton = (props: PayoutButtonProps) => {
  const { orderId, supplierId, checkoutValues, dialogRef } = props;
  const [isPayoutModalOpen, setPayoutModalOpen] = useState(false);

  useImperativeHandle(dialogRef, () => ({ setPayoutModalOpen }));

  return (
    <>
      <Button
        color="primary"
        onClick={() => setPayoutModalOpen(true)}
        size="large"
      >
        <Payment /> Payout
      </Button>
      <PayoutModal
        isOpen={isPayoutModalOpen}
        orderId={orderId}
        initialValues={{
          supplierId,
          amount: checkoutValues.checkoutGrossPrice,
          currencyCode: checkoutValues.currencyCode
        }}
        onClose={() => setPayoutModalOpen(false)}
      />
    </>
  );
};

export default PayoutButton;
