import gql from "graphql-tag";

export default gql`
  query FilterAllSuppliers($filters: SupplierFilters) {
    suppliers(filters: $filters) {
      id
      name
    }
  }
`;
