import React, { useContext, useState, Fragment } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import AppContext from "../App/AppContext";
import {
  SetPurchasedVariables as TSetPurchasedVariables,
  SetPurchased as TSetPurchased
} from "./__generated__/SetPurchased";
import { SetPurchasedSuppliers as TSuppliers } from "./__generated__/SetPurchasedSuppliers";

import { NotificationTypes } from "../Notification/Notification";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  DialogActions
} from "@material-ui/core";
import { getResourceTypeLabel } from "../../util/text-display";

import { handleSubmit, withFormState } from "../../util/form-validation";
import ShippingIcon from "@material-ui/icons/LocalShipping";
import { ResourceType as TResourceType } from "../../config/globalTypes";
import SelectField from "../SelectField";
import OrderDetailForm from "./OrderDetailForm";
import OrderDetailBasicForm from "./OrderDetailBasicForm";

import suppliersQuery from "./suppliers.graphql";
import setPurchasedMutation from "./set-purchased.graphql";

interface SetPurchasedProps {
  orderId: string;
  currencyCode: string;
  goodsPrice: GQL.Fixed;
  resourceType: TResourceType | null;
  supplierId: string | null;
  isStripeAccount: boolean;
  onComplete?: () => void;
}

interface TFormState {
  goodsPrice: GQL.Fixed;
  shippingFees: GQL.Fixed;
  supplierId: string | null;
  resourceType: TResourceType | null;
  netPrice: GQL.Fixed;
  commissionPrice: GQL.Fixed;
  deductVat: boolean;
  commissionPriceVAT: GQL.Fixed;
  totalCommissionPrice: GQL.Fixed;
  vatRatePercent: GQL.Fixed | null;
}

const SetPurchasedButton = ({
  orderId,
  currencyCode,
  resourceType,
  goodsPrice,
  supplierId,
  isStripeAccount,
  onComplete
}: SetPurchasedProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [formState, setFormState] = useState<TFormState>({
    goodsPrice,
    shippingFees: 0,
    supplierId,
    resourceType,
    netPrice: goodsPrice,
    deductVat: false,
    commissionPrice: 0,
    commissionPriceVAT: 0,
    totalCommissionPrice: 0,
    vatRatePercent: null
  });

  const handleFieldChange = withFormState<TFormState>(formState, setFormState);

  const {
    loading: loadingSuppliers,
    error,
    data: suppliersData
  } = useQuery<TSuppliers>(suppliersQuery, {
    variables: { filters: { isArchived: false } }
  });

  const supplierSelectorItems =
    !suppliersData || loadingSuppliers || error ? [] : suppliersData.suppliers;

  const [mutation, { loading }] = useMutation<
    TSetPurchased,
    TSetPurchasedVariables
  >(setPurchasedMutation);

  const { handleShowNotification } = useContext(AppContext);

  return (
    <Fragment>
      <Button color="primary" onClick={() => setIsOpen(true)} size="large">
        <ShippingIcon /> Mark as purchased
      </Button>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <form
          onSubmit={handleSubmit<TSetPurchasedVariables>(() =>
            mutation({
              variables: {
                orderId,
                goodsPrice: formState.goodsPrice,
                shippingFees: formState.shippingFees,
                vatAmount: formState.goodsPrice - formState.netPrice,
                vatRatePercent: formState.vatRatePercent ?? 0,
                supplierId: formState.supplierId as string,
                resourceType: formState.resourceType as TResourceType,
                commissionAmount: formState.totalCommissionPrice,
                commissionVatAmount: formState.commissionPriceVAT
              }
            })
              .then(() => {
                onComplete && onComplete();
              })
              .catch(({ message }: { message: string }) =>
                handleShowNotification({
                  type: NotificationTypes.error,
                  message
                })
              )
              .finally(() => setIsOpen(false))
          )}
        >
          <DialogTitle>Mark as purchased</DialogTitle>
          <DialogContent>
            {isStripeAccount ? (
              <OrderDetailBasicForm
                currencyCode={currencyCode}
                formValues={formState}
                updateValues={values =>
                  setFormState({
                    ...formState,
                    ...values
                  })
                }
              />
            ) : (
              <OrderDetailForm
                currencyCode={currencyCode}
                formValues={formState}
                updateValues={values =>
                  setFormState({
                    ...formState,
                    ...values
                  })
                }
              />
            )}
            <Grid item xs={12}>
              <SelectField
                label="Resource Type"
                value={formState.resourceType || ""}
                onChange={handleFieldChange<string>("resourceType")}
                values={Object.values(TResourceType).map(type => ({
                  key: type,
                  label: getResourceTypeLabel(type)
                }))}
              />
            </Grid>

            <Grid item xs={12}>
              <SelectField
                label="Supplier"
                value={formState.supplierId || ""}
                onChange={handleFieldChange<string>("supplierId")}
                values={supplierSelectorItems.map(({ id, name }) => ({
                  key: id,
                  label: name
                }))}
                required
              />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button
              color="primary"
              type="submit"
              disabled={loading || !formState.resourceType}
            >
              Mark as Purchased
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Fragment>
  );
};

export default SetPurchasedButton;
