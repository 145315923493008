import * as Sentry from "@sentry/react";

export const logError = error => Sentry.captureException(error);

export const setupUserContext = user => {
  if (user) {
    Sentry.configureScope(scope =>
      scope.setUser({
        id: user.id,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName
      })
    );
  }
};
