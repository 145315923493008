import React, { ReactElement, useContext } from "react";

import { Button } from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";
import { useMutation } from "@apollo/react-hooks";
import AppContext from "../../../App/AppContext";
import { NotificationTypes } from "../../../Notification/Notification";
import { ApolloError } from "apollo-client";

import archiveAccountMutation from "./archive-account.graphql";

type Props = {
  accountId: string;
  onConfirm: () => void;
};

const ArchiveAccountButton = ({
  accountId,
  onConfirm
}: Props): ReactElement => {
  let [archiveAccount, { loading }] = useMutation(archiveAccountMutation);
  const { handleShowNotification } = useContext(AppContext);

  const handleClick = async () => {
    if (window.confirm("Are you sure you wish to archive this organisation?")) {
      try {
        await archiveAccount({ variables: { id: accountId } });
        onConfirm();
      } catch (err) {
        const error = err as ApolloError;
        handleShowNotification({
          type: NotificationTypes.error,
          message: error.message
        });
      }
    }
  };

  return (
    <Button
      variant="contained"
      color="secondary"
      startIcon={<Delete />}
      onClick={handleClick}
      disabled={loading}
    >
      Archive Organisation
    </Button>
  );
};

export default ArchiveAccountButton;
