import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  CircularProgress,
  Grid,
  Box
} from "@material-ui/core";

import AppContext from "../App/AppContext";
import ReconcileContext from "./ReconcileContext";
import orderQuery from "../StripeOrder/stripeOrder.graphql";

import { NotificationTypes } from "../Notification/Notification";
import { handleSubmit, withFormState } from "../../util/form-validation";
import TextField from "../TextField";
import {
  StripeOrder as TOrder,
  StripeOrderVariables as TOrderVariables
} from "../StripeOrder/__generated__/StripeOrder";

import { Order } from "../Reconcile/types";

interface Props {
  isOpen: boolean;
  handleModalClose: () => void;
  onConfirm: (order: Order) => Promise<void>;
}

interface TFormState {
  orderId?: number;
}

const OrderIdSearchModal = ({ isOpen, handleModalClose, onConfirm }: Props) => {
  const [formState, setFormState] = useState<TFormState>({
    orderId: undefined
  });
  const [timeoutHandle, setTimeoutHandle] = useState<number | null>(null);
  const [confirming, setConfirming] = useState<boolean>(false);
  const { handleShowNotification } = useContext(AppContext);
  const { account } = useContext(ReconcileContext);

  const handleFieldChange = withFormState<TFormState>(formState, setFormState);
  const [loadOrder, { loading, data }] = useLazyQuery<TOrder, TOrderVariables>(
    orderQuery
  );

  useEffect(() => {
    const { orderId } = formState;
    if (timeoutHandle !== null) {
      window.clearTimeout(timeoutHandle);
    }

    const newTimeoutHandle = window.setTimeout(() => {
      if (orderId) loadOrder({ variables: { orderId } });
    }, 600);
    setTimeoutHandle(newTimeoutHandle);

    return () => {
      if (timeoutHandle !== null) {
        window.clearTimeout(timeoutHandle);
      }
    };
    // loadOrder and timeoutHandle should not trigger this effect despite being used inside.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState]);

  const onSubmit = async () => {
    setConfirming(true);
    let closeModal = true;

    try {
      data?.stripeOrder && (await onConfirm(data.stripeOrder));
    } catch (error) {
      closeModal = false;
      handleShowNotification({
        type: NotificationTypes.error,
        message: "Oh no, there was a problem matching the order."
      });
    } finally {
      setConfirming(false);
    }

    if (closeModal) {
      handleModalClose();
    }
  };

  const orderNotFound = useMemo(
    () => !loading && !data && !!formState.orderId,
    [loading, data, formState.orderId]
  );
  const isProcessing = useMemo(
    () => loading || confirming,
    [loading, confirming]
  );

  const orderNotOnAccount =
    account && data?.stripeOrder.account.id
      ? account !== data?.stripeOrder.account.id
      : false;

  return (
    <Dialog open={isOpen} onClose={handleModalClose}>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item>
              <Box mb={2}>
                <TextField
                  name="orderId"
                  label="Order ID"
                  value={formState.orderId}
                  onChange={(value?: string) =>
                    handleFieldChange<number>("orderId")(
                      value ? parseInt(value, 10) : value
                    )
                  }
                  error={orderNotFound || orderNotOnAccount}
                  type="number"
                  helperText={
                    orderNotFound
                      ? "Order does not exist"
                      : orderNotOnAccount
                      ? "Order does not belong to transaction account"
                      : undefined
                  }
                />
              </Box>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onSubmit}
          color="primary"
          disabled={isProcessing || !data}
        >
          {isProcessing ? <CircularProgress size={30} /> : "Confirm"}
        </Button>
        <Button onClick={handleModalClose} color="secondary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrderIdSearchModal;
