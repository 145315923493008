import gql from "graphql-tag";

export default gql`
  mutation LinkStripeOrderToTransaction($transactionId: Int!, $orderId: Int!) {
    linkStripeOrderToTransaction(
      input: { transactionId: $transactionId, orderId: $orderId }
    ) {
      ... on SemanticError {
        message
        code
      }
      ... on LinkStripeOrderToTransactionOutput {
        order {
          id
          title
        }
      }
    }
  }
`;
