import React, { StrictMode } from "react";
import Button from "@material-ui/core/Button";
import Main from "../Main";
import { makeStyles } from "@material-ui/styles";
import Auth, { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";

const useStyles = makeStyles({
  container: {
    backgroundColor: "var(--neutralLightest)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    minHeight: "100vh",
    textAlign: "center"
  }
});

const onLoginClick = () =>
  Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });

const Login = () => {
  const classes = useStyles({});
  return (
    <StrictMode>
      <Main>
        <div className={classes.container}>
          <Button variant="contained" color="primary" onClick={onLoginClick}>
            Login
          </Button>
        </div>
      </Main>
    </StrictMode>
  );
};

export default Login;
