import React, { Fragment, ReactElement, useState, ChangeEvent } from "react";
import {
  Breadcrumbs,
  Link,
  Box,
  Grid,
  Typography,
  Tab,
  Tabs
} from "@material-ui/core";

import { useQuery } from "@apollo/react-hooks";
import { useParams } from "react-router";

import Error from "../Error";
import Loading from "../Loading";
import PageMeta from "../PageMeta";

import {
  PartnerAccount as TPartnerAccount,
  PartnerAccountVariables as TPartnerAccountVariables
} from "./__generated__/PartnerAccount";

import PartnerRates from "../PartnerRates";
import PartnerContacts from "../PartnerContacts";
import PartnerAssets from "../PartnerAssets";

import partnerAccountByIdQuery from "./partner-account.graphql";

const PartnerAccount = (): ReactElement => {
  const { supplierId, partnerAccountId } = useParams<{
    supplierId: string;
    partnerAccountId: string;
  }>();

  const [activeTab, setActiveTab] = useState<number>(0);

  const { loading, error, data } = useQuery<
    TPartnerAccount,
    TPartnerAccountVariables
  >(partnerAccountByIdQuery, {
    variables: { partnerAccountId }
  });

  const handleSetActiveTab = (event: ChangeEvent<{}>, newValue: number) =>
    setActiveTab(newValue);

  if (loading) {
    return (
      <>
        <PageMeta title="Partner Account" />
        <Loading />
      </>
    );
  }

  if (error) {
    return (
      <>
        <PageMeta title="Partner Account - Error" />
        <Error message={error.message} />
      </>
    );
  }

  if (data) {
    const {
      partnerAccount: { name, commissionRates, discounts, contacts, assets }
    } = data;

    return (
      <Fragment>
        <PageMeta title={`Partner Account - ${name}`} />
        <Box mb={4}>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Suppliers</Typography>
            <Link color="inherit" href={`/suppliers/${supplierId}`}>
              {name}
            </Link>
            <Typography color="textPrimary">Partner Account</Typography>
          </Breadcrumbs>
          <Grid
            container
            justifyContent="space-between"
            spacing={2}
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h4">{name} - Partner Account</Typography>
            </Grid>
          </Grid>
        </Box>
        <Box mb={4}>
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            value={activeTab}
            onChange={handleSetActiveTab}
          >
            <Tab label="Rates / Discounts" />
            <Tab label="Contacts" />
            <Tab label="Assets" />
          </Tabs>
        </Box>
        <Typography component="div" role="tabpanel" hidden={activeTab !== 0}>
          <PartnerRates
            commissionRates={commissionRates}
            discounts={discounts}
          />
        </Typography>
        <Typography component="div" role="tabpanel" hidden={activeTab !== 1}>
          <PartnerContacts contacts={contacts} />
        </Typography>
        <Typography component="div" role="tabpanel" hidden={activeTab !== 2}>
          <PartnerAssets assets={assets} />
        </Typography>
      </Fragment>
    );
  }

  return <div />;
};

export default PartnerAccount;
