import React, { ReactElement, ChangeEvent } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

function SelectField<T>({
  values,
  value,
  onChange,
  label,
  required,
  name
}: {
  values: { key: string; label: string }[];
  value: string | number;
  label: string;
  onChange: any;
  required?: boolean;
  name?: string;
}): ReactElement {
  const onSelectChange = (
    event: ChangeEvent<{
      value: unknown;
    }>
  ) => {
    const value = event.target.value as T;
    onChange(value);
  };
  return (
    <FormControl fullWidth margin="normal">
      <InputLabel required={required}>
        {label}
        {""}
      </InputLabel>
      <Select
        value={value}
        onChange={onSelectChange}
        required={required}
        name={name}
      >
        {values.map(({ key, label }) => (
          <MenuItem key={key} value={key}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SelectField;
