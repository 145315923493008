import gql from "graphql-tag";

export default gql`
  mutation SetInProgress($orderId: ID!) {
    setInProgress(orderId: $orderId) {
      id
      status
      assignedTo {
        id
        firstName
        lastName
      }
    }
  }
`;
