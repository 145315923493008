import gql from "graphql-tag";

export default gql`
  mutation SetCancelled($orderId: ID!, $cancellationReason: String) {
    setCancelled(orderId: $orderId, cancellationReason: $cancellationReason) {
      id
      status
      cancelledBy {
        id
      }
      cancelledAt
    }
  }
`;
